import React, { Component } from "react";
import { Card, Col, Row, Label, Container } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import CsvIcon from "../../../assets/images/common/csv-file-icon.jpg";
import Papa from 'papaparse';

import {
  setImportedContactsData,
  setImportedContactsFields,
  setMappedContactFields,
  setSelectedCsvFile,
} from "../../../store/contacts/actions";

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
    };
  }
  handleAcceptedFiles = files => {
    const {
      onSetImportedContactsData,
      onSetImportedContactsFields,
      onSetSelectedCsvFile,
    } = this.props;

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );
    this.setState({ selectedFiles: [files?.[0]] });
    onSetSelectedCsvFile([files?.[0]]);
    const selectedFile = files?.[0];

    const reader = new FileReader();

    reader.onload = event => {
      const csvContent = event.target.result;

      Papa.parse(csvContent, {
        header: true, // Automatically uses the first row as headers
        skipEmptyLines: true,
        complete: function(results) {
          const headers = Object.keys(results.data[0]);
          onSetImportedContactsFields(headers);

          const contactData = results.data.map((row, index) => ({
            no: index + 1,
            ...row
          }));

          onSetImportedContactsData(contactData);
        },
        error: function(error) {
          console.error("Error parsing CSV file:", error);
        }
      });
    };

    reader.readAsText(selectedFile);

    //   const rows = csvContent.split("\n"); // Split into rows
    //   const headers = rows[0].split(",").map(header => header.trim()); // Extract headers
    //   onSetImportedContactsFields(headers);
    //   const contactData = [];

    //   for (let i = 1; i < rows.length; i++) {
    //     const columns = rows[i].split(",").map(column => column.trim());

    //     // Create an object with properties from headers
    //     const rowData = { no: i }; // Adding 'id' and 'no' properties with index values
    //     headers.forEach((header, index) => {
    //       rowData[header] = columns[index];
    //     });
    //     contactData.push(rowData);
    //   }
    //   onSetImportedContactsData(contactData);
    // };

    // reader.readAsText(selectedFile);
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleOnClose = () => {
    const {
      onSetImportedContactsData,
      onSetImportedContactsFields,
      onSetSelectedCsvFile,
      onSetMappedContactFields,
    } = this.props;
    this.setState({ selectedFiles: [] });
    onSetImportedContactsData([]);
    onSetImportedContactsFields([]);
    onSetSelectedCsvFile([]);
    onSetMappedContactFields({});
  };

  render() {
    const { selectedCsvFile } = this.props;
    return (
      <div className="">
        <Container fluid>
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="Import Contacts" /> */}
          <div className="ms-1">
            <div>
              <Label for="confirmPassword" className="form-label">
                Upload csv file
              </Label>
              <Dropzone
                onDrop={acceptedFiles =>
                  this.handleAcceptedFiles(acceptedFiles)
                }
                accept=".csv"
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedCsvFile.length > 0 &&
                  this.props.selectedCsvFile.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete mb-2"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={CsvIcon}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold me-4"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <i
                          className="mdi mdi-window-close drop-image-close"
                          onClick={this.handleOnClose}
                        ></i>
                      </Card>
                    );
                  })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

Step1.propTypes = {
  onSetImportedContactsData: PropTypes.func,
  onSetImportedContactsFields: PropTypes.func,
  onSetMappedContactFields: PropTypes.func,
};

const mapStateToProps = ({ contacts, phoneNumber }) => ({
  users: contacts.users,
  modal: phoneNumber.showBuyNumberModal,
  contactData: contacts.importedContactData,
  contactFields: contacts.importedContactFields,
  selectedCsvFile: contacts.selectedCsvFile,
});

const mapDispatchToProps = dispatch => ({
  onSetImportedContactsData: data => dispatch(setImportedContactsData(data)),
  onSetImportedContactsFields: data =>
    dispatch(setImportedContactsFields(data)),
  onSetMappedContactFields: data => dispatch(setMappedContactFields(data)),
  onSetSelectedCsvFile: data => dispatch(setSelectedCsvFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step1));
