import React, { Component } from "react";
import { Card, Col, Row, Container, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import PropTypes, { object } from "prop-types";
import { connect } from "react-redux";
import {
  setImportedContactsData,
  setImportedContactsFields,
  setMappedContactFields,
} from "../../../store/contacts/actions";
import { spreadSheetFields } from "constants/importcontacts";
import { toast } from "react-toastify";

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: {}, // Local state to store selected values for each field
    };
  }

  componentDidMount = () => {
    const { mappedContactFields, contactFields, onSetMappedContactFields } =
      this.props;
    const mapFields = {};
    const incorrectFieldNames = {};
    if (Object.keys(mappedContactFields).length !== 0) {
      this.setState({ selectedValues: mappedContactFields });
    }
    if (!Object.keys(mappedContactFields).length) {
      Object.keys(spreadSheetFields).map(field => {
        if (contactFields.includes(field)) {
          mapFields[field] = field;
        } else {
          incorrectFieldNames[field] = field;
        }
      });
      onSetMappedContactFields(mapFields);
      this.setState({ selectedValues: mapFields });
      if (Object.keys(incorrectFieldNames).length) {
        toast.error(
          `No field names available for: ${Object.keys(
            incorrectFieldNames
          ).join(", ")}`
        );
      }
    }
  };

  handleSelectChange = (field, selectedValue) => {
    const { onSetMappedContactFields } = this.props;
    const { selectedValues } = this.state;

    // Create a copy of selectedValues
    const updatedSelectedValues = { ...selectedValues };

    // If the selectedValue is already present in updatedSelectedValues, remove it
    Object.keys(updatedSelectedValues).forEach(key => {
      if (updatedSelectedValues[key] === selectedValue) {
        delete updatedSelectedValues[key];
      }
    });

    // Update the local state with the selected value for the specified field
    this.setState(
      {
        selectedValues: {
          ...updatedSelectedValues,
          [field]: selectedValue,
        },
      },
      () => {
        // This callback is executed after the state has been updated
        onSetMappedContactFields(this.state.selectedValues);
      }
    );
  };

  render() {
    const { selectedValues } = this.state;
    const { contactData, contactFields, mappedContactFields } = this.props;
    return (
      <div className="">
        <Container fluid>
          <Card>
            <CardBody className="px-5">
              <Row xs="2">
                {Object.keys(spreadSheetFields).map(field => (
                  <Col key={field}>
                    <label className="">
                      {spreadSheetFields[field] ===
                        spreadSheetFields.phoneNumber ||
                      spreadSheetFields[field] ===
                        spreadSheetFields.countryCode ? (
                        <>
                          {spreadSheetFields[field]}
                          <span className="text-danger">*</span>
                        </>
                      ) : (
                        spreadSheetFields[field]
                      )}
                    </label>
                    <div className="mb-3">
                      <select
                        className="form-select form-select-border-bottom"
                        value={
                          mappedContactFields[field] ||
                          selectedValues[field] ||
                          ""
                        }
                        onChange={e =>
                          this.handleSelectChange(field, e.target.value)
                        }
                      >
                        <option value="">Select value</option>
                        {contactFields?.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

Step2.propTypes = {
  onSetImportedContactsData: PropTypes.func,
  onSetImportedContactsFields: PropTypes.func,
  onSetMappedContactFields: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  contactData: contacts.importedContactData,
  contactFields: contacts.importedContactFields,
  mappedContactFields: contacts.mappedContactFields,
});

const mapDispatchToProps = dispatch => ({
  onSetImportedContactsData: data => dispatch(setImportedContactsData(data)),
  onSetImportedContactsFields: data =>
    dispatch(setImportedContactsFields(data)),
  onSetMappedContactFields: data => dispatch(setMappedContactFields(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step2));
