import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { setShowTemplateModal } from "../../../../store/campaign/actions";
import {
  smsCharacterCount,
  tag,
  templateLanguage,
  templateType,
  whatsappCharacterCount,
} from "constants/constants";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";

class Sms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  toggle = () => {
    const { onSetShowTemplateModal } = this.props;
    onSetShowTemplateModal();
  };

  handleAddTemplate = async templateData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllTemplates } = this.props;
      const response = await axiosPost("message-template", templateData);
      if (response?.status) {
        toast.success(response?.message || "Template created successfully!");
        getAllTemplates();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error at handleAddTemplate :", error);
    }
  };

  handleUpdateTemplate = async templateData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllTemplates, template } = this.props;
      const response = await axiosPatch(
        `message-template/${template?.id}`,
        templateData
      );
      if (response?.status) {
        toast.success(response?.message || "Template updated successfully!");
        getAllTemplates();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error at handleUpdateTemplate :", error);
    }
  };

  handleOnSubmit = values => {
    const { template, isEdit } = this.props;
    const templateData = {
      name: values?.name,
      templateFor: templateType.sms,
      language: values?.language,
      templateContent: values?.templateContent,
    };

    if (isEdit) {
      const changedFields = {};

      // Compare templateData with previous template data to identify changes
      Object.keys(templateData).forEach(key => {
        if (templateData[key] !== template[key]) {
          changedFields[key] = templateData[key];
        }
      });
      this.handleUpdateTemplate(changedFields);
    } else {
      this.handleAddTemplate(templateData);
    }
  };

  render() {
    const { isEdit, showTemplateModal, template } = this.props;
    const { showModalLoader } = this.state;

    const initialValues = {
      name: template?.name || "",
      templateContent: template.templateContent || "",
      language: template.language || "",
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Please enter template name"),
      templateContent: Yup.string().required("Please enter template message"),
      language: Yup.string().required(
        "Please select language for the template"
      ),
    });
    return (
      <Modal isOpen={showTemplateModal} className={this.props.className}>
        {showModalLoader ? <ModalLoader /> : ""}
        <ModalHeader toggle={this.toggle} tag="h4">
          {isEdit ? `Edit Template : ${template?.name}` : "Add Template"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, status, touched, values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Template Name</Label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Language</Label>
                      <Field
                        as="select"
                        name="language"
                        className={
                          "form-control-select" +
                          (errors.language && touched.language
                            ? " is-invalid"
                            : "")
                        }
                      >
                        <option value="">Select a language</option>
                        {Object.keys(templateLanguage).map(type => {
                          return (
                            <option key={type} value={templateLanguage[type]}>
                              {templateLanguage[type]}
                            </option>
                          );
                        })}{" "}
                      </Field>
                      <ErrorMessage
                        name="language"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3 d-flex mt-4">
                      <Label className="form-label">Tag</Label>
                      <div className="ms-2">
                        {Object.keys(tag).map(type => (
                          <span
                            key={type}
                            className="me-2 rounded-pill bg-secondary text-white px-3 py-2 cursor-pointer"
                            onClick={() => {
                              setFieldValue(
                                "templateContent",
                                values?.templateContent + `{${tag[type]}}`
                              );
                            }}
                          >
                            {tag[type]}
                          </span>
                        ))}{" "}
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Template Content</Label>
                      <Field
                        as="textarea"
                        name="templateContent"
                        maxLength={smsCharacterCount}
                        className={
                          "form-control-select" +
                          (errors.templateContent && touched.templateContent
                            ? " is-invalid"
                            : "")
                        }
                      ></Field>
                      <ErrorMessage
                        name="templateContent"
                        component="div"
                        className="invalid-feedback"
                      />
                      <p className="mt-2 rounded-pill bg-danger text-white p-2 width-10rem text-center">
                        <span className="">Characters : </span>
                        {values?.templateContent?.length}/{smsCharacterCount}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

Sms.propTypes = {
  className: PropTypes.any,
  onSetShowTemplateModal: PropTypes.func,
};

const mapStateToProps = ({ campaign }) => ({
  showTemplateModal: campaign.showTemplateModal,
});

const mapDispatchToProps = dispatch => ({
  onSetShowTemplateModal: () => dispatch(setShowTemplateModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sms));
