import Breadcrumbs from "components/Common/Breadcrumb";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  UncontrolledAccordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "reactstrap";
import Select from "react-select";
import { axiosGet, axiosPost } from "helpers/api_helpers";
import {
  callTypes,
  channelType,
  generateMonthList,
  messageTypes,
} from "constants/constants";
import { UserContext } from "../../components/UserProvider/UserProvider";
import { userRoles } from "constants/user";
import { setLoader } from "../../store/actions";

class Billing extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      selectedUsers: [],
      monthOptions: [],
      selectedMonth: "",
      callAnalytics: {},
      messageAnalytics: {},
    };
  }

  componentDidMount = () => {
    this.getAllUsers();
    const monthOptions = generateMonthList();
    this.setState({ monthOptions: monthOptions?.reverse() });
    this.setState({ selectedMonth: monthOptions?.[0] }, () => {
      this.handleRequestMessageAnalytics();
      this.handleRequestCallAnalytics();
    });
  };

  handleMulti = selectedUsers => {
    this.setState({ selectedUsers });
  };

  handleMonth = selectedMonth => {
    this.setState({ selectedMonth });
  };

  getAllUsers = async () => {
    try {
      const users = await axiosGet(`users/all`);
      if (users?.status) {
        this.setState({
          users: users?.data,
        });
      }
    } catch (error) {
      console.error("error while getting all users :", error);
    }
  };

  handleRequestMessageAnalytics = async () => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const { selectedMonth, selectedUsers } = this.state;
      const filter = {
        users: selectedUsers?.map(user => user.value),
        monthYear: selectedMonth?.value,
      };
      const response = await axiosPost("/conversation/analytics", filter);
      if (response?.status) {
        this.setState({ messageAnalytics: response?.data });
      }
      onSetLoader(false);
    } catch (error) {
      onSetLoader(false);
      console.error("error while getting handleRequestMessageAnalytics", error);
    }
  };

  handleRequestCallAnalytics = async () => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const { selectedMonth, selectedUsers } = this.state;
      const filter = {
        users: selectedUsers?.map(user => user.value),
        monthYear: selectedMonth?.value,
      };
      const response = await axiosPost("/calldailer/analytics", filter);
      if (response?.status) {
        this.setState({ callAnalytics: response?.data });
      }
      onSetLoader(false);
    } catch (error) {
      onSetLoader(false);
      console.error("error while getting handleRequestCallAnalytics", error);
    }
  };

  handleFilter = async () => {
    this.handleRequestCallAnalytics();
    this.handleRequestMessageAnalytics();
  };

  render() {
    // meta title
    document.title = "Billing";

    const {
      users,
      selectedUsers,
      selectedMonth,
      monthOptions,
      messageAnalytics,
      callAnalytics,
    } = this.state;
    const { userData } = this.context;

    // message analytics
    const whatsappMessageAnalytics = messageAnalytics?.channels?.filter(
      channel => channel?._id === channelType.whatsapp
    );
    const totalWhatsappCost = whatsappMessageAnalytics?.[0]?.totalWhatsappCost;

    const inboundWhatsappMessageAnalytics =
      whatsappMessageAnalytics?.[0]?.counts?.filter(
        message => message?.messageType === messageTypes.RECEIVED
      );

    const outboundWhatsappMessageAnalytics =
      whatsappMessageAnalytics?.[0]?.counts?.filter(
        message => message?.messageType === messageTypes.SEND
      );

    const totalWhatsappMessages =
      (outboundWhatsappMessageAnalytics?.[0]?.count || 0) +
      (inboundWhatsappMessageAnalytics?.[0]?.count || 0);

    //call analytics
    const inboundCallAnalytics = callAnalytics?.calls?.filter(
      call => call?.type === callTypes?.INBOUND
    );
    const outboundCallAnalytics = callAnalytics?.calls?.filter(
      call => call?.type === callTypes?.OUTBOUND
    );
    const totalCallCost = callAnalytics?.totalCost;
    const totalCalls =
      outboundCallAnalytics?.[0]?.count ||
      0 + inboundCallAnalytics?.[0]?.count ||
      0;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={
                <i className="bx bx-dollar-circle font-size-13 mt-0-5"></i>
              }
              breadcrumbItem="Billing"
            />
            <Card>
              <CardBody className="d-flex align-items-center">
                <Row className="me-2">
                  <div className="mb-3 width-21rem">
                    <Label className="form-label">Select Month</Label>
                    <Select
                      value={selectedMonth}
                      // isMulti={true}
                      onChange={this.handleMonth}
                      options={monthOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  {/* </div> */}
                </Row>
                {userData.role === userRoles.ADMIN ? (
                  <Row className="">
                    {/* <div className=""> */}
                    <div className="mb-3 width-21rem">
                      <Label className="form-label">Users</Label>
                      <Select
                        value={selectedUsers}
                        isMulti={true}
                        onChange={this.handleMulti}
                        options={users?.map(user => ({
                          label: user?.name,
                          value: user?.id,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Row>
                ) : null}
                <Row className="ms-2 mt-1">
                  <div className="mt-2">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleFilter}
                    >
                      Filter
                    </button>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="">
                <Row className="d-flex w-100">
                  <Col className="col-4 ">
                    <span className="fw-bold fs-5">Product</span>
                  </Col>
                  <Col className="col-8 d-flex justify-content-between">
                    <span className="flex-1 fw-bold fs-5 text-start">
                      Units
                    </span>
                    <span className="flex-1 fw-bold fs-5 text-center">
                      Rate
                    </span>
                    <span className="flex-1 fw-bold fs-5 text-end">Total</span>
                  </Col>
                </Row>
                <Row className="d-flex w-100 mt-2">
                  <Col className="col-4 ">
                    <span className=" fs-6">Whatsapp</span>
                  </Col>
                  <Col className="col-8 d-flex justify-content-between">
                    <span className="flex-1 fs-6 flex-1">
                      {totalWhatsappMessages ? totalWhatsappMessages : 0}
                    </span>
                    <span className="flex-1 fs-6 text-center">$0.02</span>
                    <span className="flex-1 fs-6 text-end">
                      {totalWhatsappCost
                        ? `$${parseFloat(totalWhatsappCost?.toFixed(4))}`
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex w-100 mt-2">
                  <Col className="col-4 ">
                    <span className="fs-6">Calls</span>
                  </Col>
                  <Col className="col-8 d-flex justify-content-between">
                    <span className="flex-1 fs-6 text-start">
                      {totalCalls ? totalCalls : 0}
                    </span>
                    <span className="flex-1 fs-6 text-center">-</span>
                    <span className="flex-1 fs-6 text-end">
                      {totalCallCost
                        ? `$${parseFloat(totalCallCost?.toFixed(4))}`
                        : 0}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <UncontrolledAccordion className="">
              <Card>
                <AccordionItem className="border-0">
                  <AccordionHeader targetId="1">
                    <div className="d-flex align-items-center">
                      <h5 className="fw-bold mb-0">WHATSAPP</h5>
                    </div>{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="1" className="">
                    <CardBody>
                      <Row className="d-flex w-100 mb-3">
                        <Col className="col-4 ">
                          <span className="fs-5 fw-bold">
                            Inbound Whatsapp Message
                          </span>
                        </Col>
                        <Col className="col-8 d-flex justify-content-between">
                          <span className="flex-1 text-start fs-6">
                            {inboundWhatsappMessageAnalytics?.[0]?.count
                              ? inboundWhatsappMessageAnalytics?.[0]?.count
                              : 0}
                          </span>
                          <span className="flex-1 text-center fs-6">$0.02</span>
                          <span className="flex-1 text-end fs-6">
                            {inboundWhatsappMessageAnalytics?.[0]?.cost
                              ? `$${parseFloat(
                                  inboundWhatsappMessageAnalytics?.[0]?.cost?.toFixed(
                                    4
                                  )
                                )}`
                              : 0}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex w-100">
                        <Col className="col-4">
                          <span className="fs-5 fw-bold">
                            Outbound Whatsapp Message
                          </span>
                        </Col>
                        <Col className="col-8 d-flex justify-content-between">
                          <span className="fs-6 flex-1 text-start ">
                            {outboundWhatsappMessageAnalytics?.[0]?.count
                              ? outboundWhatsappMessageAnalytics?.[0]?.count
                              : 0}
                          </span>
                          <span className="fs-6 flex-1 text-center ">
                            $0.02
                          </span>
                          <span className="fs-6 flex-1 text-end ">
                            {outboundWhatsappMessageAnalytics?.[0]?.cost
                              ? `$${parseFloat(
                                  outboundWhatsappMessageAnalytics?.[0]?.cost?.toFixed(
                                    4
                                  )
                                )}`
                              : 0}
                          </span>
                        </Col>
                      </Row>{" "}
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
              </Card>
            </UncontrolledAccordion>
            <UncontrolledAccordion className="">
              <Card>
                <AccordionItem className="border-0">
                  <AccordionHeader targetId="2">
                    <div className="d-flex align-items-center">
                      <h5 className="fw-bold mb-0">CALLS</h5>
                    </div>{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="2" className="">
                    <CardBody>
                      <Row className="d-flex w-100 mb-3">
                        <Col className="col-4 ">
                          <span className="fs-5 fw-bold">Inbound Call</span>
                        </Col>
                        <Col className="col-8 d-flex justify-content-between">
                          <span className="fs-6 flex-1 text-start">
                            {inboundCallAnalytics?.[0]?.count
                              ? inboundCallAnalytics?.[0]?.count
                              : 0}
                          </span>
                          <span className="fs-6 flex-1 text-center ">-</span>
                          <span className="fs-6 flex-1 text-end ">
                            {inboundCallAnalytics?.[0]?.totalCost
                              ? `$${inboundCallAnalytics?.[0]?.totalCost?.toFixed(
                                  4
                                )}`
                              : 0}
                          </span>
                        </Col>
                      </Row>
                      <Row className="d-flex w-100">
                        <Col className="col-4">
                          <span className="fs-5 fw-bold">Outbound Call</span>
                        </Col>
                        <Col className="col-8 d-flex justify-content-between">
                          <span className="fs-6 flex-1 text-start">
                            {outboundCallAnalytics?.[0]?.count
                              ? outboundCallAnalytics?.[0]?.count
                              : 0}
                          </span>
                          <span className="fs-6 flex-1 text-center ">-</span>
                          <span className="fs-6 flex-1 text-end ">
                            {outboundCallAnalytics?.[0]?.totalCost
                              ? `$${outboundCallAnalytics?.[0]?.totalCost?.toFixed(
                                  4
                                )}`
                              : 0}
                          </span>
                        </Col>
                      </Row>{" "}
                    </CardBody>
                  </AccordionBody>
                </AccordionItem>
              </Card>
            </UncontrolledAccordion>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// export default Dashboard;
const mapStateToProps = ({ contacts }) => ({});

const mapDispatchToProps = dispatch => ({
  onSetShowBuyNumberModal: () => dispatch(setShowBuyNumberModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Billing));
