import React from "react"
import ReactDOM from 'react-dom/client';
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"

import { Provider } from "react-redux"

import store from "./store"
import UserProvider from "components/UserProvider/UserProvider";
// import AdminToUser from "components/UserProvider/AdminToUser";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        {/* <AdminToUser> */}
        <UserProvider>
          <App />
        </UserProvider>
        {/* </AdminToUser> */}
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
serviceWorker.unregister()