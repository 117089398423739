import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";

import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { axiosDel, axiosGet } from "helpers/api_helpers";
import emptyContacts from "../../../assets/images/emptyContact.png";
import moment from "moment";
import { setLoader } from "../../../store/actions";
import DeleteModal from "components/Common/DeleteModal";
import { toast } from "react-toastify";
import { FormControl } from "react-bootstrap";

class Report extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      campaigns: [],
      campaign: {},
      deleteModal: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      searchQuery: "",
      reportListColumns: [
        {
          text: "No",
          dataField: "id",
          sort: true,
          formatter: (cellContent, report, index) => <span>{index + 1}</span>,
        },

        {
          text: "Title",
          dataField: "name",
          sort: true,
        },
        {
          text: "Date",
          dataField: "createdAt",
          sort: true,
          formatter: cellContent => (
            <span> {moment(cellContent).format("YYYY-MM-DD HH:mm:ss")}</span>
          ),
        },
        {
          text: "Status",
          dataField: "status",
          sort: true,
        },
        {
          text: "Type",
          dataField: "channel",
          sort: true,
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, report) => (
            <div className="d-flex gap-3">
              <Link
                className="text-success"
                to={`/view-report/${report?.id}?page=${this.state.page}`}
              >
                <i
                  className="mdi mdi-eye-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="view"
                  id="view"
                  // onClick={() => this.handleUserClick(report)}
                ></i>
              </Link>
              <div className="text-danger">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="delete"
                  onClick={() => this.onClickDelete(report)}
                ></i>
              </div>
            </div>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = () => {
    // this.handleGetAllcampaign(this.state.page);
    // Create a new URLSearchParams object from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    // Accessing the value of the 'id' parameter
    const contactGroupPage = queryParams.get("page");
    if (contactGroupPage) {
      this.setState({ page: contactGroupPage }, () => {
        this.handleGetAllcampaign(contactGroupPage);
      });
    } else {
      this.handleGetAllcampaign(this.state.page);
    }
  };

  onClickDelete = campaign => {
    this.setState({ campaign, deleteModal: true });
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllcampaign(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetAllcampaign(this.state.page);
    });
  };
  handleGetAllcampaign = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `campaign?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&sortBy=createdAt:desc`
      );
      if (response?.status) {
        this.setState({
          campaigns: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at handleGetAllcampaign :", error);
    }
  };

  handleDeleteCampainById = async () => {
    try {
      const { campaign } = this.state;
      const response = await axiosDel(`campaign/${campaign?.id}`);
      if (response?.status) {
        toast.success(response?.message || "Campaign deleted successfully!", {
          position: "top-right",
        });
        this.handleGetAllcampaign(this.state.page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error while deleting sender group :", error);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetAllcampaign(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "campaign-report";

    const { campaigns, deleteModal } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Campaign" breadcrumbItem="Report" />
            <DeleteModal
              show={deleteModal}
              onDeleteClick={() => this.handleDeleteCampainById()}
              onCloseClick={() => this.setState({ deleteModal: false })}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12">
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                    </Col>
                    {campaigns.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={campaigns}
                            columns={this.state.reportListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Report.propTypes = {
  className: PropTypes.any,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({});

const mapDispatchToProps = dispatch => ({
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report));
