import Breadcrumbs from "components/Common/Breadcrumb";
import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        {
          title: "Sent SMS",
          iconClass: "mdi mdi-email",
          description: "0",
        },
        {
          title: "Delivered SMS",
          iconClass: "mdi mdi-email-check",
          description: "0",
        },
        {
          title: "Failed SMS",
          iconClass: "mdi mdi-email-remove",
          description: "0",
        },
        {
          title: "Queued SMS",
          iconClass: "mdi mdi-email-plus",
          description: "0",
        },
      ],
    };
  }

  render() {
    // meta title
    document.title = "Dashboard";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={<i className="bx bx-home-circle"></i>}
              breadcrumbItem="Dashboard"
            />
            <Row>
              {/* Reports Render */}
              {this.state.reports.map((report, key) => (
                <Col md="3" key={`_col_${key}`}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{report.title}</p>
                          <h4 className="mb-0">{report.description}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={`bx ${report.iconClass} font-size-24`}
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
