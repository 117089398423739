import React, { Component } from "react";
import { Card, Col, Row, Container, CardBody, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import {
  setImportedContactsData,
  setImportedContactsFields,
  setMappedContactFields,
  setSelectedCsvFile,
} from "../../../store/contacts/actions";
import { axiosGet, axiosPost } from "helpers/api_helpers";
import { LIMIT } from "constants/constants";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/actions";

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
      selectedGroup: "",
      selectedGroupId: null,
      mappedContactData: [],
      contactGroups: [],
      newGroupName: "",
      contactListColumns: [
        {
          text: "No",
          dataField: "name",
          sort: true,
          formatter: (cellContent, contact, index) => (
            <>
              <p className="">{index + 1}</p>
            </>
          ),
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
          formatter: (cellContent, contact) => (
            <>
              <p className="">{contact?.name || "-"}</p>
            </>
          ),
        },
        {
          text: "Country Code",
          dataField: "countryCode",
          sort: true,
          formatter: (cellContent, contact) => (
            <>
              <p className="">{contact?.countryCode || "-"}</p>
            </>
          ),
        },
        {
          text: "Mobile Number",
          dataField: "phoneNumber",
          sort: true,
          formatter: (cellContent, contact) => (
            <>
              <p className="">{contact?.phoneNumber || "-"}</p>
            </>
          ),
        },
        {
          text: "Address",
          dataField: "address",
          sort: true,
          formatter: (cellContent, contact) => (
            <>
              <p className="">{contact?.address || "-"}</p>
            </>
          ),
        },
        {
          text: "Zip",
          dataField: "zip",
          sort: true,
          formatter: (cellContent, contact) => (
            <>
              <p className="">{contact?.zip || "-"}</p>
            </>
          ),
        },
        {
          text: "State",
          dataField: "state",
          sort: true,
          formatter: (cellContent, contact) => (
            <>
              <p className="">{contact?.state || "-"}</p>
            </>
          ),
        },
        {
          text: "City",
          dataField: "city",
          sort: true,
          formatter: (cellContent, contact) => (
            <>
              <p className="">{contact?.city || "-"}</p>
            </>
          ),
        },
      ],
    };
  }

  componentDidMount = () => {
    const { contactData, mappedContactFields } = this.props;

    const mappedContactData = this.mapContactData(
      contactData,
      mappedContactFields
    );
    this.setState({ mappedContactData: mappedContactData });
    this.getAllContactGroups();
  };

  mapContactData = (data, fieldMap) => {
    return data
      .map(item => {
        const mappedItem = {};
        for (const [originalField, mappedField] of Object.entries(fieldMap)) {
          if (originalField === "phoneNumber" && item[mappedField]) {
            mappedItem[originalField] = item[mappedField].replace(/[^\d]/g, "");
          } else if (item[mappedField]) {
            mappedItem[originalField] = item[mappedField];
          }
        }
        return mappedItem;
      })
      .filter(item => Object.keys(item).length > 0); // Filter out empty rows
  };

  getAllContactGroups = async () => {
    try {
      const groups = await axiosGet(
        `contact-groups?page=1&limit=${LIMIT}&sortBy=createdAt:desc`
      );
      if (groups?.status) {
        this.setState({ contactGroups: groups?.data?.results });
      }
    } catch (error) {
      console.error("error while getting all contact groups :", error);
    }
  };

  handleGroupSelection = event => {
    this.setState({ selectedGroup: event.target.value }, () => {
      // if (this.state.selectedGroup === "existing") {
      //   this.getAllContactGroups();
      // }
    });
  };

  handleExistingGroupSelection = id => {
    this.setState({ selectedGroupId: id });
  };

  handleInputChange = value => {
    this.setState({ newGroupName: value });
  };

  handleEditGroup = async () => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const {
        contactData,
        mappedContactFields,
        onSetImportedContactsData,
        onSetImportedContactsFields,
        onSetMappedContactFields,
        onSetSelectedCsvFile,
      } = this.props;
      const contacts = this.mapContactData(contactData, mappedContactFields);
      const response = await axiosPost(
        `contact-groups/add-contacts/${this.state.selectedGroupId}`,
        { contacts }
      );
      if (response?.status) {
        toast.success(response?.message, {
          position: "top-right",
        });
        this.props.history.push("/contact-groups");
        onSetImportedContactsData([]);
        onSetImportedContactsFields([]);
        onSetMappedContactFields({});
        onSetSelectedCsvFile([]);
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
      onSetLoader(false);
    } catch (error) {
      onSetLoader(false);
      console.error("error while updating a contact group:", error);
    }
  };

  handleCreateNewGroup = async () => {
    const { newGroupName } = this.state;
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const {
        contactData,
        mappedContactFields,
        onSetImportedContactsData,
        onSetImportedContactsFields,
        onSetMappedContactFields,
        onSetSelectedCsvFile,
      } = this.props;
      const contacts = this.mapContactData(contactData, mappedContactFields);
      const response = await axiosPost("contact-groups", {
        name: newGroupName,
        contacts,
      });
      if (response?.status) {
        toast.success(response?.message, {
          position: "top-right",
        });
        this.props.history.push("/contact-groups");
        onSetImportedContactsData([]);
        onSetImportedContactsFields([]);
        onSetMappedContactFields({});
        onSetSelectedCsvFile([]);
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
      onSetLoader(false);
    } catch (error) {
      onSetLoader(false);
      console.error("error while creating a new group :", error);
    }
  };

  handleSubmit = () => {
    if (this.state.selectedGroup === "existing") {
      this.handleEditGroup();
    } else if (this.state.selectedGroup === "new") {
      this.handleCreateNewGroup();
    }
  };

  render() {
    const { contactData } = this.props;
    const { mappedContactData, contactGroups } = this.state;

    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 5,
      totalSize: contactData.length, // replace later with size(users),
      custom: true,
    };

    return (
      <div className="">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="phoneNumber"
                    columns={this.state.contactListColumns}
                    data={mappedContactData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="phoneNumber"
                        columns={this.state.contactListColumns}
                        data={mappedContactData}
                        search
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitprops.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    data={contactData}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                    ref={this.node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex pagination pagination-rounded justify-content-end mb-2">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  <div>
                    <label htmlFor="groupSelection">Select Group:</label>
                    <select
                      id="groupSelection"
                      className="form-select"
                      onClick={this.handleGroupSelection}
                    >
                      <option value="">Select a Group</option>
                      <option value="existing">Add to Existing Group</option>
                      <option value="new">Create a New Group</option>
                    </select>
                  </div>
                  {this.state.selectedGroup === "existing" && (
                    <div className="mt-3">
                      <label htmlFor="groupSelection">Select Group:</label>
                      <select
                        id="groupSelection"
                        className="form-select"
                        onClick={event =>
                          this.handleExistingGroupSelection(event.target.value)
                        }
                      >
                        <option>Select a Group</option>
                        {Object.values(contactGroups).map((group, index) => (
                          <option value={group?.id} key={group?.id}>
                            {group?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {this.state.selectedGroup === "new" && (
                    <div className="mt-3">
                      <label htmlFor="groupSelection">Group Name :</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={event =>
                          this.handleInputChange(event.target.value)
                        }
                      ></input>
                    </div>
                  )}
                  <div className="mt-3 text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* </Row> */}
        </Container>
      </div>
    );
  }
}

Step3.propTypes = {
  onSetImportedContactsData: PropTypes.func,
  onSetImportedContactsFields: PropTypes.func,
  onSetMappedContactFields: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  contactData: contacts.importedContactData,
  contactFields: contacts.importedContactFields,
  mappedContactFields: contacts.mappedContactFields,
});

const mapDispatchToProps = dispatch => ({
  onSetImportedContactsData: data => dispatch(setImportedContactsData(data)),
  onSetImportedContactsFields: data =>
    dispatch(setImportedContactsFields(data)),
  onSetMappedContactFields: data => dispatch(setMappedContactFields(data)),
  onSetSelectedCsvFile: data => dispatch(setSelectedCsvFile(data)),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step3));
