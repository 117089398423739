import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { axiosPatch } from "helpers/api_helpers";
import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { setShowUploadBlockedNumbersModal } from "../../../store/blocked-numbers/actions";
import { toast } from "react-toastify";

class UploadBlockedNumbers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      fileError: "",
      phoneNumbers: [],
    };
  }

  toggle = () => {
    const { onSetShowUploadBlockedNumbersModal } = this.props;
    onSetShowUploadBlockedNumbersModal();
  };

  handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = e => {
        // Access the content of the CSV file
        const csvContent = e.target.result;
        this.handleCsvContent(csvContent);
      };

      // Read the file as text
      reader.readAsText(file);
      this.setState({ selectedFile: file });
    }
  };

  handleblockNumbers = async () => {
    const { phoneNumbers } = this.state;
    if (phoneNumbers.length === 0) {
      console.error("No contacts found!");
      return;
    }
    try {
      // Send a PATCH request to unblock contacts
      const block = true;
      const response = await axiosPatch("contact/unblock-block", {
        phoneNumbers,
        block,
        dndTrigger: "blocked",
      });
      if (response?.status === true) {
        this.props.getAllBlockContacts();

        toast.success(response?.message || "Contact blocked successfully!");
        this.setState({ deleteModal: false });
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.setState({ deleteModal: false });
      }
    } catch (error) {
      console.error("Error while unblocking contacts:", error);
    }
  };

  handleSubmit = async () => {
    const { selectedFile } = this.state;

    if (selectedFile) {
      // Perform your final action here
      await this.handleblockNumbers(); // Call handleUnblockNumbers
      this.toggle(); // Close the modal or perform any other action
    } else {
      this.setState({ fileError: "Please select a file." });
    }
  };

  handleCsvContent = csvContent => {
    const rows = csvContent.split("\n"); // Split into rows
    const phoneNumbers = [];

    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(",").map(column => column.trim());

      // Check if there are exactly 2 columns (countyCode, mobile)
      if (columns.length === 2) {
        const countryCode = columns[0];
        const phoneNumber = columns[1];

        // Create an object with phoneNumber
        const formattedPhoneNumber = "+" + countryCode + phoneNumber;
        phoneNumbers.push(formattedPhoneNumber);
      }
    }
    this.setState({ phoneNumbers });
  };

  render() {
    const { showUploadBlockedNumberModal } = this.props;
    const { fileError } = this.state;

    return (
      <Modal
        isOpen={showUploadBlockedNumberModal}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle} tag="h4">
          Import CSV
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <label htmlFor="file" className="form-label">
                  Upload CSV
                </label>
                <input
                  type="file"
                  id="file"
                  name="csvFile"
                  className="form-control"
                  accept=".csv"
                  onChange={this.handleFileChange}
                />
                {fileError && <div className="text-danger">{fileError}</div>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  onClick={this.handleSubmit}
                  className="btn btn-success save-user"
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

UploadBlockedNumbers.propTypes = {
  className: PropTypes.any,
  onSetShowUploadBlockedNumbersModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowUploadBlockedNumbersModal: () =>
    dispatch(setShowUploadBlockedNumbersModal()),
});

const mapStateToProps = ({ blockedNumbers }) => ({
  showUploadBlockedNumberModal: blockedNumbers.showUploadBlockedNumberModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UploadBlockedNumbers));
