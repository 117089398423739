import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import { setShowBuyNumberModal } from "../../../store/phone-number/actions";

import BuyNumber from "pages/PhoneNumber/Modal/BuyNumber";
import { axiosDel, axiosGet } from "helpers/api_helpers";
import { LIMIT, roles } from "constants/constants";
import { toast } from "react-toastify";
import UserForm from "./Modal/UserForm";

import { setLoader } from "../../../store/actions";
import { UserContext } from "../../../components/UserProvider/UserProvider";
import { FormControl } from "react-bootstrap";

class User extends Component {
  commonToast = React.createRef();
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      user: "",
      showUserModal: false,
      deleteModal: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      gender: "male",
      searchQuery: "",
      contactListColumns: [
        {
          text: "User",
          dataField: "name",
          sort: true,
        },

        {
          text: "Email",
          dataField: "email",
          sort: true,
        },
        {
          dataField: "phoneNumber",
          text: "Phone",
          sort: true,
        },
        {
          text: "Twilio Numbers",
          dataField: "assignNumbers",
          sort: true,
          formatter: cellContent => (
            <div className="">
              {cellContent?.length ? (
                cellContent?.map(number => (
                  <span
                    key={number}
                    className="badge rounded-pill bg-primary fs-6"
                  >
                    {number?.phoneNumber}
                  </span>
                ))
              ) : (
                <span className="badge rounded-pill bg-primary fs-6">
                  {cellContent?.length}
                </span>
              )}
            </div>
          ),
        },
        {
          text: "Whatsapp Numbers",
          dataField: "assignedWhatsappNumbers",
          sort: true,
          formatter: cellContent => (
            <div className="d-flex flex-wrap">
              {cellContent?.length ? (
                cellContent?.map(number => (
                  <div key={number} className="mb-2">
                    <span
                      key={number}
                      className="badge rounded-pill bg-primary fs-6 me-1"
                    >
                      {number?.phoneNumber}
                    </span>
                  </div>
                ))
              ) : (
                <span className="badge rounded-pill bg-primary fs-6">
                  {cellContent?.length}
                </span>
              )}
            </div>
          ),
        },
        {
          text: "Status",
          dataField: "status",
          sort: true,
          formatter: cellContent => (
            <span
              className={`badge rounded-pill ${
                cellContent === "active" ? "bg-success" : "bg-danger"
              }`}
            >
              {cellContent}
            </span>
          ),
        },

        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              {/* <a
                className="text-primary"
                href={`/login/${user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              > */}
              {user.role === roles.user ? (
                <Link className="text-primary" to={`/login/${user.id}`}>
                  <i
                    className="mdi mdi-login-variant font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="login"
                    id="login"
                  ></i>
                </Link>
              ) : (
                ""
              )}

              {/* </a> */}
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-account-edit-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="edit"
                  onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              {/* <Link className="text-warning" to="#">
                <i
                  className="mdi mdi-clipboard-edit-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="assign number"
                  onClick={() => this.handleReassign()}
                ></i>
              </Link> */}
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="delete"
                  onClick={() => this.onClickDelete(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = () => {
    this.getAllUsers(this.state.page);
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.getAllUsers(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.getAllUsers(this.state.page);
    });
  };
  handleSuccess = (msg, type) => {
    this.commonToast.current.showToast(msg, type);
  };

  handleError = (msg, type) => {
    this.commonToast.current.showToast(msg, type);
  };

  getAllUsers = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const users = await axiosGet(
        `users?populate=assignNumbers,assignedWhatsappNumbers&page=${page}&limit=${
          this.state.size
        }${searchQuery ? `&q=${searchQuery}` : ""}&sortBy=createdAt:desc`
      );
      if (users?.status) {
        this.setState({
          users: users?.data?.results,
          totalSize: users?.data?.totalResults,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error("error while getting all users :", error);
      onSetLoader(false);
    }
  };

  deleteUser = async () => {
    try {
      const response = await axiosDel(`/users/${this.state.user?.id}`);
      if (response?.status) {
        this.getAllUsers(this.state.page);
        this.setState({ deleteModal: false });
        toast.success(response?.message || "user deleted successfully!", {
          position: "top-right",
        });
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
    } catch (error) {
      console.error("error while adding user :", error);
    }
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  toggle = () => {
    this.setState(prevState => ({
      showUserModal: !prevState.showUserModal,
    }));
  };
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = users => {
    this.setState({ user: users });
    this.setState({ deleteModal: true });
  };

  handleReassign = () => {
    const { onSetShowBuyNumberModal } = this.props;
    onSetShowBuyNumberModal();
  };

  handleUserClicks = () => {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  };
  handleUserClick = arg => {
    const user = arg;

    this.setState({
      user: {
        id: user.id,
        name: user.name,
        phoneNumber: user.phoneNumber,
        email: user.email,
        uploadPermission: user.uploadPermission,
        status: user.status,
        gender: user.gender,
      },
      isEdit: true,
    });

    this.toggle();
  };

  handleGenderChange = event => {
    this.setState({ gender: event.target.value });
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.getAllUsers(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "User List";

    const { SearchBar } = Search;

    const { isEdit, deleteModal, users, user, showUserModal } = this.state;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: users.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
        <BuyNumber />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.deleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <UserForm
          showUserModal={showUserModal}
          toggle={this.toggle}
          user={user}
          getAllUsers={() => {
            this.getAllUsers(this.state.page);
          }}
          isEdit={isEdit}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="User" breadcrumbItem="Users List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New User
                        </Button>
                      </div>
                    </Col>
                    <BootstrapTable
                      keyField="id"
                      data={users}
                      columns={this.state.contactListColumns}
                    />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Limit
                        value={this.state.size}
                        onChange={this.handleLimitChange}
                      />
                      <Pagination
                        totalSize={this.state.totalSize}
                        handlePageClick={this.handlePageClick}
                        currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                        currentSize={this.state.size} // Use selectedLimit for current size
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

User.propTypes = {
  // users: PropTypes.array,
  className: PropTypes.any,
  onSetShowBuyNumberModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({});

const mapDispatchToProps = dispatch => ({
  onSetShowBuyNumberModal: () => dispatch(setShowBuyNumberModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));
