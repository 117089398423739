import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";

import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { connect } from "react-redux";

class ImportContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      activeTabVartical: 1,
      passedSteps: [1],
      passedStepsVertical: [1],
    };
    this.toggleTab.bind(this);
    this.toggleTabVertical.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  toggleTabVertical(tab) {
    if (this.state.activesTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        var modifiedSteps = [...this.state.passedStepsVertical, tab];
        this.setState({
          activeTabVartical: tab,
          passedStepsVertical: modifiedSteps,
        });
      }
    }
  }

  downloadSampleCSV = () => {
    // Sample CSV content
    const csvContent =
      "name,countryCode,phoneNumber,address,zip,state,city\n" +
      "Test1,1,3934919311,usa,287965,usa,usa\n" +
      "Test2,1,3934919322,usa,287965,usa,usa\n" +
      "Test3,1,3934919333,usa,287965,usa,usa";
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute and create a URL for the Blob
    link.download = "sample.csv";
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document and trigger a click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  render() {
    //meta title
    // const { activeTab } = this.state;
    document.title = "Import Contacts";

    const { contactData, contactFields, mappedContactFields } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Import Contacts"
              breadcrumbItem={`Step${this.state.activeTab}`}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Import Contacts</h4>
                    <div className="wizard clearfix">
                      <div className="mb-3 d-flex justify-content-end actions">
                        <button
                          className="btn download-csv-btn"
                          onClick={this.downloadSampleCSV}
                        >
                          Download Sample CSV file
                        </button>
                      </div>
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                            >
                              <span className="number">1.</span> Upload File
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 2,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(2)
                              }
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2);
                              }}
                            >
                              <span className="number">2.</span>{" "}
                              <span>Spreadsheet Details</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 3,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(3)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 3,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(3);
                              }}
                            >
                              <span className="number">3.</span> Confirm Details
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        {this.state.activeTab === 1 ? <Step1 /> : ""}
                        {this.state.activeTab === 2 ? <Step2 /> : ""}
                        {this.state.activeTab === 3 ? <Step3 /> : ""}
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              this.state.activeTab === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                this.toggleTab(this.state.activeTab - 1);
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          {this.state.activeTab !== 3 ? (
                            <li
                              className={
                                // this.state.activeTab === 3 ||
                                (contactData.length === 0 &&
                                  contactFields.length === 0) ||
                                (this.state.activeTab === 2 &&
                                  (!mappedContactFields?.phoneNumber ||
                                    !mappedContactFields?.countryCode))
                                  ? "next disabled"
                                  : "next"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  (contactData.length === 0 &&
                                    contactFields.length === 0) ||
                                  (this.state.activeTab === 2 &&
                                    (!mappedContactFields?.phoneNumber ||
                                      !mappedContactFields?.countryCode))
                                    ? ""
                                    : this.toggleTab(this.state.activeTab + 1);
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ contacts }) => ({
  contactData: contacts.importedContactData,
  contactFields: contacts.importedContactFields,
  mappedContactFields: contacts.mappedContactFields,
});

export default connect(mapStateToProps)(withRouter(ImportContacts));
