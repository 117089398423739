import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { axiosGet, axiosPost, axiosPatch } from "helpers/api_helpers";
import { connect } from "react-redux";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";
import { setLoader } from "../../store/actions";
import DeleteModal from "components/Common/DeleteModal";
import emptyContacts from "../../assets/images/emptyContact.png";
import Limit from "pages/pagination/Limit";
import Pagination from "pages/pagination/Pagination";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { setShowUploadBlockedNumbersModal } from "../../store/blocked-numbers/actions";
import UploadBlockedNumbers from "./Modal/UploadBlockedNumbers";
import { FormControl } from "react-bootstrap";

class BlockedNumbers extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      blockedNumbers: [],
      blockedNumber: "",
      selectedContacts: [],
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      isEdit: false,
      deleteModal: false,
      searchQuery: "",
      contactListColumns: [
        {
          text: "Name",
          dataField: "name",
          sort: true,
        },
        {
          text: "Number",
          dataField: "phoneNumberString",
          sort: true,
        },
        {
          text: "Trigger Event",
          dataField: "dndTrigger",
          sort: true,
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="bx bx-lock-open-alt font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Unblock"
                  id="unblock"
                  onClick={() => {
                    this.onclickUnblock(row);
                  }}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
      tooltipOpen: false,
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.handleUnblockNumbers = this.handleUnblockNumbers.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    // this.debounceSearch = this.debounceSearch.bind(this);
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  toggle = () => {
    const { onSetShowUploadBlockedNumbersModal } = this.props;
    onSetShowUploadBlockedNumbersModal();
  };
  componentDidMount() {
    this.handleGetAllcontacts(this.state.page);
  }
  onclickUnblock = row => {
    this.setState({ deleteModal: true, selectedContacts: [row] });
  };

  handleUserClicks = () => {
    this.setState({ quickMessage: "", isEdit: false });
    this.toggle();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllcontacts(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetAllcontacts(this.state.page);
    });
  };
  /* Insert,Update Delete data */
  handleGetAllcontacts = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `contact?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&dnd=true&sortBy=createdAt:desc`
      );
      if (response?.status === true) {
        this.setState({
          blockedNumbers: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at handleGetAllcontacts :", error);
    }
  };

  async handleUnblockNumbers(selectedContacts) {
    try {
      if (selectedContacts.length === 0) {
        console.error("No contacts found!");
        return;
      }

      // Extract phone numbers from selected contacts
      const phoneNumbers = selectedContacts.map(
        contact => contact.phoneNumberString
      );
      const block = false;

      // Send a PATCH request to unblock contacts
      const response = await axiosPatch("contact/unblock-block", {
        phoneNumbers,
        block,
        dndTrigger: " ",
      });

      if (response?.status === true) {
        this.handleGetAllcontacts(this.state.page);
        toast.success(response?.message || "Contact Unblocked successfully!");
        this.setState({ deleteModal: false, selectedContacts: [] });
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.setState({ deleteModal: false });
      }
    } catch (error) {
      console.error("Error while unblocking contacts:", error.message);
    }
  }

  // Method to handle row selection
  handleRowSelection = (row, isSelected) => {
    if (isSelected) {
      // Add selected contact to the state
      this.setState(prevState => ({
        selectedContacts: [...prevState.selectedContacts, row],
      }));
    } else {
      // Remove deselected contact from the state
      this.setState(prevState => ({
        selectedContacts: prevState.selectedContacts.filter(
          contact => contact.id !== row.id
        ),
      }));
    }
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = users => {
    this.setState({ users: users });
    this.setState({ deleteModal: true });
  };

  handleDeleteUser = () => {
    const { onDeleteUser } = this.props;
    const { users } = this.state;
    if (users.id !== undefined) {
      onDeleteUser(users);
      this.setState({ deleteModal: false });
    }
  };

  handleUserClick = arg => {
    const optInfo = arg;

    this.setState({
      optInfo: {
        id: optInfo.id,
        text: optInfo.text,
        optOut: optInfo.optOut,
      },
      isEdit: true,
    });

    this.toggle();
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  downloadSampleCSV = () => {
    // Sample CSV content
    const csvContent =
      "countyCode,mobile\n" +
      "1,3934919311\n" +
      "1,3934919322\n" +
      "1,3934919333";
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute and create a URL for the Blob
    link.download = "sample.csv";
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document and trigger a click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetAllcontacts(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "dnd";

    const { blockedNumbers } = this.state;

    const { deleteModal } = this.state;

    const selectRow = {
      mode: "checkbox",
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState(prevState => ({
            selectedContacts: [...prevState.selectedContacts, row],
          }));
        } else {
          this.setState(prevState => ({
            selectedContacts: prevState.selectedContacts.filter(
              contact => contact.id !== row.id
            ),
          }));
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        if (isSelect) {
          // Add all rows to selectedContacts
          this.setState({
            selectedContacts: rows,
          });
        } else {
          // Clear selectedContacts
          this.setState({
            selectedContacts: [],
          });
        }
      },
    };

    return (
      <React.Fragment>
        <UploadBlockedNumbers
          getAllBlockContacts={() => {
            this.handleGetAllcontacts(this.state.page);
          }}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <DeleteModal
              show={deleteModal}
              buttonText="Yes, Unblock it!"
              onDeleteClick={() =>
                this.handleUnblockNumbers(this.state?.selectedContacts)
              }
              onCloseClick={() =>
                this.setState({ deleteModal: false, selectedContacts: [] })
              }
            />
            <Breadcrumbs title="DND" breadcrumbItem="DND List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="13"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      <div className="text-sm-end me-3 d-flex justify-content-end">
                        <button
                          className="btn download-csv-btn me-3"
                          onClick={this.downloadSampleCSV}
                        >
                          Download Sample CSV file
                        </button>
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary me-3"
                          onClick={this.toggle}
                        >
                          <i className="bx bx-lock-open-alt me-1" />
                          Import Csv
                        </Button>
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          disabled={this.state.selectedContacts.length === 0}
                          onClick={() =>
                            this.handleUnblockNumbers(
                              this.state?.selectedContacts
                            )
                          } // Modify the onClick event to trigger unblocking
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Unblock Numbers
                        </Button>
                      </div>
                    </Col>
                    {blockedNumbers.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={blockedNumbers}
                            columns={this.state.contactListColumns}
                            selectRow={selectRow}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

BlockedNumbers.propTypes = {
  className: PropTypes.any,
  onSetShowUploadBlockedNumbersModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowUploadBlockedNumbersModal: () =>
    dispatch(setShowUploadBlockedNumbersModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ blockedNumbers }) => ({
  showUploadBlockedNumberModal: blockedNumbers.showUploadBlockedNumberModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BlockedNumbers));
