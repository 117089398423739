import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";
import { setShowTemplateModal } from "../../../store/campaign/actions";
import { axiosDel, axiosGet } from "helpers/api_helpers";
import moment from "moment";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/actions";
import Whatsapp from "./Modal/Whatsapp";
import EditWhatsapp from "./Modal/EditWhatsapp";
import emptyContacts from "../../../assets/images/emptyContact.png";
import { FormControl } from "react-bootstrap";

class WhatsappTemplate extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      templates: [],
      template: "",
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      modal: false,
      deleteModal: false,
      selectedNumberType: "local",
      searchQuery: "",
      templateListColumns: [
        {
          text: "Name",
          dataField: "templateName",
          sort: true,
        },
        {
          text: "Base Template Name",
          dataField: "template.name",
          sort: true,
        },
        {
          text: "Whatsapp Number",
          dataField: "whatsappNumberDoc",
          sort: true,
          formatter: cellContent => (
            <span>{`${cellContent?.name} : ${cellContent?.phoneNumber}`}</span>
          ),
        },
        {
          text: "Template Text",
          dataField: "templateContent",
          sort: true,
          formatter: (cellContent, template, rowIndex) => {
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {cellContent}
                </UncontrolledTooltip>

                <span id={tooltipId}>
                  {cellContent?.slice(0, 50)}
                  {cellContent?.length > 50 ? "..." : ""}
                </span>
              </>
            );
          },
        },
        {
          text: "Language",
          dataField: "template.language.code",
          sort: true,
        },
        {
          text: "Added On",
          dataField: "createdAt",
          sort: true,
          formatter: (cellContent, template) => (
            <span>
              {moment(template?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, template) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-clipboard-edit font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="re-assign number"
                  id="re-assign"
                  onClick={() => this.handleUserClick(template)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="delete"
                  onClick={() => this.onClickDelete(template)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount() {
    this.handleGetAllTemplates(this.state.page);
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllTemplates(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetAllTemplates(this.state.page);
    });
  };
  toggleEditModal = () => {
    this.setState({ isEdit: false });
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handleUserClicks = () => {
    this.setState({ template: "", isEdit: false });
    this.toggle();
  };

  handleUserClick = arg => {
    const template = arg;

    this.setState({
      template,
      isEdit: true,
    });

    // this.toggle();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */
  onClickDelete = template => {
    this.setState({ template, deleteModal: true });
  };

  handleGetAllTemplates = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const templates = await axiosGet(
        `whatsapp-template?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&populate=whatsappNumberDoc&sortBy=createdAt:desc`
      );
      if (templates?.status) {
        onSetLoader(false);
        this.setState({
          templates: templates?.data?.results,
          totalSize: templates?.data?.totalResults,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at handleGetAllTemplates :", error);
    }
  };

  handleDeleteTemplate = async () => {
    try {
      const { template } = this.state;
      const response = await axiosDel(`whatsapp-template/${template?.id}`);
      if (response?.status) {
        toast.success(response?.message || "Template deleted successfully");
        this.handleGetAllTemplates(this.state.page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.toggleDeleteModal();
      }
    } catch (error) {
      this.toggleDeleteModal();
      console.error("error at handleDeleteTemplate :", error);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetAllTemplates(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "Templates";

    const { isEdit, deleteModal, templates, template, modal } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteTemplate}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {modal ? (
          <Whatsapp
            showModal={modal}
            toggle={this.toggle}
            template={template}
            getAllTemplates={() => {
              this.handleGetAllTemplates(this.state.page);
            }}
          />
        ) : (
          ""
        )}
        {isEdit ? (
          <EditWhatsapp
            isEdit={isEdit}
            toggle={this.toggleEditModal}
            template={template}
            getAllTemplates={() => {
              this.handleGetAllTemplates(this.state.page);
            }}
          />
        ) : (
          ""
        )}
        <div className="page-content py-0">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Whatsapp Template
                        </Button>
                      </div>
                    </Col>
                    {templates.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={templates}
                            columns={this.state.templateListColumns}
                          />
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <Limit
                              value={this.state.size}
                              onChange={this.handleLimitChange}
                            />
                            <Pagination
                              totalSize={this.state.totalSize}
                              handlePageClick={this.handlePageClick}
                              currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                              currentSize={this.state.size} // Use selectedLimit for current size
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

WhatsappTemplate.propTypes = {
  className: PropTypes.any,
  onSetShowTemplateModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ campaign }) => ({
  showTemplateModal: campaign.showTemplateModal,
});

const mapDispatchToProps = dispatch => ({
  onSetShowTemplateModal: () => dispatch(setShowTemplateModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WhatsappTemplate));
