import { SHOW_BUY_NUMBER_MODAL, SHOW_REASSIGN_NUMBER_MODAL, SHOW_WHATSAPP_NUMBER_MODAL } from "./actionTypes"

const INIT_STATE = {
  showBuyNumberModal: false,
  showReassignNumberModal: false,
  showWhatsappNumberModal: false
}

const phoneNumber = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_BUY_NUMBER_MODAL:
      return {
        ...state,
        showBuyNumberModal: !state.showBuyNumberModal,
      }

    case SHOW_REASSIGN_NUMBER_MODAL:
      return {
        ...state,
        showReassignNumberModal: !state.showReassignNumberModal,
      }

    case SHOW_WHATSAPP_NUMBER_MODAL:
      return {
        ...state,
        showWhatsappNumberModal: !state.showWhatsappNumberModal,
      }

    default:
      return state
  }
}

export default phoneNumber
