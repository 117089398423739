
import { SHOW_ADD_NOTE_MODAL, SHOW_CHANGE_GROUP_MODAL, SHOW_NOTE_LIST_MODAL, SELECTED_CONTACT_ID, ACTIVE_TAB } from "./actionTypes"

const INIT_STATE = {
  activeTab: '2',
  showNoteListModal: false,
  showAddListModal: false,
  showChangeGroupModal: false,
  selectedContactId: null,
}

const inbox = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      }

    case SHOW_NOTE_LIST_MODAL:
      return {
        ...state,
        showNoteListModal: !state.showNoteListModal,
      }

    case SHOW_ADD_NOTE_MODAL:
      return {
        ...state,
        showAddListModal: !state.showAddListModal,
      }

    case SHOW_CHANGE_GROUP_MODAL:
      return {
        ...state,
        selectedContactId: !state.showChangeGroupModal ? state.selectedContactId : null,
        showChangeGroupModal: !state.showChangeGroupModal
      };

    case SELECTED_CONTACT_ID:
      return {
        ...state,
        selectedContactId: action.payload,
      };

    default:
      return state
  }
}

export default inbox
