import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  SET_IMPORTED_CONTACTS_DATA,
  SET_IMPORTED_CONTACTS_FIELDS,
  SET_MAPPED_CONTACT_FIELDS,
  SET_SELECTED_CSV_FILE,
  SET_SHOW_CONTACT_FORM_MODAL,
  SET_SHOW_GROUP_FORM_MODAL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
  importedContactData: [],
  importedContactFields: [],
  mappedContactFields: {},
  selectedCsvFile: [],
  showContactFormModal: false,
  showGroupFormModal: false
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_IMPORTED_CONTACTS_DATA:
      return {
        ...state,
        importedContactData: action.payload,
      }

    case SET_IMPORTED_CONTACTS_FIELDS:
      return {
        ...state,
        importedContactFields: action.payload,
      }

    case SET_MAPPED_CONTACT_FIELDS:
      return {
        ...state,
        mappedContactFields: action.payload,
      }

    case SET_SELECTED_CSV_FILE:
      return {
        ...state,
        selectedCsvFile: action.payload,
      }

    case SET_SHOW_CONTACT_FORM_MODAL:
      return {
        ...state,
        showContactFormModal: !state.showContactFormModal,
      }

    case SET_SHOW_GROUP_FORM_MODAL:
      return {
        ...state,
        showGroupFormModal: !state.showGroupFormModal,
      }

    default:
      return state
  }
}

export default contacts
