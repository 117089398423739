import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
  setShowSenderGroupModal,
  setShowAssignNumberModal,
} from "../../store/sender-group/actions";

import { setLoader } from "../../store/actions";

import SenderForm from "./Modal/SenderForm";
import AssignNumber from "./Modal/AssignNumber";
import { axiosDel, axiosGet, axiosPost } from "helpers/api_helpers";
import emptyContacts from "../../assets/images/emptyContact.png";
import { toast } from "react-toastify";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";
import { FormControl } from "react-bootstrap";

class SenderGroup extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      senderGroups: [],
      senderGroup: "",
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      selectedNumberType: "local",
      searchQuery: "",
      senderGroupListColumns: [
        {
          text: "User Name",
          dataField: "assignedUserName",
          sort: true,
        },
        {
          text: "Group Name",
          dataField: "name",
          sort: true,
        },
        {
          text: "Total Contact",
          dataField: "numberIds",
          sort: true,
          formatter: (cellContent, group) => (
            <span className="badge rounded-pill bg-primary fs-6">
              {cellContent.length}
            </span>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, group) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-clipboard-edit font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="edit"
                  id="edit-gtoup"
                  onClick={() => this.handleUserClick(group)}
                ></i>
              </Link>
              <Link className="text-primary" to="#">
                <i
                  className="fas fa-hashtag font-size-18 mt-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="assign number"
                  id="assign-number"
                  onClick={() => this.handleAssignNumberClick(group)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="delete"
                  onClick={() => this.onClickDelete(group)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = () => {
    this.getAllSenderGroups(this.state.page);
  };

  // toggle functions

  toggle = () => {
    const { onSetShowSenderGroupModal } = this.props;
    onSetShowSenderGroupModal();
  };

  toggleAssignNumber() {
    const { onSetShowAssignNumberModal } = this.props;
    onSetShowAssignNumberModal();
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  //for pagination
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.getAllSenderGroups(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.getAllSenderGroups(this.state.page);
    });
  };
  //on user click functions

  onClickDelete = senderGroup => {
    this.setState({ senderGroup });
    this.setState({ deleteModal: true });
  };

  handleUserClicks = () => {
    this.setState({ senderGroup: "", isEdit: false });
    this.toggle();
  };

  handleUserClick = arg => {
    this.setState({
      senderGroup: arg,
      isEdit: true,
    });
    this.toggle();
  };

  handleAssignNumberClick = arg => {
    this.setState({
      senderGroup: arg,
    });
    this.toggleAssignNumber();
  };

  // to get all sender groups
  getAllSenderGroups = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `sender-groups?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&sortBy=createdAt:desc`
      );
      if (response?.status) {
        onSetLoader(false);
        this.setState({
          senderGroups: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error while getting sender groups :", error);
    }
  };

  // to delete a sender group
  handleDeleteSenderGroup = async () => {
    try {
      const { senderGroup } = this.state;
      const response = await axiosDel(`sender-groups/${senderGroup?.id}`);
      if (response?.status) {
        toast.success(
          response?.message || "Sender Group deleted successfully!",
          {
            position: "top-right",
          }
        );
        this.getAllSenderGroups(this.state.page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error while deleting sender group :", error);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.getAllSenderGroups(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "Sender List";

    const { showSenderFormModal, showAssignNumberModal } = this.props;

    const { isEdit, deleteModal, senderGroups, senderGroup } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteSenderGroup()}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {showSenderFormModal && (
          <SenderForm
            isEdit={this.state.isEdit}
            group={isEdit ? senderGroup : ""}
            getAllSenderGroups={() => {
              this.getAllSenderGroups(this.state.page);
            }}
          />
        )}
        {showAssignNumberModal && (
          <AssignNumber
            group={senderGroup}
            getAllSenderGroups={() => {
              this.getAllSenderGroups(this.state.page);
            }}
          />
        )}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Sender Group" breadcrumbItem="Sender List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Sender Group
                        </Button>
                      </div>
                    </Col>
                    {senderGroups.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={senderGroups}
                            columns={this.state.senderGroupListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

SenderGroup.propTypes = {
  className: PropTypes.any,
  onSetShowSenderGroupModal: PropTypes.func,
  onSetShowAssignNumberModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ senderGroup }) => ({
  showSenderFormModal: senderGroup.showSenderFormModal,
  showAssignNumberModal: senderGroup.showAssignNumberModal,
});

const mapDispatchToProps = dispatch => ({
  onSetShowSenderGroupModal: () => dispatch(setShowSenderGroupModal()),
  onSetShowAssignNumberModal: () => dispatch(setShowAssignNumberModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SenderGroup));
