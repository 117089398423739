// DialPad.js
import axios from "axios";
import { axiosGet, axiosPatch } from "helpers/api_helpers";
import React, { Component } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Button, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import { deviceToken, LIMIT } from "constants/constants";
import { Device } from '@twilio/voice-sdk';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setElaspedTime, setIsCallInitiated, setStartTimer, setTwilioVoiceToken } from "store/actions";
import Stopwatch from "./Stopwatch";
import { toast } from "react-toastify";
import DeleteModal from "components/Common/DeleteModal";

class DialPad extends Component {
  static device = null; // Define a static class property to hold the Twilio Device instance

  constructor(props) {
    super(props);
    this.state = {
      elapsedTime: 0,
      phoneNumber: "",
      selectedOption: "",
      optionGroup: "",
      calls: "",
      currentCall: "",
      isDeviceOnline: false,
      isIncomingCallAccepted: false,
      incomingCall: false,
      incomingCallFromNumber: "",
      isCallInitiated: false,
      isCallMuted: false,
      userDisplayName: "",
      blockModal: false,
      numberToBlock: ""
    };

    // DialPad.device = new Device(props?.twilioVoiceToken, {
    //   enableImprovedSignalingErrorPrecision: true,
    //   // logLevel: 1,
    //   // codecPreferences: ["opus", "pcmu"]
    // }); // Set device here using props

    // this.registerDevice();

    // console.log('DialPad.device: ', DialPad.device);
    if (!DialPad.device) {
      DialPad.device = new Device(props?.twilioVoiceToken, {
        enableImprovedSignalingErrorPrecision: true,
        logLevel: 1,
        codecPreferences: ["opus", "pcmu"]
      });

      this.registerDevice();
    }

    if (DialPad.device) {
      DialPad.device.on('error', error => {
        console.error("device error :", error)
        // this.setState({ isDeviceOnline: false })
      });

      DialPad.device.on("registered", () => {
        // this.setState({ isDeviceOnline: true })
        console.log("Edge", DialPad.device.edge);
        console.log("Twilio.Device Ready to make and receive calls!");
      });

      DialPad.device.on('tokenWillExpire', async () => {
        console.log("token will expire soon");
        const token = await this.handleGetTwilioVoiceToken()
        DialPad.device.updateToken(token);
      });

      DialPad.device.on('unregistered', async () => {
        // this.setState({ isDeviceOnline: false })
        console.log("Twilio device unregistered");
      })

      DialPad.device.on('incoming', async (call) => {
        const { toggleDialPad, isOpenDialPad, onSetElaspedTime, onSetStartTimer } = this.props;
        this.setState({ incomingCall: true, currentCall: call, incomingCallFromNumber: call?.parameters?.From })
        await this.handleGetContactByPhoneNumberString(call?.parameters?.From)
        if (!isOpenDialPad) {
          toggleDialPad()
        }
        onSetElaspedTime(0)
        onSetStartTimer(true)

        call.on("cancel", () => {
          this.setState({ incomingCall: false, currentCall: "", isIncomingCallAccepted: false })
          console.log("call canceled");
          toggleDialPad()
          onSetElaspedTime(0)
          onSetStartTimer(false)
        });
        call.on("disconnect", () => {
          this.setState({ incomingCall: false, currentCall: "", isIncomingCallAccepted: false })
          console.log("call disconnected");
          toggleDialPad()
          onSetStartTimer(false)
          onSetElaspedTime(0)
        });
        call.on("reject", () => {
          this.setState({ incomingCall: false, currentCall: "", isIncomingCallAccepted: false })
          console.log("call rejected");
          toggleDialPad()
          onSetStartTimer(false)
          onSetElaspedTime(0)
        });
      });
    }

    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleNumberClick = this.handleNumberClick.bind(this);
  }

  registerDevice = async () => {
    try {
      // if (DialPad.device) {
      await DialPad.device.register();
      // }
    } catch (error) {
      console.error('dialpad error: ', error);
    }
  }

  updateElapsedTime = (elapsedTime) => {
    this.setState({ elapsedTime });
  };

  componentDidMount() {
    this.getBuyNumbers();
    const { to, from } = this.props
    if (to && from) {
      this.setState
      console.log("~~~~~~~~~~~~~~~~~ CALLING USING INBOX ~~~~~~~~~~~~~~~");
      this.setState({ phoneNumber: to, selectedOption: { label: from, value: from } })
    }
  }

  handleSetnumberToBlockOrUnblock = (numberToBlock) => {
    this.setState({ numberToBlock })
  }

  toggle = () => {
    this.setState(prevState => ({
      blockModal: !prevState.blockModal
    }));
  }

  handleSelect = selectedOption => {
    this.setState({ selectedOption });
  };

  handleNumberClick = number => {
    this.setState(prevState => ({
      phoneNumber: prevState.phoneNumber + number,
    }));
  };

  handleClearClick = () => {
    this.setState({
      phoneNumber: "",
    });
  };

  handleAcceptCall = () => {
    console.log("incoming call accepted");
    const { currentCall } = this.state
    const { onSetStartTimer } = this.props
    currentCall.accept()
    this.setState({ isIncomingCallAccepted: true })
    onSetStartTimer(true)
  }

  handleRejectCall = () => {
    console.log("incoming call rejected");
    const { currentCall } = this.state
    const { onSetStartTimer, startTimer } = this.props
    currentCall.reject()
    this.setState({ isIncomingCallAccepted: false })
    if (startTimer) {
      onSetStartTimer(false)
    }
  }

  handleGetContactByPhoneNumberString = async (phoneNumberString) => {
    try {
      const response = await axiosGet(`contact/${phoneNumberString}`)
      if (response.status) {
        this.setState({ userDisplayName: response.data })
      } else {
        this.setState({ userDisplayName: "" })
      }
    } catch (error) {
      console.error("error at handleGetContactByPhoneNumberString :", error)
    }
  }

  handleCallClick = async () => {
    try {
      const { phoneNumber, selectedOption } = this.state
      const { onSetStartTimer, onSetElaspedTime, onSetIsCallInitiated
      } = this.props

      if (DialPad.device && phoneNumber && Object.keys(selectedOption).length) {
        await this.handleGetContactByPhoneNumberString(phoneNumber)
        const calls = await DialPad.device.connect({ params: { To: phoneNumber, From: selectedOption?.value, OutBoundCall: true } });
        // this.setState({ isCallInitiated: true })
        this.setState({ calls })
        onSetElaspedTime(0)
        onSetIsCallInitiated(true)


        // Add listeners to the Call
        // calls.mute
        calls.on("ringing", () => {
          console.log("call ringing");
          // this.setState({ startTimer: true })
        });
        calls.on("accept", () => {
          console.log("accepted");
          onSetStartTimer(true)
        });
        calls.on("disconnect", () => {
          console.log("disconnect");
          onSetStartTimer(false)
          onSetElaspedTime(0)
          onSetIsCallInitiated(false)
          this.setState({ isCallInitiated: false, selectedOption: "", phoneNumber: "" })
        });
        calls.on("cancel", () => {
          console.log("cancel");
          onSetStartTimer(false)
          onSetElaspedTime(0)
          onSetIsCallInitiated(false)
          this.setState({ isCallInitiated: false, selectedOption: "", phoneNumber: "" })
        });
        calls.on("reject", () => {
          console.log("call rejected");
          onSetStartTimer(false)
          onSetElaspedTime(0)
          onSetIsCallInitiated(false)
          this.setState({ isCallInitiated: false, selectedOption: "", phoneNumber: "" })
        });
        calls.on("error", (error) => {
          console.log("an error occured", error);
          onSetStartTimer(false)
          onSetElaspedTime(0)
          onSetIsCallInitiated(false)
          this.setState({ isCallInitiated: false, selectedOption: "", phoneNumber: "" })
        });
      }
    } catch (error) {
      console.error("Error making call:", error);
    }
  };

  hangupCall = () => {
    const { onSetElaspedTime } = this.props
    console.log('The call has ended.');
    if (DialPad.device) {
      DialPad.device.disconnectAll();
    }
    onSetElaspedTime(0)
  }

  handleMuteAndUnMuteCall = () => {
    const { calls } = this.state
    if (calls.isMuted()) {
      calls.mute(false)
      console.log("Call muted");
    } else {
      calls.mute(true)
      console.log("Call unmuted");
    }

    this.setState({ isCallMuted: calls.isMuted() })
  }

  handleMuteAndUnMuteIncomingCall = () => {
    const { currentCall } = this.state
    if (currentCall.isMuted()) {
      currentCall.mute(false)
      console.log("currentCall muted");
    } else {
      currentCall.mute(true)
      console.log("currentCall unmuted");
    }

    this.setState({ isCallMuted: currentCall.isMuted() })
  }


  async getBuyNumbers() {
    try {
      const buyNumberList = await axiosGet(`buy-number?page=1&limit=${LIMIT}`);

      const options = buyNumberList?.data?.results?.map(result => ({
        value: result.phoneNumber,
        label: result.phoneNumber,
      }));

      this.setState({ optionGroup: options });
    } catch (error) {
      console.error(error);
    }
  }

  handleGetTwilioVoiceToken = async () => {
    try {
      const { onSetTwilioVoiceToken } = this.props
      const response = await axiosGet('private-cred/voice-access-token')
      if (response.status) {
        onSetTwilioVoiceToken(response?.data)
        return response.data
      }
    } catch (error) {
      console.error("error at handleGetTwilioVoiceToken in app.js", error)
    }
  }

  handleBlockContact = async (numberToBlock) => {
    try {
      const block = true
      const response = await axiosPatch("contact/unblock-block", {
        phoneNumbers: [numberToBlock],
        block,
        dndTrigger: "blocked"
      });
      if (response.status) {
        toast.success(response?.message || "Contact blocked successfully!")
      } else {
        toast.error(response?.message || "Oops ! something went wrong")
      }
    } catch (error) {
      console.error("error at handleBlockContact:", error)
    }
  }

  formatTime = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  render() {
    const { blockModal, numberToBlock, phoneNumber, incomingCall, isCallMuted, incomingCallFromNumber, isIncomingCallAccepted, isDeviceOnline, userDisplayName } = this.state;
    const { toggleDialPad, isOpenDialPad, startTimer, elaspedTime, isCallInitiated } = this.props;
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"];
    return (
      <div>
        <DeleteModal
          show={blockModal}
          buttonText="Yes, block it!"
          onDeleteClick={() =>
            this.handleBlockContact(numberToBlock)
          }
          onCloseClick={this.toggle}
        />
        <Offcanvas
          direction="end"
          toggle={toggleDialPad}
          isOpen={isOpenDialPad}
          className={`dialpad-container ${isCallInitiated || incomingCall ? "call-dialer" : ""}`}
        >
          <OffcanvasHeader toggle={toggleDialPad}>
            {isCallInitiated ? "" : "Call Dailer"}
          </OffcanvasHeader>
          <OffcanvasBody>
            {isCallInitiated ?
              <div className="d-flex justify-content-center flex-column text-light">
                <span className="fs-1 text-center">{userDisplayName?.name || "Unknown"}</span>
                <span className="fs-2 text-center">Calling...</span>
                <span className="mt-2 text-center fs-4">{phoneNumber || "+918160868308"}</span>
                {startTimer ? <span className="mt-2 text-center">{this.formatTime(elaspedTime)}</span> : ""}
                <div className="mt-5 d-flex justify-content-end align-items-center">
                  <span
                    className="me-5"
                    onClick={startTimer ? this.handleMuteAndUnMuteCall : ''}
                  >
                    <i className={`font-size-40 ${isCallMuted ? "fas fa-microphone-slash" : "fas fa-microphone"}`}></i>
                  </span>
                  <span
                    className="me-5"
                    onClick={() => {
                      this.handleSetnumberToBlockOrUnblock(phoneNumber);
                      this.toggle()
                    }}
                  >
                    <i className="font-size-40 fas fa-ban"></i>
                  </span>
                </div>
                <button
                  className="mt-5 btn btn-circle-dialer btn-danger btn-lg"
                  onClick={this.hangupCall}
                >
                  <i className="font-size-40 mdi mdi-phone"></i>
                </button>
              </div> : incomingCall ?
                <div className="d-flex justify-content-center flex-column text-light">
                  <span className="fs-1 text-center">{userDisplayName?.name || "Unknown"}</span>
                  <span className="fs-2 text-center">Incoming Call...</span>
                  <span className="mt-2 text-center fs-4">{incomingCallFromNumber || "+918160868308"}</span>
                  {startTimer ? <span className="mt-2 text-center">{this.formatTime(elaspedTime)}</span> : ""}
                  {isIncomingCallAccepted ?
                    <>
                      <div className="mt-5 d-flex justify-content-center align-items-center">
                        <UncontrolledTooltip placement="bottom" target="user-information">
                          <div className="d-flex flex-column">
                            <span>Name: {userDisplayName.name || '-'}</span>
                            <span className="text-nowrap">Phone Number: {userDisplayName?.phoneNumberString || userDisplayName?.phoneNumber || '-'}</span>
                          </div>
                        </UncontrolledTooltip>
                        <span
                          className="me-5"
                          id="user-information"
                        >
                          <i className="font-size-40 fas fa-info-circle"></i>
                        </span>
                        <span
                          className="me-5"
                          onClick={startTimer ? this.handleMuteAndUnMuteIncomingCall : ''}
                        >
                          <i className={`font-size-40 ${isCallMuted ? "fas fa-microphone-slash" : "fas fa-microphone"}`}></i>
                        </span>
                        <span
                          className=""
                          onClick={() => { this.handleSetnumberToBlockOrUnblock(incomingCallFromNumber); this.toggle(incomingCallFromNumber) }}
                        >
                          <i className="font-size-40 fas fa-ban"></i>
                        </span>

                      </div>
                      {/* <button
                        className="mt-5 btn btn-danger btn-lg"
                        onClick={this.hangupCall}
                      >
                        End Call
                      </button> */}
                      <button
                        className="mt-5 btn btn-circle-dialer btn-danger btn-lg"
                        onClick={this.hangupCall}
                      >
                        <i className="font-size-40 mdi mdi-phone"></i>
                      </button>
                    </>
                    : <div className="mt-5 d-flex justify-content-center">
                      <button
                        className="me-3 btn btn-success btn-lg"
                        onClick={this.handleAcceptCall}
                      >
                        {/* <i className={`mdi ${isCallMuted ? "mdi-microphone-off" : "mdi-microphone"}`}></i> */}
                        Accept
                      </button>
                      <button
                        className="btn btn-danger btn-lg"
                        onClick={this.handleRejectCall}
                      >
                        {/* <i className="mdi mdi-block-helper"></i> */}
                        Reject
                      </button>
                    </div>}

                </div> :
                <><Select
                  value={this.state.selectedOption}
                  onChange={this.handleSelect}
                  options={this.state.optionGroup}
                  classNamePrefix="select2-selection"
                  className="select-option"
                />
                  <div className="my-4">
                    <button className="btn btn-primary w-100">
                      {DialPad.device ? "Online" : "Offline"}
                    </button>
                  </div>
                  <div className="phone-input-container">
                    <PhoneInput
                      containerStyle={{ marginTop: "10px" }}
                      inputStyle={{ width: "250px", maxHeight: "100%" }}
                      defaultCountry="US"
                      value={phoneNumber}
                      onChange={phoneNumber => this.setState({ phoneNumber })}
                    />
                  </div>

                  <div className="number-pad">
                    {numbers.map(number => (
                      <button
                        className="btn btn-circle btn-outline-primary"
                        key={number}
                        onClick={() => this.handleNumberClick(number)}
                      >
                        {number}
                      </button>
                    ))}
                    <button
                      className="btn btn-circle btn-primary "
                      onClick={this.handleClearClick}
                    >
                      <i className="bx bx-left-arrow-alt"></i>
                    </button>
                    <button
                      className="btn btn-circle btn-primary"
                      onClick={this.handleCallClick}
                    >
                      <i className="bx mdi mdi-phone"></i>
                    </button>
                  </div></>}
          </OffcanvasBody>
        </Offcanvas>
      </div>
    );
  }
}

// export default DialPad;

const mapDispatchToProps = dispatch => ({
  onSetTwilioVoiceToken: (token) => dispatch(setTwilioVoiceToken(token)),
  onSetStartTimer: bool => dispatch(setStartTimer(bool)),
  onSetElaspedTime: value => dispatch(setElaspedTime(value)),
  onSetIsCallInitiated: bool => dispatch(setIsCallInitiated(bool)),
});

const mapStateToProps = ({ dialPad }) => ({
  twilioVoiceToken: dialPad.twilioVoiceToken,
  startTimer: dialPad.startTimer,
  elaspedTime: dialPad.elaspedTime,
  isCallInitiated: dialPad.isCallInitiated
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DialPad));
