import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import {
  channelType,
  PAGE,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
} from "constants/constants";

import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  setShowBuyNumberModal,
  setShowReassignNumberModal,
} from "../../store/phone-number/actions";

import { FormControl } from "react-bootstrap";
import BuyNumber from "./Modal/BuyNumber";
import { roles } from "constants/constants";
import { axiosDel, axiosGet, axiosPatch } from "helpers/api_helpers";
import emptyContacts from "../../assets/images/emptyContact.png";
import { toast } from "react-toastify";
import ReassignNumber from "./Modal/ReassignNumber";
import { UserContext } from "../../components/UserProvider/UserProvider";
import { setLoader } from "../../store/actions";

class PhoneNumber extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      userData: this.context?.userData,
      users: [],
      user: "",
      numbers: [],
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      number: "",
      deleteModal: false,
      selectedNumberType: "local",
      searchQuery: "",
      contactListColumns: [
        {
          text: "No",
          sort: true,
          dataField: "id",
          formatter: (cellContent, number, index) => (
            <>
              <span className="mb-1">{index + 1}</span>
            </>
          ),
        },
        {
          text: "Name",
          dataField: "assignedUserId.name",
          sort: true,
        },
        {
          text: "Number",
          dataField: "phoneNumber",
          sort: true,
        },
        {
          text: "Type",
          dataField: "numberType",
          sort: true,
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = () => {
    this.handleGetNumbers(this.state.page);
    const { userData } = this.context;

    if (userData?.role === roles.admin) {
      this.setState({
        contactListColumns: [
          ...this.state.contactListColumns,
          {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            formatter: (cellContent, numberInfo) => (
              <div className="d-flex gap-3">
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-clipboard-edit font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="re-assign number"
                    onClick={() => this.handleUserClick(numberInfo)}
                  ></i>
                </Link>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-clipboard-arrow-right-outline font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="release number"
                    onClick={() => this.onClickDelete(numberInfo)}
                  ></i>
                </Link>
              </div>
            ),
          },
        ],
      });
    }
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetNumbers(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetNumbers(this.state.page);
    });
  };

  toggle = () => {
    const { onSetShowBuyNumberModal } = this.props;
    onSetShowBuyNumberModal();
  };

  toggleReassignNumberModal = () => {
    const { onSetShowReassignNumberModal } = this.props;
    onSetShowReassignNumberModal();
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = numberInfo => {
    this.setState({ number: numberInfo, deleteModal: true });
  };

  // handles when user clicks buy number btn
  handleUserClicks = () => {
    this.setState({ number: "", isEdit: false });
    this.toggle();
  };

  // handles when user tries to re-assign a number to another user
  handleUserClick = numberInfo => {
    this.setState({
      number: numberInfo,
      isEdit: true,
    });

    this.toggleReassignNumberModal();
  };

  // to get list of all twilio number purchased
  handleGetNumbers = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const numbers = await axiosGet(
        `buy-number?populate=assignedUserId&page=${page}&limit=${
          this.state.size
        }${searchQuery ? `&q=${searchQuery}` : ""}&sortBy=createdAt:desc`
      );
      if (numbers?.status) {
        this.setState({
          numbers: numbers?.data?.results,
          totalSize: numbers?.data?.totalResults,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error while getting twilio numbers :", error);
    }
  };

  // to release a twilio number
  handleReleaseNumber = async () => {
    try {
      const { onSetLoader } = this.props;
      onSetLoader(true);
      const { number } = this.state;
      const response = await axiosDel(
        `buy-number/release-number?phoneSid=${number?.phoneSid}`
      );
      if (response?.status) {
        onSetLoader(false);
        toast.success(response?.message || "Twilio Number Released", {
          position: "top-right",
        });
        this.handleGetNumbers(this.state.page);
      } else {
        onSetLoader(false);
        toast.error(response?.message || "Oops! Something went wrong", {
          position: "top-right",
        });
      }
      this.toggleDeleteModal();
    } catch (error) {
      onSetLoader(false);
      console.error("error while releasing a twilio number :", error);
    }
  };

  // handles pagination
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetNumbers(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "Phone Numbers";

    const { userData } = this.context;

    const { deleteModal, numbers, number } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          buttonText="Yes, release it!"
          onDeleteClick={() => this.handleReleaseNumber()}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <BuyNumber
          getAllNumbers={() => {
            this.handleGetNumbers(this.state.page);
          }}
        />
        <ReassignNumber
          channeltype={channelType.sms}
          numberInfo={number}
          getAllNumbers={() => {
            this.handleGetNumbers(this.state.page);
          }}
        />
        <div className="page-content p-0">
          <Container fluid>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs
              title="Phone Numbers"
              breadcrumbItem="Phone Number List"
            /> */}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      {userData?.role === roles.admin ? (
                        <div className="text-sm-end mb-3">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={this.handleUserClicks}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Buy Number
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>

                    {numbers.length ? (
                      <>
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            data={numbers}
                            columns={this.state.contactListColumns}
                            classes={
                              "table align-middle table-nowrap table-hover"
                            }
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

PhoneNumber.propTypes = {
  className: PropTypes.any,
  //
  onSetShowBuyNumberModal: PropTypes.func,
  onSetShowReassignNumberModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ phoneNumber }) => ({
  modal: phoneNumber.showBuyNumberModal,
  showReassignNumberModal: phoneNumber.showReassignNumberModal,
});

const mapDispatchToProps = dispatch => ({
  onSetShowBuyNumberModal: () => dispatch(setShowBuyNumberModal()),
  onSetShowReassignNumberModal: () => dispatch(setShowReassignNumberModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PhoneNumber));
