import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Limit from "pages/pagination/Limit";
import Pagination from "pages/pagination/Pagination";
import emptyContacts from "../../assets/images/emptyContact.png";

import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import { setShowQuickSmsModal } from "../../store/quick-message/actions";

import { axiosDel, axiosGet } from "helpers/api_helpers";
import { toast } from "react-toastify";
import MessageForm from "./Modal/messageForm";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";
import moment from "moment";
import { setLoader } from "../../store/actions";
import { FormControl } from "react-bootstrap";

class QuickMessage extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      quickMessages: [],
      quickMessage: "",
      isEdit: false,
      contactIdToEdit: "",
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      selectedNumberType: "local",
      searchQuery: "",
      quickMessageListColumns: [
        {
          text: "Name",
          dataField: "name",
          sort: true,
        },
        {
          text: "Template Text",
          dataField: "content",
          sort: true,
          formatter: (cellContent, row, rowIndex) => {
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {cellContent}
                </UncontrolledTooltip>

                <span id={tooltipId}>
                  {cellContent?.slice(0, 30)}
                  {cellContent.length > 30 ? "..." : ""}
                </span>
              </>
            );
          },
        },
        {
          text: "Created On",
          dataField: "createdAt",
          sort: true,
          formatter: (cellContent, quickmessage) => (
            <span>
              {" "}
              {moment(quickmessage?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, quickmessage) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-clipboard-edit font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  id="edit"
                  onClick={() => this.handleUserClick(quickmessage)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                  onClick={() => this.onClickDelete(quickmessage)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = () => {
    this.handleGetAllQuickMessages(this.state.page);
  };

  toggle = () => {
    const { onSetShowQuickSmsModal } = this.props;
    onSetShowQuickSmsModal();
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handleUserClicks = () => {
    this.setState({ quickMessage: "", isEdit: false });
    this.toggle();
  };

  onClickDelete = quickmessage => {
    this.setState({ quickMessage: quickmessage, deleteModal: true });
  };

  handleUserClick = arg => {
    const quickMessage = arg;
    this.setState({
      quickMessage,
      isEdit: true,
    });
    this.toggle();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleGetAllQuickMessages = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `quick-message?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&sortBy=createdAt:desc`
      );
      if (response?.status) {
        onSetLoader(false);
        this.setState({
          quickMessages: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at handleGetAllQuickMessages :", error);
    }
  };

  handleDeleteQuickMessages = async () => {
    try {
      const { quickMessage } = this.state;
      const response = await axiosDel(`quick-message/${quickMessage?.id}`);
      if (response?.status) {
        toast.success(
          response?.message || "Quick message Deleted successfully!"
        );
        this.handleGetAllQuickMessages(this.state.page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error("error at handleGetAllQuickMessages :", error);
    }
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllQuickMessages(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetAllQuickMessages(1);
    });
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetAllQuickMessages(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "quick-sms";

    const { deleteModal, isEdit, quickMessage, quickMessages } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteQuickMessages}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <MessageForm
          isEdit={isEdit}
          quickMessage={quickMessage}
          getAllQuickMessages={() => {
            this.handleGetAllQuickMessages(this.state.page);
          }}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Quick Message"
              breadcrumbItem="Quick Message List"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-1">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>{" "}
                      <div className="text-sm-end me-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Quick Sms
                        </Button>
                      </div>
                    </Col>
                    {quickMessages.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={quickMessages}
                            columns={this.state.quickMessageListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

QuickMessage.propTypes = {
  className: PropTypes.any,
  onSetShowQuickSmsModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ quickMessage }) => ({
  showQuickSmsModal: quickMessage.showQuickSmsModal,
});

const mapDispatchToProps = dispatch => ({
  onSetShowQuickSmsModal: () => dispatch(setShowQuickSmsModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuickMessage));
