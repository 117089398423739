import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InboxMessage from "./InboxMessage";
import InboxWhatsappMessage from "./InboxWhatsappMessage";
import { connect } from "react-redux";
import { setActiveTab } from "store/actions";

class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "2",
      isMenu: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleTab = tab => {
    if (this.props.activeTab !== tab) {
      const { onSetActiveTab } = this.props;
      onSetActiveTab(tab);
    }
  };

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }));
  }

  render() {
    //meta title
    document.title = "inbox";

    const { activeTab } = this.props;

    const { selectedNotification } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Inbox"
              breadcrumbItem={`${activeTab === "1" ? "SMS" : "Whatsapp"}`}
            />
            <Row>
              <Col lg="12">
                {/* <Card>
                  <CardBody> */}
                <div className="crypto-buy-sell-nav">
                  <Nav tabs className="nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Whatsapp
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        SMS
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="">
                  {activeTab === "1" ? (
                    <InboxMessage
                      to={selectedNotification?.to}
                      from={selectedNotification?.from}
                    />
                  ) : (
                    ""
                  )}
                  {activeTab === "2" ? (
                    <InboxWhatsappMessage
                      to={selectedNotification?.to}
                      from={selectedNotification?.from}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSetActiveTab: index => dispatch(setActiveTab(index)),
});

const mapStateToProps = state => {
  return {
    selectedNotification: state.notification.selectedNotification,
    activeTab: state.inbox.activeTab,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
