import { CALL_INITIATED, ELAPSED_TIME, START_TIMER, TWILIO_VOICE_TOKEN } from "./actionTypes"

export const setTwilioVoiceToken = (token) => {
    return {
        type: TWILIO_VOICE_TOKEN,
        payload: token
    };
};

export const setStartTimer = (bool) => {
    return {
        type: START_TIMER,
        payload: bool
    };
};

export const setElaspedTime = (value) => {
    return {
        type: ELAPSED_TIME,
        payload: value
    };
};

export const setIsCallInitiated = (bool) => {
    return {
        type: CALL_INITIATED,
        payload: bool
    };
};

