import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowOptModal } from "../../../../store/user/actions";
import { optType } from "constants/constants";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";

class OptForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  toggle = () => {
    const { onSetShowOptModal } = this.props;
    onSetShowOptModal();
  };

  handleOptCreation = async optData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllOpts } = this.props;
      const response = await axiosPost("opt", optData);
      if (response?.status) {
        toast.success(response?.message || "Opt created successfully!");
        getAllOpts();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error at handleOptCreation :", error);
    }
  };

  handleOptUpdate = async optData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllOpts, optInfo } = this.props;
      const response = await axiosPatch(`opt/${optInfo?.id}`, optData);
      if (response?.status) {
        toast.success(response?.message || "Opt updated successfully!");
        getAllOpts();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error at handleOptUpdate :", error);
    }
  };

  handleOnSubmit = values => {
    const data = {
      optOut: values["optOut"],
      content: values["optText"],
    };
    const { isEdit } = this.props;
    if (isEdit) {
      this.handleOptUpdate(data);
    } else {
      this.handleOptCreation(data);
    }
  };

  render() {
    const { isEdit, showOptFormModal, optInfo } = this.props;
    const {showModalLoader}=this.state

    const initialValues = {
      optOut: optInfo?.optOut || "",
      optText: optInfo?.text || "",
    };

    const validationSchema = Yup.object().shape({
      optOut: Yup.string().required("Please select a opt out"),
      optText: Yup.string().required("Please enter the text to opt out"),
    });
    return (
      <Modal isOpen={showOptFormModal} className={this.props.className}>
        {showModalLoader?<ModalLoader/>:""}
        <ModalHeader toggle={this.toggle} tag="h4">
          {!!isEdit ? "Edit Opt" : "Add Opt"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Opt Out</Label>
                      <Field
                        as="select"
                        name="optOut"
                        className={
                          "form-control-select form-select-border-bottom" +
                          (errors.optOut && touched.optOut ? " is-invalid" : "")
                        }
                      >
                        <option value="">Select a opt out</option>
                        {Object.keys(optType).map(type => (
                          <option key={type} value={type}>
                            {optType[type]}
                          </option>
                        ))}{" "}
                      </Field>
                      <ErrorMessage
                        name="optOut"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Text</Label>
                      <Field
                        name="optText"
                        type="text"
                        className={
                          "form-control" +
                          (errors.optText && touched.optText
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="optText"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

OptForm.propTypes = {
  className: PropTypes.any,
  onSetShowOptModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowOptModal: () => dispatch(setShowOptModal()),
});

const mapStateToProps = ({ user }) => ({
  showOptFormModal: user.showOptFormModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OptForm));
