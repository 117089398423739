import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { setStartTimer } from "store/actions";
import { connect } from "react-redux";

class Stopwatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elapsedTime: 0,
      timer: null,
    };
  }

  componentDidMount = () => {
    const { startTimer } = this.props;
    if (startTimer) {
      this.startTimer();
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.startTimer !== this.props.startTimer) {
      if (this.props.startTimer) {
        // If stopTimer prop changes to true, stop the timer
        this.startTimer();
      } else {
        // If stopTimer prop changes to false, start the timer
        this.stopTimer();
      }
    }
  };

  startTimer = () => {
    if (!this.props.stopTimer) {
      this.setState({
        timer: setInterval(this.tick, 1000),
      });
    }
  };

  stopTimer = () => {
    clearInterval(this.state.timer);
    this.setState({ timer: null });
  };

  resetTimer = () => {
    this.stopTimer();
    this.setState({ elapsedTime: 0 });
  };

  tick = () => {
    const { elapsedTime } = this.state;
    const newElapsedTime = elapsedTime + 1;
    this.setState({ elapsedTime: newElapsedTime });
  };

  formatTime = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  render() {
    const { elapsedTime } = this.state;

    return (
      <div>
        <div>{this.formatTime(elapsedTime)}</div>
      </div>
    );
  }
}

// export default Stopwatch;

Stopwatch.propTypes = {
  startTimer: PropTypes.any,
  onSetShowUploadBlockedNumbersModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetStartTimer: bool => dispatch(setStartTimer(bool)),
});

const mapStateToProps = ({ dialPad }) => ({
  startTimer: dialPad.startTimer,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Stopwatch));
