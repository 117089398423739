
import { SHOW_TEMPLATE_MODAL } from "./actionTypes"

const INIT_STATE = {
  showTemplateModal: false,
}

const campaign = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_TEMPLATE_MODAL:
      return {
        ...state,
        showTemplateModal: !state.showTemplateModal,
      }

    default:
      return state
  }
}

export default campaign
