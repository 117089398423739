import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";

import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import { setShowGroupFormModal } from "../../../store/contacts/actions";

import GroupForm from "./Modal/GroupForm";
import emptyContacts from "../../../assets/images/emptyContact.png";

import { setLoader } from "../../../store/actions";
import { FormControl } from "react-bootstrap";
import { contactFieldsArray } from "constants/importcontacts";
import socketService from "utils/socket";

class ContactGroups extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      contactGroups: [],
      group: "",
      isEdit: false,
      groupIdToEdit: "",
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      selectedNumberType: "local",
      searchQuery: "",
      isDownloading: false,
      contactListColumns: [
        {
          text: "User Name",
          dataField: "assignedUserName",
          sort: true,
        },
        {
          text: "Group Name",
          dataField: "name",
          sort: true,
        },
        {
          text: "Total Contacts",
          dataField: "contactIds",
          sort: true,
          formatter: (cellContent, row) => (
            <div className="">
              <span className="badge rounded-pill bg-primary fs-6">
                {row?.status === "pending"
                  ? "Updating..."
                  : cellContent?.length}
              </span>
            </div>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, contactGroup) => (
            <div className="d-flex gap-3">
              <i
                className="text-success mdi mdi-clipboard-edit font-size-18"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
                id="edit"
                onClick={() => this.handleGroupEdit(contactGroup)}
              ></i>
              <Link
                className="text-primary"
                to={`/view-contacts/${contactGroup?.id}?page=${this.state.page}`}
              >
                <i
                  className="mdi mdi-eye-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="View Contacts"
                  id="viewContacts"
                ></i>
              </Link>
              <i
                className={`text-warning mdi mdi-download-circle-outline font-size-18  ${
                  this.state.group?.id === contactGroup?.id
                    ? "cursor-not-allowed"
                    : ""
                }`}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Download Contacts"
                id="downloadContacts"
                onClick={() => {
                  if (
                    // !this.state.isDownloading &&
                    this.state.group?.id !== contactGroup?.id
                  ) {
                    this.downloadCSV(contactGroup);
                  }
                }}
                // style={{
                //   cursor:
                //     this.state.group?.id === contactGroup?.id
                //       ? "not-allowed"
                //       : "pointer",
                // }}
              ></i>
              <i
                className="text-danger mdi mdi-trash-can-outline font-size-18"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
                onClick={() => this.onClickDelete(contactGroup)}
              ></i>
            </div>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = () => {
    // Create a new URLSearchParams object from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    // Accessing the value of the 'id' parameter
    const contactGroupPage = queryParams.get("page");
    if (contactGroupPage) {
      this.setState({ page: contactGroupPage }, () => {
        this.handleAllContactGroups(contactGroupPage);
      });
    } else {
      this.handleAllContactGroups(this.state.page);
    }

    socketService.on("contact-group", data => {
      toast.success(data?.message);
      this.handleAllContactGroups(this.state.page);
    });
  };

  createNewGroup = async groupName => {
    try {
      const response = await axiosPost("contact-groups", { name: groupName });
      if (response?.status) {
        toast.success(response?.message, {
          position: "top-right",
        });
        this.handleAllContactGroups(this.state.page);
        this.toggle();
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error while getting user :", error);
    }
  };

  EditGroup = async editedData => {
    try {
      const response = await axiosPatch(
        `contact-groups/${this.state.group?.id}`,
        editedData
      );
      if (response?.status) {
        toast.success(response?.message, {
          position: "top-right",
        });
        this.handleAllContactGroups(this.state.page);
        this.toggle();
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error while getting user :", error);
    }
  };

  deleteGroupById = async () => {
    try {
      const response = await axiosDel(`contact-groups/${this.state.group?.id}`);
      if (response?.status) {
        toast.success(response?.message, {
          position: "top-right",
        });
        this.handleAllContactGroups(this.state.page);
        this.setState({ deleteModal: false });
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error while adding user :", error);
    }
  };

  toggle = () => {
    const { onSetShowGroupFormModal } = this.props;
    onSetShowGroupFormModal();
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = contactGroup => {
    this.handleUserClick(contactGroup);
    this.setState({ deleteModal: true });
  };

  handleUserClick = arg => {
    const group = arg;

    this.setState({
      group: {
        id: group.id,
        name: group.name,
        assignedUserName: group.assignedUserName,
        assignedUserId: group.assignedUserId,
      },
    });
  };

  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleAllContactGroups(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleAllContactGroups(this.state.page);
    });
  };
  handleUserClicks = () => {
    this.setState({ group: "", isEdit: false });
    this.toggle();
  };

  handleGroupEdit = group => {
    const { onSetShowGroupFormModal } = this.props;
    this.setState({ isEdit: true, groupIdToEdit: group?.id });
    this.handleUserClick(group);
    onSetShowGroupFormModal();
  };

  handleAddGroup = () => {
    const { onSetShowGroupFormModal } = this.props;
    this.setState({ isEdit: false });
    onSetShowGroupFormModal();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleAllContactGroups = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;

    try {
      onSetLoader(true);
      const groups = await axiosGet(
        `contact-groups?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&sortBy=createdAt:desc`
      );
      if (groups?.status) {
        onSetLoader(false);
        this.setState({
          contactGroups: groups?.data?.results,
          totalSize: groups?.data?.totalResults,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error while getting all contact groups :", error);
    }
  };

  deleteGroupById = async () => {
    try {
      const response = await axiosDel(`contact-groups/${this.state.group?.id}`);
      if (response?.status) {
        toast.success(response?.message || "Group Deleted!", {
          position: "top-right",
        });
        this.handleAllContactGroups(this.state.page);
        this.setState({ deleteModal: false });
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error while deleting a contact group :", error);
    }
  };

  getGroupContactsById = async id => {
    try {
      const groups = await axiosGet(
        `contact-groups/${id}?page=1&limit=${this.state.size}`
      );
      if (groups?.status) {
        return groups?.data?.contactGroup?.contactIds;
      }
    } catch (error) {
      console.error("error while getting group contacts :", error);
    }
  };

  getAllContactsInContactGroup = async id => {
    try {
      const groups = await axiosGet(`contact-groups/contacts/${id}`);
      if (groups?.status) {
        return groups?.data?.contactIds;
      }
    } catch (error) {
      console.error("error while getting group contacts :", error);
    }
  };

  downloadCSV = async group => {
    this.setState({ isDownloading: true, group });
    const id = toast.loading(`Downloading ${group?.name}...`, {
      closeOnClick: true,
      autoClose: 3000,
    });
    try {
      const contacts = await this.getAllContactsInContactGroup(group?.id);
      if (contacts?.length > 0) {
        // Prepare CSV content
        // const csvContent =
        //   "name,countryCode,phoneNumber,address,zip,city,state\n" +
        //   contacts
        //     .map(
        //       contact =>
        //         `${contact?.name},${contact?.countryCode},${contact?.phoneNumber},"${contact?.address}",${contact?.zip},${contact?.city},${contact?.state}`
        //     )
        //     .join("\n");

        const headersString = contactFieldsArray.reduce(
          (str, header, index) => {
            str +=
              index === contactFieldsArray?.length - 1 ? header : `${header},`;
            return str;
          },
          ""
        );

        const valuesString = contacts
          .map((callObj, index) => {
            if (index % 1000 === 0) {
            }

            const val = contactFieldsArray.map(header => {
              // if (callObj?.[header]?.trim() === "") {
              //   return "-";
              // } else {

              return callObj?.[header]?.trim()?.replace(/["|.|,]/g, "") || "-";
              // }
            });

            const str = val.join(",").replace(/\n/g, "");
            return str;
          })
          .join("\n");

        let csvData = `${headersString}\n${valuesString}`;

        // Create a Blob and initiate download
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `${group.name}_contacts.csv`);
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        toast.update(id, {
          render: `Downloaded ${group?.name}!`,
          type: "success",
          isLoading: false,
        });
        this.setState({ isDownloading: false, group: "" });
      } else {
        toast.update(id, {
          render: `No Data available!`,
          type: "error",
          isLoading: false,
        });
        this.setState({ isDownloading: false, group: "" });
        // toast.error("No Data available!");
      }
    } catch (error) {
      console.error("error at downloadCSV: ", error);
      this.setState({ isDownloading: false, group: "" });
      toast.update(id, {
        render: "Oops something went wrong!",
        type: "error",
        isLoading: false,
      });
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleAllContactGroups(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "Contact Groups";

    const { group, contactGroups } = this.state;

    const { deleteModal } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.deleteGroupById()}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <GroupForm
          isEdit={this.state.isEdit}
          id={this.state.isEdit ? this.state.groupIdToEdit : ""}
          group={this.state.isEdit ? group : ""}
          getAllContactGroups={() => {
            this.handleAllContactGroups(this.state.page);
          }}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Contacts" breadcrumbItem="Contacts Groups" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleAddGroup}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Group
                        </Button>
                      </div>
                    </Col>
                    {contactGroups.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={contactGroups}
                            columns={this.state.contactListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ContactGroups.propTypes = {
  className: PropTypes.any,
  onSetShowGroupFormModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowGroupFormModal: () => dispatch(setShowGroupFormModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ contacts }) => ({
  showGroupFormModal: contacts.showGroupFormModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactGroups));
