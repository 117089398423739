import axios from "axios";
import { getDataFromLocalStorage, setDataInLocalStorage } from "./common_helpers";
import accessToken from "./jwt-token-access/accessToken";

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosApi.interceptors.request.use(
  config => {
    const token = getDataFromLocalStorage("accessToken")

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message === "Please authenticate" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getDataFromLocalStorage("refreshToken")


      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/refresh-tokens`,
          {
            refreshToken: refreshToken,
          }
        );
        if (res.status === 200) {
          setDataInLocalStorage(
            "accessToken",
            res?.data?.tokens?.access?.token
          );
          setDataInLocalStorage(
            "refreshToken",
            res?.data?.tokens?.refresh?.token
          );
          setDataInLocalStorage("accessToken",
            res?.data?.tokens?.access?.token)

          setDataInLocalStorage("refreshToken",
            res?.data?.tokens?.refresh?.token)

          error.config.headers[
            "Authorization"
          ] = `Bearer ${res.data.tokens.access.token}`;

          return axiosApi(error.config);
        } else {
          localStorage.clear();
          sessionStorage.clear()
          window.location.replace("/login");
        }
      } catch (refreshError) {
        localStorage.clear();
        sessionStorage.clear()
        window.location.replace("/login");
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
