import { SHOW_ADD_NOTE_MODAL, SHOW_CHANGE_GROUP_MODAL, SHOW_NOTE_LIST_MODAL, SELECTED_CONTACT_ID, ACTIVE_TAB } from "./actionTypes"

export const setActiveTab = (activeTab) => {
  return {
    type: ACTIVE_TAB,
    payload: activeTab,
  };
};

export const setShowNoteListModal = () => {
  return {
    type: SHOW_NOTE_LIST_MODAL,
  };
};

export const setShowAddNoteModal = () => {
  return {
    type: SHOW_ADD_NOTE_MODAL,
  };
};

export const setShowChangeGroupModal = () => {
  return {
    type: SHOW_CHANGE_GROUP_MODAL,
  };
};
export const setSelectedContactId = (contactId) => {
  return {
    type: SELECTED_CONTACT_ID,
    payload: contactId,
  };
};

