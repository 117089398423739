import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowReassignNumberModal } from "../../../store/phone-number/actions";
import { channelType, LIMIT } from "constants/constants";
import { axiosGet, axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";

const validationSchema = Yup.object().shape({
  assignedUser: Yup.string().required("Please select a number to assign"),
});

class ReassignNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showModalSpinner: false,
      initialValues: {
        assignedUser: this.props.numberInfo?.assignedUserId?.id || "",
      },
    };
  }

  componentDidMount = () => {
    this.getAllUsers();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.numberInfo?.assignedUserId?.id !==
      prevProps?.numberInfo?.assignedUserId?.id
    ) {
      this.setState({
        initialValues: {
          assignedUser: this.props?.numberInfo?.assignedUserId?.id,
        },
      });
    }
  }

  // to toggle the modal
  toggle = () => {
    const { onSetShowReassignNumberModal } = this.props;
    onSetShowReassignNumberModal();
  };

  // to get all users list
  getAllUsers = async () => {
    try {
      const users = await axiosGet(`users?page=1&limit=${LIMIT}`);
      if (users?.status) {
        this.setState({ users: users?.data?.results });
      }
    } catch (error) {
      console.error("error while getting all users :", error);
    }
  };

  //to re-assign number to another user
  handleReassignNumber = async assignedUserId => {
    try {
      this.setState({ showModalSpinner: true });
      const { numberInfo, getAllNumbers } = this.props;
      const response = await axiosPatch(`buy-number/${numberInfo?.id}`, {
        assignedUserId,
      });
      if (response?.status) {
        this.setState({ showModalSpinner: false });
        toast.success(response?.message || "Number Re-assigned successfully", {
          position: "top-right",
        });
        getAllNumbers();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! Something went wrong", {
          position: "top-right",
        });
        this.setState({ showModalSpinner: false });
      }
    } catch (error) {
      this.setState({ showModalSpinner: true });
      console.error("error while re-assigning number :", error);
    }
  };

  //to re-assign whatsapp number to another user
  handleReassignWhatsappNumber = async assignedUserId => {
    try {
      this.setState({ showModalSpinner: true });
      const { numberInfo, getAllNumbers } = this.props;
      const response = await axiosPatch(`whatsapp-number/${numberInfo?.id}`, {
        assignedUserId,
      });
      if (response?.status) {
        this.setState({ showModalSpinner: false });
        toast.success(
          response?.message || "Whatsapp Number Re-assigned successfully",
          {
            position: "top-right",
          }
        );
        getAllNumbers();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! Something went wrong", {
          position: "top-right",
        });
        this.setState({ showModalSpinner: false });
      }
    } catch (error) {
      this.setState({ showModalSpinner: true });
      console.error("error while re-assigning whatsapp number :", error);
    }
  };

  //Formik onSubmit

  handleOnSubmit = values => {
    const { numberInfo, channeltype } = this.props;
    if (values["assignedUser"] !== numberInfo?.assignedUserId?.id) {
      if (channeltype === channelType.sms) {
        this.handleReassignNumber(values["assignedUser"]);
      } else if (channeltype === channelType.whatsapp) {
        this.handleReassignWhatsappNumber(values["assignedUser"]);
      }
    } else {
      toast.error("Select a different user", {
        position: "top-right",
      });
    }
  };

  render() {
    const { showReassignNumberModal, channeltype, numberInfo } = this.props;
    const { users, showModalSpinner } = this.state;
    return (
      <Modal isOpen={showReassignNumberModal} className={this.props.className}>
        {showModalSpinner ? <ModalLoader /> : ""}
        <ModalHeader toggle={this.toggle} tag="h4">
          {channeltype === channelType.sms
            ? "Re-assign Twilio Number"
            : "Re-assign Whatsapp Number"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">User</Label>
                      <Field
                        as="select"
                        name="assignedUser"
                        className={
                          "form-control-select" +
                          (errors.assignedUser && touched.assignedUser
                            ? " is-invalid"
                            : "")
                        }
                      >
                        <option value="">Select a user</option>
                        {users?.map((user, index) => (
                          <option
                            value={user?.id}
                            key={`${index}-${user?.name}`}
                          >
                            {user?.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="assignedUser"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

ReassignNumber.propTypes = {
  className: PropTypes.any,
  onSetShowReassignNumberModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowReassignNumberModal: () => dispatch(setShowReassignNumberModal()),
});

const mapStateToProps = ({ phoneNumber }) => ({
  showReassignNumberModal: phoneNumber.showReassignNumberModal,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReassignNumber));
