import { CLOSE_TOAST, SHOW_TOAST } from "./actionTypes";

const INIT_STATE = {
  show: false,
  type: "error",
  message: "",
  duration: 2000,
  title: "Toast",
};

const tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        show: true,
        type: action.payload.type,
        title: action.payload.title,
        message: action.payload.message,
        duration: action.payload.duration,
      };

    case CLOSE_TOAST:
      return {
        ...state,
        show: false,
        type: "error",
        title: "Toast",
        message: "",
        duration: 2000,
      };

    default:
      return state;
  }
};

export default tasks;
