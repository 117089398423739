import { SHOW_BUY_NUMBER_MODAL, SHOW_REASSIGN_NUMBER_MODAL, SHOW_WHATSAPP_NUMBER_MODAL } from "./actionTypes"

export const setShowBuyNumberModal = () => {
  return {
    type: SHOW_BUY_NUMBER_MODAL,
  };
};

export const setShowReassignNumberModal = () => {
  return {
    type: SHOW_REASSIGN_NUMBER_MODAL,
  };
};

export const setShowWhatsappNumberModal = () => {
  return {
    type: SHOW_WHATSAPP_NUMBER_MODAL,
  };
};

