import { SET_CONTACT_GROUPS } from './actionTypes';

const initialState = {
  contactGroups: [],
};

const contactgroup = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_GROUPS:
      return {
        ...state,
        contactGroups: action.payload,
      };
    default:
      return state;
  }
};

export default contactgroup;