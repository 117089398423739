import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-drawer/lib/react-drawer.css";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";
import { UserContext } from "../../components/UserProvider/UserProvider";

// Redux Store
import {
  toggleRightSidebar,
  toggleInitializeNewSocket,
  updateNotification,
} from "../../store/actions";
import DialPad from "components/CallDialer/DialPad";
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "helpers/common_helpers";
import zappycomLogo from "../../assets/images/zappycom.png";
import { axiosGet } from "helpers/api_helpers";

class Header extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
      isDialPadOpen: false,
    };
    this.toggleDialPad = this.toggleDialPad.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  toggleDialPad() {
    this.setState(prevState => ({
      isDialPadOpen: !prevState.isDialPadOpen,
    }));
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  handleGetUnreadMessages = async () => {
    try {
      const { updateNotification } = this.props;
      const response = await axiosGet("/conversation/unread-messages");
      if (response?.status) {
        updateNotification(response?.data);
      }
    } catch (error) {
      console.error("error at handleNotificationClick", error);
    }
  };

  handleBackToAdmin = async () => {
    const { getUserinfo } = this.context;
    const { toggleInitializeNewSocket, initializeSocket } = this.props;
    setDataInLocalStorage(
      "accessToken",
      getDataFromLocalStorage("adminAccessToken")
    );
    setDataInLocalStorage(
      "refreshToken",
      getDataFromLocalStorage("adminRefreshToken")
    );
    localStorage.removeItem("adminAccessToken");
    localStorage.removeItem("adminRefreshToken");
    await getUserinfo();
    toggleInitializeNewSocket();
    this.handleGetUnreadMessages();
  };

  render() {
    const { isDialPadOpen } = this.state;
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box d-lg-none d-md-block">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src={zappycomLogo}
                      alt=""
                      height="35"
                      // className="rounded-circle"
                    />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      src={zappycomLogo}
                      alt=""
                      height="35"
                      // className="rounded-circle"
                    />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>
            <div className="d-flex">
              {getDataFromLocalStorage("adminAccessToken") ? (
                <div className="d-flex align-items-center me-2">
                  <button
                    type="button"
                    onClick={this.handleBackToAdmin}
                    className="btn btn-primary d-flex justify-content-center align-items-center"
                    id="vertical-menu-btn"
                  >
                    <i className="mdi mdi-account-arrow-left-outline font-size-16 me-1"></i>
                    <span className="">Admin</span>
                  </button>
                </div>
              ) : (
                ""
              )}

              <NotificationDropdown />
              <div className="dropdown d-inline-block">
                <button
                  className="btn header-item noti-icon"
                  data-toggle="fullscreen"
                  onClick={this.toggleDialPad}
                >
                  <i className="bx bxs-phone"></i>
                </button>
              </div>
              <ProfileMenu />
              <DialPad
                isOpenDialPad={isDialPadOpen}
                toggleDialPad={this.toggleDialPad}
              />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  showRightSidebar: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
};

const mapStatetoProps = state => {
  const { adminLoggedAsUser } = state.user;
  const { layoutType, showRightSidebar } = state.Layout;
  const { initializeSocket } = state.Login;
  return { layoutType, showRightSidebar, adminLoggedAsUser, initializeSocket };
};

export default connect(mapStatetoProps, {
  toggleRightSidebar,
  toggleInitializeNewSocket,
  updateNotification,
})(withTranslation()(Header));
