import React, { Component } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SmsTemplate from "./SmsTemplate";
import WhatsappTemplate from "./WhatsappTemplate";

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      isMenu: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }));
  }

  render() {
    //meta title
    document.title = "inbox";

    const { activeTab } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Templates"
              breadcrumbItem={`${activeTab === "1" ? "SMS" : "Whatsapp"}`}
            />
            <Row>
              <Col lg="12">
                {/* <Card>
                  <CardBody> */}
                <div className="crypto-buy-sell-nav">
                  <Nav tabs className="nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        SMS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Whatsapp
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="">
                  {this.state.activeTab === "1" ? <SmsTemplate /> : ""}
                  {this.state.activeTab === "2" ? <WhatsappTemplate /> : ""}
                </div>
                {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Templates;
