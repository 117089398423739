import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Tooltip } from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../components/UserProvider/UserProvider";
import { roles } from "constants/constants";
import { axiosGet, axiosPatch } from "helpers/api_helpers";

class FormLayouts extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        coPilotToken: "",
        accountSid: "",
        authToken: "",
        systemUserAccessToken: "",
        AccounttooltipOpen: false,
        AuthtooltipOpen: false,
        MetatooltipOpen: false,
        CoPilottooltipOpen: false,
      },
      twilioCredentials: "",
    };
  }

  componentDidMount = () => {
    const { getUserinfo } = this.context;
    getUserinfo();
    this.handleGetTwilioCredentials();
  };
  toggleAccountTooltip = () => {
    this.setState({
      AccounttooltipOpen: !this.state.AccounttooltipOpen,
    });
  };
  toggleAuthTooltip = () => {
    this.setState({
      AuthtooltipOpen: !this.state.AuthtooltipOpen,
    });
  };
  toggleMetaTooltip = () => {
    this.setState({
      MetatooltipOpen: !this.state.MetatooltipOpen,
    });
  };
  toggleCoPilotTooltip = () => {
    this.setState({
      CoPilottooltipOpen: !this.state.CoPilottooltipOpen,
    });
  };
  handleGetTwilioCredentials = async () => {
    try {
      const response = await axiosGet("private-cred");
      if (response?.status) {
        const { initialValues } = this.state;
        const updatedInitialValues = {
          ...initialValues,
          coPilotToken: response.data.copilotId || "",
          accountSid: response.data.sid || "",
          authToken: response.data.authToken || "",
          systemUserAccessToken: response.data.metaSystemUserAccessToken || "",
        };
        this.setState({
          twilioCredentials: response.data,
          initialValues: updatedInitialValues,
        });
      } else {
        toast.error(response?.message || "cannot get twilio credentials");
      }
    } catch (error) {
      console.error("error while updating twilio credentials:", error);
    }
  };

  handleUpdateTwilioCredentials = async twilioCred => {
    try {
      const response = await axiosPatch("private-cred", twilioCred);
      if (response?.status) {
        this.handleGetTwilioCredentials();
        toast.success(
          response?.message || "Twilio credentials updated successfully!"
        );
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
    } catch (error) {
      console.error("error while updating twilio credentials:", error);
    }
  };

  onSubmit = (values, formik) => {
    const { twilioCredentials } = this.state;
    const { userData } = this.context;
    formik?.resetForm();

    let editedData = {};
    const isTwilioSidChanged = values["accountSid"] !== twilioCredentials?.sid;
    const isAuthTokenChanged =
      values["authToken"] !== twilioCredentials?.authToken;
    const isSystemUserAccessTokenChanged =
      values["systemUserAccessToken"] !==
      twilioCredentials?.systemUserAccessToken;

    const isCoPilotTokenChanged =
      values["coPilotToken"] !== twilioCredentials?.copilotId;

    if (isTwilioSidChanged) {
      editedData.sid = values["accountSid"];
    }
    if (isAuthTokenChanged) {
      editedData.authToken = values["authToken"];
    }
    if (isSystemUserAccessTokenChanged) {
      editedData.metaSystemUserAccessToken = values["systemUserAccessToken"];
    }
    if (isCoPilotTokenChanged) {
      editedData.copilotId = values["coPilotToken"];
    }
    if (
      (userData?.role === roles.admin && isTwilioSidChanged) ||
      (userData?.role === roles.admin && isAuthTokenChanged) ||
      (userData?.role === roles.admin && isSystemUserAccessTokenChanged) ||
      isCoPilotTokenChanged
    ) {
      this.handleUpdateTwilioCredentials(editedData);
    }
  };

  render() {
    document.title = "Settings";
    const { initialValues, twilioCredentials } = this.state;
    const { userData } = this.context;

    const validationSchema = Yup.object().shape({
      coPilotToken: Yup.string(),
      ...(userData?.role === roles.admin
        ? {
            accountSid: Yup.string().required("Account SID is required"),
            authToken: Yup.string().required("Auth Token is required"),
            systemUserAccessToken: Yup.string().required(
              "System User Access Token is required"
            ),
          }
        : {}),
    });

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Settings" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Twilio API Key Management
                    </CardTitle>

                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={this.onSubmit}
                    >
                      {({ handleSubmit, isSubmitting, errors, touched }) => (
                        <Form onSubmit={handleSubmit}>
                          {userData?.role === roles.admin && (
                            <>
                              <div className="row mb-4">
                                <Label
                                  htmlFor="horizontal-sid-Input"
                                  className="col-sm-2 col-form-label"      
                                >
                                  Account SID{"   "} <i className="fas fa-info-circle" id="accountSidTooltip">
                                 
                                  </i>
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={this.state.AccounttooltipOpen}
                                    target="accountSidTooltip"
                                    toggle={this.toggleAccountTooltip}
                                  >
                                  Enter your Twilio account sid here.
                                  </Tooltip>
                                </Label>   
                               
 
                                <Col sm={9}>
                                  <Field
                                    name="accountSid"
                                    type="text"
                                    className={
                                      "form-control mb-2" +
                                      (errors.accountSid && touched.accountSid
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                 
                                  <ErrorMessage
                                    name="accountSid"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </div>
                              <div className="row mb-4">
                                <Label
                                  htmlFor="horizontal-token-Input"
                                  className="col-sm-2 col-form-label"
                                >
                                  Auth Token{"   "} <i className="fas fa-info-circle" id="authTokenTooltip">         
                                  </i>
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={this.state.AuthtooltipOpen}
                                    target="authTokenTooltip"
                                    toggle={this.toggleAuthTooltip}
                                  >
                                     Enter your Twilio Auth Token here.
                                  </Tooltip>
                                </Label>
                                <Col sm={9}>
                                  <Field
                                    name="authToken"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.authToken && touched.authToken
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                
                                  <ErrorMessage
                                    name="authToken"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </div>
                              <div className="row mb-4">
                                <Label
                                  htmlFor="horizontal-token-Input"
                                  className="col-sm-2 col-form-label"
                                >
                                  System User Access Token
                                  {"   "}
                                    <i className="fas fa-info-circle" id="metaTooltip">
                                  </i>
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={this.state.MetatooltipOpen}
                                    target="metaTooltip"
                                    toggle={this.toggleMetaTooltip}
                                  >
                                     Enter your Meta System User Access Token.
                                  </Tooltip>
                                </Label>
                                <Col sm={9}>
                                  <Field
                                    name="systemUserAccessToken"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.systemUserAccessToken &&
                                      touched.systemUserAccessToken
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                 
                                  <ErrorMessage
                                    name="systemUserAccessToken"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </div>
                            </>
                          )}

                          <div className="row mb-4">
                            <Label
                              htmlFor="horizontal-token-Input"
                              className="col-sm-2 col-form-label"
                            >
                              Co-Pilot{" "}
                              <i className="fas fa-info-circle" id="coPilotTooltip">
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={this.state.CoPilottooltipOpen}
                                    target="coPilotTooltip"
                                    toggle={this.toggleCoPilotTooltip}
                                  >
                                   Enter your Twilio Co-pilot id here.
                                  </Tooltip>
                                  </i>
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="coPilotToken"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.coPilotToken && touched.coPilotToken
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                             
                              <ErrorMessage
                                name="coPilotToken"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </div>
                          <div className="row justify-content-end">
                            <Col sm={12}>
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="w-md"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Col>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormLayouts;
