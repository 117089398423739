import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { axiosGet } from "helpers/api_helpers";
import emptyContacts from "../../../assets/images/emptyContact.png";
import moment from "moment";
import {
  channelType,
  PAGE,
  reports,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
} from "constants/constants";
import { setLoader } from "../../../store/actions";

class ViewReport extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      campaignReport: [],
      campaignData: {},
      campaignStatistics: {},
      whatsappNumber: {},
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      contactListColumns: [
        {
          text: "To Number",
          dataField: "to",
          sort: true,
        },

        {
          text: "From Number",
          dataField: "from",
          sort: true,
          formatter: (cellContent, report) => {
            const { whatsappNumber } = this.state;
            if (this.state.campaignData?.usingCopilot) {
              if (cellContent) {
                return <span>{`co-pilot-${cellContent}`}</span>;
              } else {
                return <span>co-pilot</span>;
              }
            } else if (
              this.state.campaignData?.channel === channelType.whatsapp
            ) {
              return <span>{`${whatsappNumber?.name} : ${cellContent}`}</span>;
            } else {
              return <span>{cellContent}</span>;
            }
          },
        },
        {
          text: "Message",
          dataField: "whatsappMessage.templateContent" || "message",
          sort: true,
          formatter: (cellContent, report, rowIndex) => {
            // Determine the content to display
            const contentToDisplay =
              report?.whatsappMessage?.templateContent || report?.message || "";
            // Truncate the content if it's longer than 30 characters
            const truncatedContent =
              contentToDisplay.length > 30
                ? `${contentToDisplay.slice(0, 30)}...`
                : contentToDisplay;

            // Generate a unique ID for the tooltip
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;

            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {contentToDisplay}
                </UncontrolledTooltip>

                <span id={tooltipId}>{truncatedContent}</span>
              </>
            );
          },
        },
        {
          text: "Status",
          dataField: "status",
          sort: true,
        },
        {
          text: "Cost",
          dataField: "cost",
          sort: true,
          formatter: (cellContent, report) => {
            if (this.state.campaignData?.channel === channelType.sms) {
              return (
                <span>{cellContent.toString().replace(/-/g, "") || "-"}</span>
              );
            } else {
              return <span>$0.02</span>;
            }
          },
        },
        {
          text: "Date",
          dataField: "date",
          sort: true,
          formatter: (cellContent, report) => (
            <span>
              {" "}
              {moment(report?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          ),
        },
      ],
    };
  }

  componentDidMount = () => {
    this.handleGetCampignReportById();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  handleGetCampignReportById = async page => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const { id } = this.props.match.params;
      const response = await axiosGet(
        `campaign/report/${id}?limit=${this.state.size}&page=${page}`
      );
      if (response?.status) {
        if (response?.data?.campaignData?.channel === channelType.whatsapp) {
          await this.handleGetWhatsappNumber(
            response?.data?.campaignData?.senderNumberId
          );
        }
        this.setState({
          campaignReport: response?.data?.campaignMessages?.messages,
          campaignData: response?.data?.campaignData,
          campaignStatistics: response?.data?.campaignStatistics,
          // page: response?.data?.campaignMessages?.page,
          totalSize: response?.data?.campaignMessages?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error("error at handleGetAllTemplates :", error);
      onSetLoader(false);
    }
  };

  handleGetWhatsappNumber = async whatsappNumberId => {
    try {
      const response = await axiosGet(`whatsapp-number/${whatsappNumberId}`);
      if (response?.status) {
        this.setState({ whatsappNumber: response?.data });
      }
    } catch (error) {
      console.error("error at handleGetWhatsappNumber:", error);
    }
  };

  handlePageClick = selectedPage => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetCampignReportById(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetCampignReportById(this.state.page);
    });
  };

  render() {
    //meta title
    document.title = "campaign-report";

    const { campaignReport, campaignData, campaignStatistics } = this.state;

    // Create a new URLSearchParams object from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    // Accessing the value of the 'id' parameter
    const reportPage = queryParams.get("page");

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Campaign" breadcrumbItem={campaignData?.name} />
            <Row>
              {reports.map((report, key) => (
                <Col md="3" key={`_col_${key}`}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{report.title}</p>
                          <h4 className="mb-0">
                            {campaignStatistics?.[report?.key] || 0}
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={`bx ${report.iconClass} font-size-24`}
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-end mb-3">
                      <Link
                        className="text-success"
                        to={`/report?page=${reportPage}`}
                      >
                        <Button className="btn btn-secondary">Back</Button>
                      </Link>
                    </div>
                    {/* <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.contactListColumns}
                      data={campaignReport || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.contactListColumns}
                          data={campaignReport || []}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="6">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  sm="6"
                                  className="d-flex justify-content-end"
                                >
                                  <div>
                                    <Link className="text-success" to="/report">
                                      <Button className="btn btn-secondary">
                                        Back
                                      </Button>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={emptyContacts}
                                      alt="empty-conatct"
                                      className={`${
                                        campaignReport?.length === 0
                                          ? ""
                                          : "display-none"
                                      }`}
                                    ></img>
                                  </div>
                                  <div
                                    className={`table-responsive ${
                                      campaignReport?.length === 0
                                        ? "display-none"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex pagination pagination-rounded justify-content-end mb-2">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider> */}
                    {campaignReport?.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={campaignReport}
                            columns={this.state.contactListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ViewReport.propTypes = {
  className: PropTypes.any,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({});

const mapDispatchToProps = dispatch => ({
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewReport));

// export default ViewReport;
