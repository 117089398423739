import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";

import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import { setShowContactFormModal } from "../../../store/contacts/actions";

import ContactForm from "./Modal/ContactForm";
import { toast } from "react-toastify";
import { axiosDel, axiosGet } from "helpers/api_helpers";
import emptyContacts from "../../../assets/images/emptyContact.png";
import { setLoader } from "../../../store/actions";

class ViewContacts extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      contacts: [],
      contact: "",
      isEdit: false,
      contactIdToDelete: "",
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      selectedNumberType: "local",
      contactListColumns: [
        {
          text: "Name",
          dataField: "name",
          sort: true,
          formatter: cellContent => {
            return <span>{cellContent || "-"}</span>;
          },
        },
        {
          text: "Country Code",
          dataField: "countryCode",
          sort: true,
          formatter: cellContent => {
            return <span>{cellContent || "-"}</span>;
          },
        },
        {
          text: "Mobile Number",
          dataField: "phoneNumber",
          sort: true,
          formatter: cellContent => {
            return <span>{cellContent || "-"}</span>;
          },
        },
        {
          text: "Address",
          dataField: "address",
          sort: true,
          formatter: (cellContent, contact, rowIndex) => {
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {cellContent}
                </UncontrolledTooltip>

                <span id={tooltipId}>
                  {cellContent?.slice(0, 30) || "-"}
                  {cellContent?.length > 30 ? "..." : ""}
                </span>
              </>
            );
          },
        },
        {
          text: "Zip Code",
          dataField: "zip",
          sort: true,
          formatter: cellContent => {
            return (
              <span>{cellContent !== " " ? cellContent || "-" : "-"}</span>
            );
          },
        },
        {
          text: "City",
          dataField: "city",
          sort: true,
          formatter: cellContent => {
            return (
              <span>{cellContent !== " " ? cellContent || "-" : "-"}</span>
            );
          },
        },
        {
          text: "State",
          dataField: "state",
          sort: true,
          formatter: cellContent => {
            return <span>{cellContent || "-"}</span>;
          },
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, contact) => (
            <div className="d-flex gap-3">
              <div className="text-success">
                <i
                  className="mdi mdi-clipboard-edit font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  id="edit"
                  onClick={() => this.handleUserClick(contact)}
                ></i>
              </div>
              <div className="text-danger">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                  onClick={() => this.onClickDelete(contact)}
                ></i>
              </div>
            </div>
          ),
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getAllContacts(this.state.page);
  };

  toggle = () => {
    const { onSetShowContactFormModal } = this.props;
    onSetShowContactFormModal();
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  handlePageClick = selectedPage => {
    this.setState({ page: selectedPage }, () => {
      this.getAllContacts(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.getAllContacts(this.state.page);
    });
  };
  handleUserClicks = () => {
    this.setState({ contact: "", isEdit: false });
    this.toggle();
  };

  handleUserClick = arg => {
    const contact = arg;

    this.setState({
      contact: {
        id: contact.id,
        countryCode: contact?.countryCode,
        phoneNumber: contact.phoneNumber,
        name: contact.name,
        zip: contact.zip,
        state: contact.state,
        city: contact.city,
        address: contact.address,
      },
      isEdit: true,
    });

    this.toggle();
  };

  onClickDelete = contact => {
    this.setState({ contactIdToDelete: contact?.id, deleteModal: true });
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  getAllContacts = async page => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const groupId = this.props.match.params.groupId;
      const groups = await axiosGet(
        `contact-groups/${groupId}?page=${page}&limit=${this.state.size}&sortBy=createdAt:desc`
      );
      if (groups?.status) {
        onSetLoader(false);
        this.setState({
          contacts: groups?.data?.contactGroup?.contactIds,
          totalSize: groups?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error while getting all contacts :", error);
    }
  };

  handleDeleteContact = async id => {
    try {
      const groupId = this.props.match.params.groupId;
      const response = await axiosDel(
        `contact-groups/delete-contact/${groupId}?contactId=${id}`
      );
      if (response?.status) {
        toast.success(response?.message || "Contact Deleted Successfully", {
          position: "top-right",
        });
        this.getAllContacts(this.state.page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error("error while deleting a contact :", error);
    }
  };

  render() {
    //meta title
    document.title = "Contact Groups";

    const { deleteModal, contacts, contact, contactIdToDelete } = this.state;

    const { showContactFormModal } = this.props;

    // Create a new URLSearchParams object from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    // Accessing the value of the 'id' parameter
    const contactGroupPage = queryParams.get("page");
    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteContact(contactIdToDelete)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {showContactFormModal && (
          <ContactForm
            isEdit={this.state.isEdit}
            contact={contact}
            getAllContacts={() => {
              this.getAllContacts(this.state.page);
            }}
          />
        )}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Contacts" breadcrumbItem="Contacts Groups" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end mb-3">
                      <Link
                        className="text-danger"
                        to={`/contact-groups?page=${contactGroupPage}`}
                      >
                        <Button
                          color="secondary"
                          className="font-16 btn-block btn btn-primary me-3"
                        >
                          Back
                        </Button>
                      </Link>
                      <div className="text-sm-end me-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Contact
                        </Button>
                      </div>
                      <div className="text-sm-end">
                        <Link to="/import-contacts">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                          >
                            <i className="mdi mdi-upload me-1" />
                            Upload Bulk Contacts
                          </Button>
                        </Link>
                      </div>
                    </Col>
                    {contacts?.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={contacts}
                            columns={this.state.contactListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ViewContacts.propTypes = {
  className: PropTypes.any,
  onSetShowContactFormModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowContactFormModal: () => dispatch(setShowContactFormModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ contacts }) => ({
  showContactFormModal: contacts.showContactFormModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewContacts));
