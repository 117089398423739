import { SHOW_ASSIGN_NUMBER_MODAL, SHOW_SENDER_GROUP_MODAL } from "./actionTypes"

export const setShowSenderGroupModal = () => {
  return {
    type: SHOW_SENDER_GROUP_MODAL,
  };
};

export const setShowAssignNumberModal = () => {
  return {
    type: SHOW_ASSIGN_NUMBER_MODAL,
  };
};

