import React, { Component } from "react";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  addUser = async userData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllUsers, toggle } = this.props;
      const response = await axiosPost("users", userData);
      if (response?.status) {
        toast.success(response?.message || "user added successfully!");
        getAllUsers();
        toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while adding user :", error);
    }
  };

  editUser = async userData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllUsers, toggle, user } = this.props;
      const response = await axiosPatch(`users/${user?.id}`, userData);
      if (response?.status) {
        toast.success(response?.message || "user updated successfully!");
        getAllUsers();
        toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while editing user :", error);
    }
  };

  handleOnSubmit = values => {
    try {
      const { isEdit, user } = this.props;
      const data = {
        email: values["email"],
        password: values["password"],
        name: values["name"],
        phoneNumber: `${values["phoneNumber"]}`,
        role: "user",
        gender: values["gender"],
        status: values["status"],
        uploadPermission: values["uploadPermission"],
      };
      if (!isEdit) {
        this.addUser(data);
      } else if (isEdit) {
        const editedFields = Object.keys(values).reduce((acc, key) => {
          if (values[key] !== user[key] && values[key]) {
            acc[key] = `${values[key]}`;
          }
          return acc;
        }, {});

        if (Object.keys(editedFields).length > 0) {
          const editedData = {
            ...editedFields,
          };
          this.editUser(editedData);
        } else {
          this.toggle();
        }
      }
    } catch (error) {
      console.error("error at handleOnSubmit :", error);
    }
  };

  render() {
    const { isEdit, showUserModal, toggle, user } = this.props;
    const { showModalLoader } = this.state;
    const initialValues = {
      name: (user && user.name) || "",
      gender: (user && user.gender) || "male",
      email: (user && user.email) || "",
      phoneNumber: (user && user.phoneNumber) || "",
      uploadPermission: (user && user.uploadPermission) || "false",
      status: (user && user.status) || "active",
      primaryNumber: (user && user.primaryNumber) || "",
      ...(!isEdit
        ? {
            password: "",
            confirmPassword: "",
          }
        : {}),
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Please enter user name"),
      gender: Yup.string().required("Please select user gender"),
      email: Yup.string().required("Please enter user's email"),
      phoneNumber: Yup.number().required("Please enter user's phone number"),

      status: Yup.string().required("Please enter user's status"),
      uploadPermission: Yup.string().required("Does user have permission?"),

      ...(!isEdit && {
        password: Yup.string().required("Enter user password"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm your password"),
      }),
    });

    return (
      <Modal isOpen={showUserModal} className={this.props.className}>
        {showModalLoader ? <ModalLoader /> : ""}
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit User Details" : "Add User"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Gender</Label>

                      <div className="">
                        <Field
                          name="gender"
                          type="radio"
                          value="male"
                          id="male"
                          className="form-check-input me-1"
                        />
                        <Label className="form-check-label me-5" htmlFor="male">
                          Male
                        </Label>

                        <Field
                          name="gender"
                          type="radio"
                          value="female"
                          id="female"
                          className="form-check-input me-1"
                        />
                        <Label
                          className="form-check-label me-5"
                          htmlFor="female"
                        >
                          Female
                        </Label>

                        <Field
                          name="gender"
                          type="radio"
                          value="others"
                          id="others"
                          className="form-check-input me-1"
                        />
                        <Label className="form-check-label" htmlFor="others">
                          Others
                        </Label>

                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Field
                        name="email"
                        type="email"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Phone Number</Label>

                      <Field
                        name="phoneNumber"
                        type="number"
                        className={
                          "form-control" +
                          (errors.phoneNumber && touched.phoneNumber
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {isEdit ? (
                      <div className="mb-3">
                        <Label className="form-label">Primary Number</Label>
                        <Field
                          as="select"
                          name="primaryNumber"
                          className={
                            "form-control-select" +
                            (errors.primaryNumber && touched.primaryNumber
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">Select a Primary Number</option>
                          <option value="1">number1</option>
                        </Field>
                        <ErrorMessage
                          name="primaryNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="mb-3">
                      <Label className="form-label">
                        Import Contacts Permission
                      </Label>
                      <Field
                        as="select"
                        name="uploadPermission"
                        className={
                          "form-control-select" +
                          (errors.uploadPermission && touched.uploadPermission
                            ? " is-invalid"
                            : "")
                        }
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </Field>
                      <ErrorMessage
                        name="uploadPermission"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Field
                        as="select"
                        name="status"
                        className={
                          "form-control-select" +
                          (errors.status && touched.status ? " is-invalid" : "")
                        }
                      >
                        <option value="active">Active</option>
                        <option value="deactivated">Inactive</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {!isEdit ? (
                      <>
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Field
                            name="password"
                            type="text"
                            className={
                              "form-control" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <Field
                            name="confirmPassword"
                            type="text"
                            className={
                              "form-control" +
                              (errors.confirmPassword && touched.confirmPassword
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

export default UserForm;
