import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  CardTitle,
  Form
} from "reactstrap";

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { axiosPatch } from "helpers/api_helpers";
import { UserContext } from "../../components/UserProvider/UserProvider";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "", idx: 1, user: {} };
  }
  static contextType = UserContext;

  componentDidMount = () => {
    const { getUserinfo } = this.context;
    getUserinfo()
  }

  editProfile = async editedUserData => {
    try {
      const { userData } = this.context;
      const response = await axiosPatch(
        `users/${userData?.id}`,
        editedUserData
      );
      if (response?.status) {
        toast.success(response?.message, {
          position: "top-right",
        });
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error while editing user :", error);
    }
  };

  render() {
    const { userData } = this.context;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Dashboard" breadcrumbItem="Profile" />

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Edit Profile
                    </CardTitle>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name: (userData && userData.name) || "",
                        gender:
                          (userData && userData.gender) || "male",
                        email: (userData && userData.email) || "",
                        phoneNumber:
                          (userData && userData.phoneNumber) || "",

                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().required(
                          "Please enter user name"
                        ),
                        gender: Yup.string().required(
                          "Please select user gender"
                        ),
                        email: Yup.string().required(
                          "Please enter user's email"
                        ),
                        phoneNumber: Yup.number().required(
                          "Please enter user's phone number"
                        ),
                      })}
                      onSubmit={values => {
                        const editedFields = Object.keys(
                          values
                        ).reduce((acc, key) => {
                          if (
                            values[key] !== userData[key] &&
                            values[key]
                          ) {
                            acc[key] = `${values[key]}`;
                          }
                          return acc;
                        }, {});

                        if (
                          Object.keys(editedFields)
                            .length > 0
                        ) {
                          const editedData = {
                            ...editedFields,
                          };
                          this.editProfile(editedData);
                        }
                      }}
                    >
                      {({ errors, status, touched, handleSubmit }) => (
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                        >
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">
                                  Name
                                </Label>
                                <Field
                                  name="name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.name &&
                                      touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Email
                                </Label>
                                <Field
                                  name="email"
                                  type="email"
                                  className={
                                    "form-control" +
                                    (errors.email &&
                                      touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Phone Number
                                </Label>

                                <Field
                                  name="phoneNumber"
                                  type="number"
                                  className={
                                    "form-control" +
                                    (errors.phoneNumber &&
                                      touched.phoneNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="phoneNumber"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Gender
                                </Label>

                                <div className="">
                                  <Field
                                    name="gender"
                                    type="radio"
                                    value="male"
                                    id="male"
                                    className="form-check-input me-1"
                                  />
                                  <Label
                                    className="form-check-label me-5"
                                    htmlFor="male"
                                  >
                                    Male
                                  </Label>

                                  <Field
                                    name="gender"
                                    type="radio"
                                    value="female"
                                    id="female"
                                    className="form-check-input me-1"
                                  />
                                  <Label
                                    className="form-check-label me-5"
                                    htmlFor="female"
                                  >
                                    Female
                                  </Label>

                                  <Field
                                    name="gender"
                                    type="radio"
                                    value="others"
                                    id="others"
                                    className="form-check-input me-1"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="others"
                                  >
                                    Others
                                  </Label>

                                  <ErrorMessage
                                    name="gender"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>

                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-start">
                                <button
                                  type="submit"
                                  className="btn btn-primary save-user"
                                >
                                  Submit
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(UserProfile)
);
