import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { LIMIT } from "constants/constants";
import { toast } from "react-toastify";
import { setShowAssignNumberModal } from "../../../store/sender-group/actions";
import ModalLoader from "components/Common/ModalLoader";

class AssignNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      twilioNumbers: [],
      selectedTwilioNumbers: [],
      showModalLoader: false,
    };
  }

  componentDidMount() {
    this.getSenderGroupById();
  }

  toggle = () => {
    const { onSetShowAssignNumberModal } = this.props;
    onSetShowAssignNumberModal();
  };

  handleMulti = selectedTwilioNumbers => {
    this.setState({ selectedTwilioNumbers });
  };

  getAllTwilioNumbers = async () => {
    try {
      const response = await axiosGet(`buy-number?page=1&limit=${LIMIT}`);
      if (response?.status) {
        const formattedOptions = response?.data?.results?.map(option => ({
          value: option.id,
          label: option.phoneNumber,
        }));
        this.setState({ twilioNumbers: formattedOptions });
      }
    } catch (error) {
      console.error("Error while getting twilio numbers:", error);
    }
  };

  getSenderGroupById = async () => {
    try {
      const { group } = this.props;
      const response = await axiosGet(
        `sender-groups/${group?.id}?page=1&limit=${LIMIT}`
      );
      if (response?.status) {
        const formattedOptions = response?.data?.numberIds?.map(option => ({
          value: option.id,
          label: option.phoneNumber,
        }));
        this.setState({ selectedTwilioNumbers: formattedOptions }, () =>
          this.getAllTwilioNumbers()
        );
      }
    } catch (error) {
      console.error("Error while getting sender group by id:", error);
    }
  };

  handleAssignNumbers = async () => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllSenderGroups, group } = this.props;
      const { selectedTwilioNumbers } = this.state;
      const numberIds = selectedTwilioNumbers?.map(option => option?.value);
      const response = await axiosPatch(`sender-groups/${group?.id}`, {
        numberIds,
      });
      if (response?.status) {
        toast.success(response?.message || "Number assigned successfully!", {
          position: "top-right",
        });
        getAllSenderGroups();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! Something went wrong", {
          position: "top-right",
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("Error while assigning number:", error);
    }
  };

  render() {
    const { showAssignNumberModal, group } = this.props;
    const { twilioNumbers, selectedTwilioNumbers, showModalLoader } =
      this.state;

    // Define initial values
    const initialValues = {
      groupName: group?.name || "",
    };

    // Define validation schema
    const validationSchema = Yup.object().shape({
      groupName: Yup.string().required("Please enter group name"),
    });

    // Define submit handler
    const onSubmit = () => {
      if (selectedTwilioNumbers.length > 0) {
        this.handleAssignNumbers();
      } else {
        toast.error("Select a number first!", {
          position: "top-right",
        });
      }
    };

    return (
      <Modal isOpen={showAssignNumberModal} className={this.props.className}>
        {showModalLoader ? <ModalLoader /> : ""}
        <ModalHeader toggle={this.toggle} tag="h4">
          Assign Number
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Group Name</Label>
                      <Field
                        name="groupName"
                        type="text"
                        disabled={true}
                        className={
                          "form-control" +
                          (errors.groupName && touched.groupName
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="groupName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Primary Number</Label>
                      <Select
                        value={selectedTwilioNumbers}
                        isMulti={true}
                        onChange={this.handleMulti}
                        options={twilioNumbers}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

AssignNumber.propTypes = {
  className: PropTypes.any,
  onSetShowAssignNumberModal: PropTypes.func,
  showAssignNumberModal: PropTypes.bool,
  group: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  onSetShowAssignNumberModal: () => dispatch(setShowAssignNumberModal()),
});

const mapStateToProps = ({ senderGroup }) => ({
  showAssignNumberModal: senderGroup.showAssignNumberModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssignNumber));
