import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosGet, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoader } from "../../../../store/actions";

class SingleWhatsappMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      senderNumbers: [],
    };
  }

  componentDidMount = () => {
    this.handleGetAllWhatsappNumbers();
  };

  handleGetAllWhatsappNumbers = async () => {
    try {
      const response = await axiosGet("whatsapp-number");
      if (response?.status) {
        this.setState({ senderNumbers: response?.data?.results });
      }
    } catch (error) {
      console.error("error at handleGetAllSenderGroups :", error);
    }
  };

  hnadleSendSingleWhatsappMessage = async (data, formik) => {
    const { onSetLoader } = this.props;
    try {
      const response = await axiosPost(
        `campaign/single-whatsapp-message`,
        data
      );
      if (response?.status) {
        toast.success(
          response?.message || "Whatsapp Campaign created successfully!"
        );
        formik.resetForm();
        onSetLoader(false);
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at hnadleSendSingleWhatsappMessage", error);
    }
  };

  hnadleOnSubmit = (values, formik) => {
    const data = {
      message: values.message,
      toPhoneNumber: `${values.recipentNumber}`,
      fromPhoneNumberId: values.senderNumber,
    };
    this.hnadleSendSingleWhatsappMessage(data, formik);
  };

  render() {
    //meta title
    document.title = "message";

    const { senderNumbers } = this.state;

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        recipentNumber: "",
                        senderNumber: "",
                        message: "",
                      }}
                      validationSchema={Yup.object().shape({
                        recipentNumber: Yup.number().required(
                          "Please enter recipent number"
                        ),
                        senderNumber: Yup.number().required(
                          "Please select a sender number"
                        ),
                        message: Yup.string().required(
                          "Please provide a message to send"
                        ),
                      })}
                      onSubmit={this.hnadleOnSubmit}
                    >
                      {({ errors, status, touched }) => (
                        <Form>
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">Number</Label>
                                <Field
                                  name="recipentNumber"
                                  type="number"
                                  placeholder="Enter Receiver number here..."
                                  className={
                                    "form-control" +
                                    (errors.recipentNumber &&
                                    touched.recipentNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="recipentNumber"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Sender Number
                                </Label>
                                <Field
                                  as="select"
                                  name="senderNumber"
                                  className={
                                    "form-control-select form-select-border-bottom" +
                                    (errors.senderNumber && touched.senderNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option value="">Select a number</option>
                                  {senderNumbers?.map((number, index) => (
                                    <option
                                      value={number.numberId}
                                      key={`${number.phoneNumber}-${index}`}
                                    >
                                      {number.name}({number.phoneNumber})
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="senderNumber"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Text Box</Label>
                                <Field
                                  as="textarea"
                                  name="message"
                                  className={
                                    "form-control-select" +
                                    (errors.message && touched.message
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  {/* <option value="">Select a number</option>
                                  <option value="1">number1</option> */}
                                </Field>
                                <ErrorMessage
                                  name="message"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Send
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// export default SingleWhatsappMessage;

SingleWhatsappMessage.propTypes = {
  onSetLoader: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SingleWhatsappMessage));
