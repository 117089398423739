/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

/* IMPORT CONTACTS */
export const SET_SELECTED_CSV_FILE = 'SET_SELECTED_CSV_FILE'
export const SET_IMPORTED_CONTACTS_DATA = 'SET_IMPORTED_CONTACTS_DATA'
export const SET_IMPORTED_CONTACTS_FIELDS = 'SET_IMPORTED_CONTACTS_FIELDS'
export const SET_MAPPED_CONTACT_FIELDS = 'SET_MAPPED_CONTACT_FIELDS'

/* CONTACT GROUPS */
export const SET_SHOW_CONTACT_FORM_MODAL = 'SET_SHOW_CONTACT_FORM_MODAL'
export const SET_SHOW_GROUP_FORM_MODAL = 'SET_SHOW_GROUP_FORM_MODAL'