import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowAddNoteModal } from "../../../store/inbox/actions";
import { axiosGet } from "helpers/api_helpers";

class QuickMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickMessages: [],
    };
  }

  componentDidMount = () => {
    this.handleGetAllQuickMessages();
  };

  handleQuickMessageSelect = message => {
    const { handleSelectMessage, onToggle } = this.props;
    handleSelectMessage(message);
    onToggle();
  };

  handleGetAllQuickMessages = async () => {
    try {
      const response = await axiosGet(`quick-message`);
      if (response?.status) {
        this.setState({ quickMessages: response?.data?.results });
      }
    } catch (error) {
      console.error("error at handleGetAllQuickMessages :", error);
    }
  };

  render() {
    const { showQuickMessageModal, onToggle } = this.props;
    const { quickMessages } = this.state;
    return (
      <Modal isOpen={showQuickMessageModal} className={this.props.className}>
        <ModalHeader toggle={onToggle} tag="h4">
          Quick Message
        </ModalHeader>
        <ModalBody className="inbox-list-modal">
          <ul className="list-unstyled">
            {quickMessages.map((quickMessage, index) => (
              <li
                key={`${index}-${quickMessage?.name}`}
                className="inbox-modal-list cursor-pointer"
                onClick={() =>
                  this.handleQuickMessageSelect(quickMessage?.content)
                }
              >
                <span>{quickMessage?.name}</span>
              </li>
            ))}
          </ul>
        </ModalBody>
      </Modal>
    );
  }
}

export default QuickMessageList;
