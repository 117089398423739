import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowContactFormModal } from "../../../../store/contacts/actions";
import { countryCodeOptions } from "constants/constants";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import Select from "react-select";
import ModalLoader from "components/Common/ModalLoader";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountryCode: "",
      showModalLoader: false,
    };
  }

  componentDidMount = () => {
    const { contact } = this.props;
    const selectedCountryCode = countryCodeOptions.filter(countryCode => {
      return countryCode?.value === contact?.countryCode;
    });
    this.setState({ selectedCountryCode: selectedCountryCode[0] });
  };

  toggle = () => {
    const { onSetShowContactFormModal } = this.props;
    onSetShowContactFormModal();
  };

  handleCountryCode = (selectedCountryCode, setFieldValue) => {
    this.setState({ selectedCountryCode });
    setFieldValue("countryCode", selectedCountryCode?.value);
  };

  createNewContact = async contactInfo => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllContacts } = this.props;
      const groupId = this.props.match.params.groupId;
      const response = await axiosPost(
        `contact-groups/add-contacts/${groupId}`,
        {
          contacts: contactInfo,
        }
      );
      if (response?.status) {
        toast.success(response?.message || "Contact created successfully", {
          position: "top-right",
        });
        getAllContacts();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while creating a contact :", error);
    }
  };

  handleEditContact = async (contactId, updatedData) => {
    try {
      this.setState({ showModalLoader: true });
      const { editContactInbox, getAllContacts } = this.props;
      const response = await axiosPatch(`contact/${contactId}`, updatedData);
      if (response?.status) {
        toast.success(response?.message || "Contact updated successfully!", {
          position: "top-right",
        });
        if (!editContactInbox) {
          getAllContacts();
        }
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while updating a contact :", error);
    }
  };

  onSubmit = values => {
    const { isEdit, contact } = this.props;
    const contactInfo = {
      countryCode: values?.countryCode,
      phoneNumber: `${values?.phoneNumber}`,
      name: values?.name,
      address: values?.address,
      zip: `${values?.zip}`,
      state: values?.state,
      city: values?.city,
    };
    if (!isEdit) {
      this.createNewContact([contactInfo]);
    } else {
      // Update existing contact

      // Assuming existingContactInfo is the object representing the current contact's details
      const updatedFields = {};

      // Compare each field and update only if it's different
      for (const key in contactInfo) {
        if (
          contactInfo.hasOwnProperty(key) &&
          contactInfo[key] !== contact[key]
        ) {
          updatedFields[key] = contactInfo[key];
        }
      }

      if (Object.keys(updatedFields).length > 0) {
        this.handleEditContact(contact?.id, updatedFields);
        // this.toggle(); // Toggle modal or perform any other action
      } else {
        this.toggle();
      }
    }
  };

  render() {
    const { isEdit, showContactFormModal, contact, editContactInbox } =
      this.props;

    const { selectedCountryCode, showModalLoader } = this.state;

    const initialValues = {
      countryCode: contact?.countryCode || "",
      phoneNumber: contact?.phoneNumber?.replace(/[^\d]/g, "") || "",
      name: contact?.name || "",
      address: contact?.address || "",
      zip: contact?.zip || "",
      state: contact?.state || "",
      city: contact?.city || "",
    };

    const validationSchema = Yup.object().shape({
      countryCode: Yup.string().required("Please select county code"),
      phoneNumber: Yup.number().required("Please enter the contact number"),
      name: Yup.string().required("Please enter the name "),
      address: Yup.string().required("Please enter the address"),
      zip: Yup.number().required("Please enter zipcode"),
      state: Yup.string().required("Please enter the state"),
      city: Yup.string().required("Please enter the city"),
    });

    return (
      <Modal isOpen={showContactFormModal} className={this.props.className}>
        {showModalLoader ? <ModalLoader /> : ""}
        <ModalHeader toggle={this.toggle} tag="h4">
          {!!isEdit ? "Edit Contact" : "Add Contact"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
          >
            {({ values, errors, status, touched, setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    {!editContactInbox && (
                      <div className="mb-3">
                        <Label className="form-label">Contact Number</Label>
                        <div className="d-flex me-2">
                          <div className="mb-3 w-50 county-code-select">
                            <Select
                              value={selectedCountryCode}
                              onChange={selectedValue => {
                                this.handleCountryCode(
                                  selectedValue,
                                  setFieldValue
                                );
                              }}
                              options={countryCodeOptions}
                              classNamePrefix="select2-selection"
                              className={
                                "form-control-select form-select-border-bottom select-border-bottom-none p-0" +
                                (errors.countryCode && touched.countryCode
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="countryCode"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div>
                            <Field
                              name="phoneNumber"
                              type="number"
                              className={
                                "form-control ms-3" +
                                (errors.phoneNumber && touched.phoneNumber
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              component="div"
                              className="invalid-feedback ms-3"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Address</Label>
                      <Field
                        name="address"
                        type="text"
                        className={
                          "form-control" +
                          (errors.address && touched.address
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Zip Code</Label>
                      <Field
                        name="zip"
                        type="number"
                        className={
                          "form-control" +
                          (errors.zip && touched.zip ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="zip"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">State</Label>
                      <Field
                        name="state"
                        type="text"
                        className={
                          "form-control" +
                          (errors.state && touched.state ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">City</Label>
                      <Field
                        name="city"
                        type="text"
                        className={
                          "form-control" +
                          (errors.city && touched.city ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

ContactForm.propTypes = {
  className: PropTypes.any,
  onSetShowContactFormModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowContactFormModal: () => dispatch(setShowContactFormModal()),
});

const mapStateToProps = ({ contacts }) => ({
  showContactFormModal: contacts.showContactFormModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactForm));
