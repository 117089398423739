import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Limit from "pages/pagination/Limit";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { roles, PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";
import { axiosGet } from "helpers/api_helpers";
import emptyContacts from "../../assets/images/emptyContact.png";
import { UserContext } from "../../components/UserProvider/UserProvider";
import moment from "moment";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoader } from "../../store/actions";
import Pagination from "pages/pagination/Pagination";
import { FormControl } from "react-bootstrap";

class Notes extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      notes: [],
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      note: "",
      deleteModal: false,
      selectedNumberType: "local",
      searchQuery: "",
      noteListColumns: [
        {
          text: "Phone Number",
          dataField: "customerPhoneNumber",
          sort: true,
        },
        {
          text: "Message",
          dataField: "message",
          sort: true,
          formatter: (cellContent, note, rowIndex) => {
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {cellContent}
                </UncontrolledTooltip>

                <span id={tooltipId}>
                  {cellContent?.slice(0, 30)}
                  {cellContent?.length > 30 ? "..." : ""}
                </span>
              </>
            );
          },
        },
        {
          text: "CreatedAt",
          dataField: "createdAt",
          sort: true,
          formatter: (cellContent, note) => (
            <span>{moment(note?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount() {
    this.handleGetNotes(this.state.page);
    const { userData } = this.context;

    if (userData?.role === roles.admin) {
      this.setState({
        noteListColumns: [
          {
            text: "User",
            dataField: "userName",
            sort: true,
            formatter: cellContent => {
              return <span>{cellContent || "-"}</span>;
            },
          },
          ...this.state.noteListColumns,
        ],
      });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetNotes(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetNotes(this.state.page);
    });
  };

  /* Insert,Update Delete data */

  handleGetNotes = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `note?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&sortBy=createdAt:desc`
      );
      if (response?.status) {
        onSetLoader(false);
        this.setState({ notes: response?.data?.results });
        this.setState({ totalSize: response?.data?.totalResults });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      toast.error("Error at getting all notes");
      onSetLoader(false);
      console.error("error at handleGetNotes :", error);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetNotes(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "notes";

    const { notes } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Notes" breadcrumbItem="Note List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                    </Col>
                    {notes.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={notes}
                            columns={this.state.noteListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Notes.propTypes = {
  onSetLoader: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(null, mapDispatchToProps)(withRouter(Notes));

// export default Notes;
