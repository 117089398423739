import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {} from "../../store/actions";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

// import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";
import logo from "../../assets/images/logo-sm-light.png";
import zappycomLogo from "../../assets/images/zappycom.png";
import { axiosGet } from "helpers/api_helpers";
import { addNotification } from "store/notifications/actions";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={zappycomLogo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={zappycomLogo} alt="" height="17" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={zappycomLogo} alt="" height="35" />
              </span>
              <span className="logo-lg d-flex justify-content-center align-items-center mt-4">
                <img
                  src={zappycomLogo}
                  alt=""
                  height="35"
                  className="me-2"
                  // className="me-2 rounded-circle"
                />
                <h2 className="text-light mt-2">Zappycom</h2>
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            {this.props.type !== "condensed" ? (
              <SidebarContent />
            ) : (
              <SidebarContent />
            )}
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    );
  }
}

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
