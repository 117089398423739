import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// import images
import logo from "../../assets/images/zappycom.png";
import { axiosPost } from "helpers/api_helpers";
import { SOMETHING_WENT_WRONG } from "constants/constants";

export default class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifingEmail: true,
      error: "",
      tokenNotFound: false,
    };
  }

  componentDidMount() {
    if (this.props?.match?.params?.token) {
      this.verifyEmail(this.props?.match?.params?.token);
    } else {
      this.setState({ tokenNotFound: true });
    }
  }

  verifyEmail = async token => {
    try {
      const response = await axiosPost(`/auth/verify-email?token=${token}`);
      if (response?.status) {
        // this.props.history.push("/login");
        this.setState({
          verifingEmail: false,
        });
      } else {
        console.error("verifyEmail API error: ", response?.message);
        this.setState({ error: response?.message || SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      console.error("verifyEmail error: ", error);
      this.setState({ error: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  render() {
    //meta title
    document.title = "Email Verification | Cloudmate - Template";

    const { tokenNotFound, error, verifingEmail } = this.state;

    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div
                  className="text-center mb-5 text-muted"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Link to="dashboard" className="d-block auth-logo">
                    <img
                      src={logo}
                      alt=""
                      height="30"
                      className="auth-logo-dark mx-auto"
                    />
                    <img
                      src={logo}
                      alt=""
                      height="30"
                      className="auth-logo-light mx-auto"
                    />
                  </Link>
                  <p className="m-0" style={{ fontSize: "1rem" }}>
                    zappycom
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody>
                    <div className="p-2">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          {tokenNotFound ? (
                            <>
                              <h4 className="text-danger">
                                Oops! Token not found
                              </h4>
                              <div className="mt-4">
                                <a
                                  href="/forgot-password"
                                  className="btn btn-success w-md"
                                >
                                  Try Again
                                </a>
                              </div>
                            </>
                          ) : (
                            <>
                              {error ? (
                                <>
                                  <h4 className="text-danger">{error}</h4>
                                  <div className="mt-4">
                                    <a
                                      href="/forgot-password"
                                      className="btn btn-success w-md"
                                    >
                                      Try Again
                                    </a>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {verifingEmail ? (
                                    <h5>Verifing your email...</h5>
                                  ) : (
                                    <h4 className="text-success">
                                      Your email has been verified
                                    </h4>
                                  )}
                                  <div className="mt-4">
                                    <a
                                      href="/login"
                                      className="btn btn-success w-md"
                                    >
                                      Login
                                    </a>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} zappycom
                    {/* <i className="mdi mdi-heart text-danger"></i> by Themesbrand */}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
