import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Select from "react-select";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";
import emptyContacts from "../../../src/assets/images/emptyContact.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { userRoles } from "constants/user";
import {
  LIMIT,
  SIZE_PER_PAGE,
  SOMETHING_WENT_WRONG,
  defaultSelect,
} from "constants/constants";
import { showToast, userForgetPasswordError } from "store/actions";
import EditModal from "./EditModal";
import { getDataFromLocalStorage } from "helpers/common_helpers";

class CustomerTableNew extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      authUser: getDataFromLocalStorage("authUser"),
      users: [],
      user: "",
      showModal: false,
      editableUser: {},
      userList: [], // Initialize userList state
      deleteModal: false,
      blockModal: false,
      columns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{user.id}</>,
        },
        {
          dataField: "destination",
          text: "Profile",
          formatter: (cellContent, user) => (
            <>
              {!user?.destination ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {user?.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={user?.destination}
                    alt=""
                  />
                </div>
              )}
            </>
          ),
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {/* <Link to={`/customers/${user?.id}`} className="text-dark"> */}
              <Link to={`/customers`} className="text-dark">
                <h5 className="font-size-14 mb-1">{user?.name}</h5>
                <p className="text-muted mb-0">{user?.designation}</p>
              </Link>
            </>
          ),
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {/* <Link to={`/customers/${user?.id}`} className="text-dark"> */}
              <Link to={`/customers`} className="text-dark">
                <p className="text-muted mb-0">{user?.email}</p>
              </Link>
            </>
          ),
        },
        {
          dataField: "phoneNumber",
          text: "Phone Number",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {/* <Link to={`/customers/${user?.id}`} className="text-dark"> */}
              <Link to={`/customers`} className="text-dark">
                <p className="text-muted mb-0">{user?.phoneNumber}</p>
              </Link>
            </>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.onClickEdit(user)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.onClickDelete(user)}
                ></i>
              </Link>
              {user?.isBlocked ? (
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-account-cancel font-size-18"
                    id="blocktooltip"
                    onClick={() => this.onClickBlock(user)}
                  ></i>
                </Link>
              ) : (
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-account font-size-18"
                    id="blocktooltip"
                    onClick={() => this.onClickBlock(user)}
                  ></i>
                </Link>
              )}
            </div>
          ),
        },
      ],
    };
    this.onClickDelete = this.onClickDelete.bind(this);
    this.openCreateUserModal = this.openCreateUserModal.bind(this);
  }

  componentDidMount() {
    this.getCustomers();
  }

  async getCustomers() {
    try {
      const response = await axiosGet(
        `/users?page=1&limit=${LIMIT}&role=${userRoles.USER}`
      );
      if (response?.status) {
        this.setState({ userList: response?.data?.results }); // Set the userList in the state
      } else {
        console.error("getCustomers API error: ", response?.message);
        this.props.showToast({
          type: "error",
          message: response?.message || SOMETHING_WENT_WRONG,
          title: "User",
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("getCustomers error: ", error);
      this.props.showToast({
        type: "error",
        message: error?.message || SOMETHING_WENT_WRONG,
        title: "User",
        duration: 3000,
      });
    }
  }

  onClickDelete(user) {
    this.setState({ user: user });
    this.setState({ deleteModal: true });
  }

  onClickEdit(user) {
    this.setState({ editableUser: user, showModal: true });
  }

  onClickBlock(user) {
    this.setState({ user: user });
    this.setState({ blockModal: true });
  }

  async handleDeleteUser(id) {
    try {
      const response = await axiosDel(
        `/users/${id}?deletedBy=${this.state.authUser?.id}`
      );
      if (response?.status) {
        this.props.showToast({
          type: "success",
          message: response?.message || "Delete user successfully",
          title: "Delete User",
          duration: 3000,
        });
        //  if (this.state.selectedDietitian.value !== defaultSelect.value) {
        //    this.handleFilter(this.state.selectedDietitian.value);
        //  } else {
        this.getCustomers();
        //  }
      } else {
        console.error("handleDeleteUser API error : ", response?.message);
        this.props.showToast({
          type: "error",
          message: response?.message || SOMETHING_WENT_WRONG,
          title: "Delete User",
          duration: 3000,
        });
      }
      this.setState({ deleteModal: false });
    } catch (error) {
      console.error("handleDeleteUser error : ", error?.message);
      this.props.showToast({
        type: "error",
        message: error?.message || SOMETHING_WENT_WRONG,
        title: "Delete User",
        duration: 3000,
      });
      this.setState({ deleteModal: false });
    }
  }

  async handleBlockUser(id, isBlocked = true) {
    try {
      const response = await axiosPatch(`/users/${id}`, {
        isBlocked,
        updatedBy: this.state.authUser?.id,
      });
      if (response?.status) {
        this.props.showToast({
          type: "success",
          message: isBlocked
            ? "Block user successfully"
            : "Unblock user successfully",
          title: isBlocked ? "Block User" : "Unblock User",
          duration: 3000,
        });
        //  if (this.state.selectedDietitian.value !== defaultSelect.value) {
        //    this.handleFilter(this.state.selectedDietitian.value);
        //  } else {
        this.getCustomers();
        //  }
      } else {
        console.error("handleBlockUser API error : ", response?.message);
        this.props.showToast({
          type: "error",
          message: response?.message || SOMETHING_WENT_WRONG,
          title: isBlocked ? "Block User" : "Unblock User",
          duration: 3000,
        });
      }
      this.setState({ blockModal: false });
    } catch (error) {
      console.error("handleBlockUser error : ", error?.message);
      this.props.showToast({
        type: "error",
        message: error?.message || SOMETHING_WENT_WRONG,
        title: isBlocked ? "Block User" : "Unblock User",
        duration: 3000,
      });
      this.setState({ blockModal: false });
    }
  }

  openCreateUserModal() {
    this.setState({ showModal: true, editableUser: {} });
  }

  render() {
    //meta title
    document.title = "User List | Cloudmate Template";

    // const { users } = this.state
    const { SearchBar } = Search;

    const {
      deleteModal,
      blockModal,
      userList,
      columns,
      showModal,
      editableUser,
      user,
    } = this.state;

    const pageOptions = {
      sizePerPage: SIZE_PER_PAGE,
      totalSize: userList.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteUser(user?.id)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <DeleteModal
          show={blockModal}
          onDeleteClick={() => this.handleBlockUser(user?.id, !user?.isBlocked)}
          onCloseClick={() => this.setState({ blockModal: false })}
          buttonText={user?.isBlocked ? "Yes, Unblock it" : "Yes, Block it"}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Cloudmate Tech." breadcrumbItem="Customers" />
            <div className="ms-1">
              <div className="text-sm-end">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary mb-3"
                  onClick={this.openCreateUserModal}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New Customer
                </Button>
              </div>
            </div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={userList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={userList}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <EditModal
          modal={showModal}
          user={editableUser}
          isEdit={Object.keys(editableUser).length ? true : false}
          toggle={() => {
            this.setState({ showModal: false });
            this.getCustomers();
          }}
        />
      </React.Fragment>
    );
  }
}

CustomerTableNew.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

export default connect(mapStateToProps, {
  showToast,
})(CustomerTableNew);
