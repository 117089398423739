import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowAddNoteModal } from "../../../store/inbox/actions";
import { axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { conversationType } from "constants/constants";
import ModalLoader from "components/Common/ModalLoader";

class AddNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalSpinner: false,
    };
  }

  toggle = () => {
    const { onSetShowAddNoteModal } = this.props;
    onSetShowAddNoteModal();
  };

  handleCreateNote = async noteData => {
    try {
      this.setState({ showModalSpinner: true });
      const response = await axiosPost("note", noteData);
      if (response?.status) {
        this.setState({ showModalSpinner: false });
        toast.success(response?.message || "Note created successfully!");
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.setState({ showModalSpinner: false });
      }
    } catch (error) {
      this.setState({ showModalSpinner: false });
      console.error("error at handleCreateNote :", error);
    }
  };

  handleOnSubmit = values => {
    const { conversation } = this.props;
    const noteData = {
      userName: (conversation?.name || "-"),
      userPhoneNumber: conversation?.from,
      customerPhoneNumber: conversation?.to,
      message: values["note"],
      type: conversationType.SMS,
    };
    this.handleCreateNote(noteData);
  };

  render() {
    const { showAddListModal } = this.props;
    const { showModalSpinner } = this.state;

    return (
      <Modal isOpen={showAddListModal} className={this.props.className}>
        {showModalSpinner ? <ModalLoader /> : ""}
        <ModalHeader toggle={this.toggle} tag="h4">
          Add Note
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              note: "",
            }}
            validationSchema={Yup.object().shape({
              note: Yup.string().required("Please enter your note"),
            })}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Note</Label>
                      <Field
                        name="note"
                        as="textarea"
                        className={
                          "form-control" +
                          (errors.note && touched.note ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="note"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

AddNote.propTypes = {
  className: PropTypes.any,
  onSetShowAddNoteModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowAddNoteModal: () => dispatch(setShowAddNoteModal()),
});

const mapStateToProps = ({ inbox }) => ({
  showAddListModal: inbox.showAddListModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddNote));
