import React, { Component } from "react";

class ModalLoader extends Component {
  render() {
    return (
      <div className="loader-div d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
}

export default ModalLoader;
