import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import {
  setShowNoteListModal,
  setShowAddNoteModal,
} from "../../../store/inbox/actions";
import AddNote from "./AddNote";
import { axiosGet } from "helpers/api_helpers";
import { LIMIT } from "constants/constants";
import moment from "moment";
import emptyContacts from "../../../assets/images/emptyContact.png";

class NoteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: [],
    };
  }

  componentDidMount = () => {
    this.handleGetNotes();
  };

  toggle = () => {
    const { onSetShowNoteListModal } = this.props;
    onSetShowNoteListModal();
  };

  toggleAddNoteModal = () => {
    const { onSetShowAddNoteModal, showNoteListModal, onSetShowNoteListModal } =
      this.props;
    onSetShowAddNoteModal();
    if (showNoteListModal) {
      onSetShowNoteListModal();
    }
  };

  handleGetNotes = async () => {
    try {
      const { conversation } = this.props;
      const response = await axiosGet(
        `note?userPhoneNumber=${conversation?.from}&customerPhoneNumber=${conversation?.to}&page=1&limit=${LIMIT}`
      );
      if (response?.status) {
        this.setState({ notes: response?.data?.results });
      }
    } catch (error) {
      console.error("error at handleGetNotes :", error);
    }
  };

  render() {
    const { showNoteListModal, conversation } = this.props;
    const { notes } = this.state;
    return (
      <Modal isOpen={showNoteListModal} className={this.props.className}>
        {/* <AddNote conversation={conversation} /> */}
        <ModalHeader toggle={this.toggle} tag="h4">
          Note List
        </ModalHeader>
        <ModalBody className="inbox-list-modal">
          <ul className="list-unstyled">
            {notes?.length > 0 ? (
              notes?.map((note, index) => (
                <li key={`${index}-${note?.id}`} className="inbox-modal-list">
                  <div className="d-flex flex-column">
                    <div className="me-3">{note?.message}</div>
                    <div className="text-muted">
                      <small>
                        {moment(note?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                      </small>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div>
                <img src={emptyContacts} width="100%"></img>
              </div>
            )}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-primary" onClick={this.toggleAddNoteModal}>
            Add Note
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

NoteList.propTypes = {
  className: PropTypes.any,
  onSetShowNoteListModal: PropTypes.func,
  onSetShowAddNoteModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowNoteListModal: () => dispatch(setShowNoteListModal()),
  onSetShowAddNoteModal: () => dispatch(setShowAddNoteModal()),
});

const mapStateToProps = ({ inbox }) => ({
  showNoteListModal: inbox.showNoteListModal,
  showAddListModal: inbox.showAddListModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NoteList));
