import { ADD_NOTIFICATION, LATEST_NOTIFICATION, SELECTED_NOTIFICATION, UPDATE_NOTIFICATION } from './actionTypes';

const initialState = {
  notifications: [],
  latestNotification: {},
  selectedNotification: ""
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: [...action.payload],
      };
    case LATEST_NOTIFICATION:
      return {
        ...state,
        latestNotification: action.payload,
      };
    case SELECTED_NOTIFICATION:
      return {
        ...state,
        selectedNotification: action.payload,
      };
    default:
      return state;
  }
};

export default notification;
