import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Spinner,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowWhatsappNumberModal } from "../../../store/phone-number/actions";
import { LIMIT } from "constants/constants";
import { axiosGet, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";

const initialValues = {
  wabaId: "",
  watoken: "",
};

const validationSchema = Yup.object().shape({
  wabaId: Yup.string().required(
    "Please enter your WhatsApp Business Account ID"
  ),
  watoken: Yup.string().required("Please enter Your WhatsApp Access Token"),
});

const initialValues2 = {
  selectedNumber: "",
  user: "",
};

const validationSchema2 = Yup.object().shape({
  selectedNumber: Yup.string().required("Please select the phone number"),
  user: Yup.string().required("Please select a user"),
});
class ManageWhatsappNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wabaId: "",
      watoken: "",
      availableNumbers: [],
      users: [],
      showSpinner: false,
    };
  }

  componentDidMount = () => {
    this.getAllUsers();
  };

  toggle = () => {
    const { onSetShowWhatsappNumberModal } = this.props;
    onSetShowWhatsappNumberModal();
    this.setState({ wabaId: "", availableNumbers: [] });
  };

  // To get all user list
  getAllUsers = async () => {
    try {
      const users = await axiosGet(`users?page=1&limit=${LIMIT}`);
      if (users?.status) {
        this.setState({ users: users?.data?.results });
      }
    } catch (error) {
      console.error("error while getting all users :", error);
    }
  };

  // To get available numbers from twilio using area code and number type
  handleGetAvailableNumbers = async (wabaId, watoken) => {
    try {
      this.setState({ wabaId, watoken });
      this.setState({ showSpinner: true });
      const numbers = await axiosGet(
        `whatsapp-number/available-numbers?whatsappBusinessAccountId=${wabaId}&whatsappAccessToken=${watoken}`
      );
      if (numbers?.status) {
        if (numbers?.data.length > 0) {
          this.setState({
            availableNumbers: numbers?.data,
          });
          this.getAllUsers();
          this.setState({ showSpinner: false });
        } else {
          toast.error(numbers?.message || "No Numbers Available", {
            position: "top-right",
          });
          this.setState({ showSpinner: false });
        }
      } else {
        toast.error(numbers?.message || "Oops! something went wrong", {
          position: "top-right",
        });
        this.setState({ showSpinner: false });
      }
    } catch (error) {
      this.setState({ showSpinner: false });
      console.error("error while getting available twilio numbers :", error);
    }
  };

  // To buy a twilio number
  handleWhatsappNumberAddition = async values => {
    try {
      const { selectedNumber } = values;
      const [phoneNumber, numberId, name] = selectedNumber.split("|");
      const { getAllNumbers } = this.props;
      const data = {
        assignedUserId: values?.user,
        whatsappBusinessAccountId: this.state.wabaId,
        phoneNumber: phoneNumber.replace(/[\(\)\-\s]/g, ""),
        numberId,
        name,
        whatsappAccessToken: this.state.watoken,
      };
      const response = await axiosPost(`whatsapp-number`, data);
      if (response?.status) {
        toast.success(
          response?.message || "Whatsapp Number added successfull!",
          {
            position: "top-right",
          }
        );
        getAllNumbers();
        this.setState({ availableNumbers: [], wabaId: "" });
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! Something went wrong!", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("error at handleWhatsappNumberAddition :", error);
    }
  };

  // Formik onSubmit handler
  handleOnSubmit = values => {
    this.handleGetAvailableNumbers(values["wabaId"], values["watoken"]);
  };

  handleSaveBtnOnClick = values => {
    this.handleWhatsappNumberAddition(values);
  };

  render() {
    const { showWhatsappNumberModal } = this.props;
    const { availableNumbers, users, showSpinner } = this.state;
    return (
      <Modal isOpen={showWhatsappNumberModal} className={this.props.className}>
        {showSpinner ? <ModalLoader /> : ""}

        <ModalHeader toggle={this.toggle} tag="h4">
          Add Whatsapp Number
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        WhatsApp Business Account ID
                      </Label>
                      <Field
                        name="wabaId"
                        type="text"
                        className={
                          "form-control" +
                          (errors.wabaId && touched.wabaId ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="wabaId"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        WhatsApp Access Token
                      </Label>
                      <Field
                        name="watoken"
                        type="text"
                        className={
                          "form-control" +
                          (errors.watoken && touched.watoken
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="watoken"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user d-flex justify-content-center"
                      >
                        Get Numbers
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {availableNumbers.length > 0 ? (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues2}
              validationSchema={validationSchema2}
              onSubmit={this.handleWhatsappNumberAddition}
            >
              {({ errors, status, touched }) => (
                <Form>
                  <Row>
                    <div className="mb-3">
                      <Label className="form-label">User</Label>
                      <Field
                        as="select"
                        name="user"
                        className={
                          "form-control-select" +
                          (errors.user && touched.user ? " is-invalid" : "")
                        }
                      >
                        <option value="">Select</option>
                        {users?.map((user, index) => (
                          <option
                            value={user?.id}
                            key={`${index}-${user?.name}`}
                          >
                            {user?.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="user"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="form-label px-0">
                        Associated Phone Number
                      </Label>
                    </Col>
                  </Row>
                  <Row className="px-4">
                    {availableNumbers.map((phoneNumber, index) => (
                      <>
                        <Col
                          sm={6}
                          key={`${phoneNumber?.display_phone_number}-${index}`}
                          className=""
                        >
                          <Label className="d-flex align-items-center">
                            <Field
                              type="radio"
                              name="selectedNumber"
                              value={`${phoneNumber?.display_phone_number} | ${phoneNumber?.id} | ${phoneNumber?.verified_name}`}
                              // className="me-2"
                              className={
                                "me-2" +
                                (errors.user && touched.user
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {phoneNumber.display_phone_number}
                          </Label>
                        </Col>
                      </>
                    ))}
                  </Row>
                  <ErrorMessage
                    name="selectedNumber"
                    component="div"
                    className="error"
                  />
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSetShowWhatsappNumberModal: () => dispatch(setShowWhatsappNumberModal()),
});

const mapStateToProps = ({ phoneNumber }) => ({
  showWhatsappNumberModal: phoneNumber.showWhatsappNumberModal,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManageWhatsappNumber));
