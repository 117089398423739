export const getDataFromSessionStorage = (key, defaultValue = undefined) => {
    return sessionStorage.getItem(key)
        ? JSON.parse(sessionStorage.getItem(key))
        : defaultValue;
};

export const getDataFromLocalStorage = (key, defaultValue = undefined) => {
    return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : defaultValue;
};

export const setDataInSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const setDataInLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    );

    return JSON.parse(jsonPayload);
};





