import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  SET_IMPORTED_CONTACTS_DATA,
  SET_IMPORTED_CONTACTS_FIELDS,
  SET_MAPPED_CONTACT_FIELDS,
  SET_SELECTED_CSV_FILE,
  SET_SHOW_CONTACT_FORM_MODAL,
  SET_SHOW_GROUP_FORM_MODAL,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

export const setImportedContactsData = data => ({
  type: SET_IMPORTED_CONTACTS_DATA,
  payload: data,
})

export const setImportedContactsFields = data => ({
  type: SET_IMPORTED_CONTACTS_FIELDS,
  payload: data,
})

export const setMappedContactFields = data => ({
  type: SET_MAPPED_CONTACT_FIELDS,
  payload: data,
})

export const setSelectedCsvFile = data => ({
  type: SET_SELECTED_CSV_FILE,
  payload: data,
})

export const setShowContactFormModal = data => ({
  type: SET_SHOW_CONTACT_FORM_MODAL,
  payload: data,
})

export const setShowGroupFormModal = data => ({
  type: SET_SHOW_GROUP_FORM_MODAL,
  payload: data,
})
