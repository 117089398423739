import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowBuyNumberModal } from "../../../store/phone-number/actions";
import { LIMIT, PAGE_SIZE } from "constants/constants";
import { axiosGet, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";

const initialValues = {
  areaCode: "",
  numberType: "local",
};

const validationSchema = Yup.object().shape({
  areaCode: Yup.string().required("Please enter the area code"),
  numberType: Yup.string().required("Please select number type"),
});
class BuyNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNumberType: "local",
      areaCode: "",
      availableNumbers: [],
      users: [],
      showSpinner: false,
    };
  }

  componentDidMount = () => {
    this.getAllUsers();
  };

  toggle = () => {
    const { onSetShowBuyNumberModal } = this.props;
    onSetShowBuyNumberModal();
    this.setState({ availableNumbers: [] });
  };

  // To get all user list
  getAllUsers = async () => {
    try {
      const users = await axiosGet(`users?page=1&limit=${LIMIT}`);
      if (users?.status) {
        this.setState({ users: users?.data?.results });
      }
    } catch (error) {
      console.error("error while getting all users :", error);
    }
  };

  // To get available numbers from twilio using area code and number type
  handleGetAvailableNumbers = async (areaCode, numberType) => {
    try {
      this.setState({ showSpinner: true, availableNumbers: [] });
      const numbers = await axiosGet(
        `buy-number/available-numbers?areaCode=${areaCode}&numberType=${numberType}&pageSize=${PAGE_SIZE}`
      );
      if (numbers?.status) {
        // if (numbers?.data.length > 0) {
        this.setState({
          availableNumbers: numbers?.data,
          areaCode,
          selectedNumberType: numberType,
        });
        this.getAllUsers();
        this.setState({ showSpinner: false });
        // } else {
        //   toast.error(numbers?.message || "No Numbers Available", {
        //     position: "top-right",
        //   });
        //   this.setState({ showSpinner: false });
        // }
      } else {
        toast.error(numbers?.message || "Oops! something went wrong", {
          position: "top-right",
        });
        this.setState({ showSpinner: false });
      }
    } catch (error) {
      this.setState({ showSpinner: false });
      console.error("error while getting available twilio numbers :", error);
    }
  };

  // To buy a twilio number
  handleBuyNumber = async values => {
    try {
      this.setState({ showSpinner: true });
      const { areaCode, selectedNumberType } = this.state;
      const { getAllNumbers } = this.props;
      const data = {
        assignedUserId: values?.user,
        phoneNumbers: values?.selectedNumbers,
        areaCode,
        numberType: selectedNumberType,
      };
      const response = await axiosPost(
        `buy-number/purchase-number?areaCode=${areaCode}&numberType=${selectedNumberType}&pageSize=${PAGE_SIZE}`,
        data
      );
      if (response?.status) {
        this.setState({ showSpinner: false });
        toast.success(response?.message || "Number Purchase successfull!", {
          position: "top-right",
        });
        getAllNumbers();
        this.setState({ availableNumbers: [] });
        this.toggle();
      } else {
        this.setState({ showSpinner: false });
        toast.error(response?.message || "Oops! Something went wrong!", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ showSpinner: false });
      console.error("error while purchasing a twilio number :", error);
    }
  };

  // Formik onSubmit handler
  handleOnSubmit = values => {
    this.handleGetAvailableNumbers(values["areaCode"], values["numberType"]);
  };

  render() {
    const { isEdit, modal } = this.props;
    const { availableNumbers, users, showSpinner } = this.state;
    return (
      <Modal isOpen={modal} className={this.props.className}>
        {showSpinner ? <ModalLoader /> : ""}
        <ModalHeader toggle={this.toggle} tag="h4">
          Buy Number
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Area Code</Label>
                      <Field
                        name="areaCode"
                        type="text"
                        className={
                          "form-control" +
                          (errors.areaCode && touched.areaCode
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="areaCode"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Number Type</Label>

                      <div className="">
                        <Field
                          name="numberType"
                          type="radio"
                          value="local"
                          id="local"
                          className="form-check-input me-1"
                        />
                        <Label className="form-check-label me-5" htmlFor="male">
                          Local
                        </Label>

                        <Field
                          name="numberType"
                          type="radio"
                          value="toll-free"
                          id="female"
                          className="form-check-input me-1"
                        />
                        <Label
                          className="form-check-label me-5"
                          htmlFor="female"
                        >
                          toll-free
                        </Label>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user d-flex justify-content-center"
                      >
                        Get Numbers
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {availableNumbers.length > 0 && (
            <Formik
              enableReinitialize={true}
              initialValues={{
                user: "",
                selectedNumbers: [],
              }}
              validationSchema={Yup.object().shape({
                user: Yup.string().required("Please select a user"),
                selectedNumbers: Yup.array()
                  .min(1, "Please select at least one phone number")
                  .required("Please select at least one phone number"),
              })}
              onSubmit={values => {
                this.handleBuyNumber(values);
              }}
            >
              {({ errors, status, touched }) => (
                <Form>
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">User</Label>
                        <Field
                          as="select"
                          name="user"
                          className={
                            "form-control-select" +
                            (errors.user && touched.user ? " is-invalid" : "")
                          }
                        >
                          <option value="">Select</option>
                          {users?.map((user, index) => (
                            <option
                              value={user?.id}
                              key={`${index}-${user?.name}`}
                            >
                              {user?.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="user"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="px-4">
                    {availableNumbers.map((phoneNumber, index) => (
                      <Col sm={6} key={index} className="">
                        <Label className="d-flex align-items-center">
                          <Field
                            type="checkbox"
                            name="selectedNumbers"
                            value={phoneNumber.phoneNumber}
                            className="me-2"
                          />
                          {phoneNumber.friendlyName}
                        </Label>
                      </Col>
                    ))}
                  </Row>
                  <ErrorMessage
                    name="selectedNumbers"
                    component="div"
                    className="error"
                  />
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSetShowBuyNumberModal: () => dispatch(setShowBuyNumberModal()),
});

const mapStateToProps = ({ phoneNumber }) => ({
  modal: phoneNumber.showBuyNumberModal,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyNumber));
