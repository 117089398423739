import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import {
  apiError,
  loginUser,
  socialLogin,
  addNotification,
  setTwilioVoiceToken,
  toggleInitializeNewSocket,
  updateNotification,
} from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import { axiosGet, axiosPost } from "helpers/api_helpers";
import { SOMETHING_WENT_WRONG } from "constants/constants";
import { UserContext } from "../../components/UserProvider/UserProvider";
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "helpers/common_helpers";
import socketService from "utils/socket";
import zappycomLogo from "../../assets/images/zappycom.png";

class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  // static contextType = UserContext;

  componentDidMount = () => {
    const { userId } = this.props.match.params;
    this.props.apiError("");
    const accessToken = getDataFromLocalStorage("accessToken");
    if (accessToken) {
      this.props.history.push("/");
    }
    if (userId) {
      this.handleLoginAdminAsUser(userId);
    }
  };

  handleLoginAdminAsUser = async userId => {
    try {
      const { getUserinfo } = this.context;
      const { initializeSocket, toggleInitializeNewSocket } = this.props;
      setDataInLocalStorage(
        "adminAccessToken",
        getDataFromLocalStorage("accessToken")
      );
      setDataInLocalStorage(
        "adminRefreshToken",
        getDataFromLocalStorage("refreshToken")
      );

      const response = await axiosGet(`auth/admin-to-user/${userId}`);
      if (response?.status) {
        const userData = response?.data?.user;
        // const auth = {userId:""};

        // Connect to socket after successful login
        // socketService.init(userData?.id);
        // socketService.emit('login', userData)
        // socketService.on('login', (data) => {
        // })

        setDataInLocalStorage(
          "accessToken",
          response?.data?.tokens?.access?.token
        );
        setDataInLocalStorage(
          "refreshToken",
          response?.data?.tokens?.refresh?.token
        );

        await getUserinfo();
        this.handleGetUnreadMessages();
        await this.handleGetTwilioVoiceToken();

        toggleInitializeNewSocket();

        this.props.history.push("/");
      } else {
        console.error("Login API error: ", response?.message);
        this.props.apiError(response?.message || SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error("Login error: ", error);
      this.props.apiError(error?.message || SOMETHING_WENT_WRONG);
    }
  };

  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    }
  };

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google");
  };

  //handleTwitterLoginResponse
  twitterResponse = () => {};

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook");
  };

  handleGetUnreadMessages = async () => {
    try {
      const { updateNotification } = this.props;
      const response = await axiosGet("/conversation/unread-messages");
      if (response?.status) {
        updateNotification(response?.data);
      }
    } catch (error) {
      console.error("error at handleNotificationClick", error);
    }
  };

  handleLogin = async values => {
    try {
      const { toggleInitializeNewSocket } = this.props;
      const { getUserinfo } = this.context;

      const response = await axiosPost("/auth/login", {
        email: values?.email,
        password: values?.password,
      });
      if (response?.status) {
        setDataInLocalStorage(
          "accessToken",
          response?.data?.tokens?.access?.token
        );
        setDataInLocalStorage(
          "refreshToken",
          response?.data?.tokens?.refresh?.token
        );
        setDataInLocalStorage(
          "accessToken",
          response?.data?.tokens?.access?.token
        );
        setDataInLocalStorage(
          "refreshToken",
          response?.data?.tokens?.refresh?.token
        );

        this.handleGetUnreadMessages();
        await this.handleGetTwilioVoiceToken();

        this.props.history.push("/");

        toggleInitializeNewSocket();

        await getUserinfo();
      } else {
        console.error("Login API error: ", response?.message);
        this.props.apiError(response?.message || SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error("Login error: ", error);
      this.props.apiError(error?.message || SOMETHING_WENT_WRONG);
    }
  };

  handleGetTwilioVoiceToken = async () => {
    try {
      const { setTwilioVoiceToken } = this.props;
      const response = await axiosGet("private-cred/voice-access-token");
      if (response.status) {
        setTwilioVoiceToken(response?.data);
      }
    } catch (error) {
      console.error("error at handleGetTwilioVoiceToken", error);
    }
  };

  render() {
    const { showPassword } = this.state;

    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Zappycom.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={zappycomLogo}
                              alt=""
                              // className="rounded-circle"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={zappycomLogo}
                              alt=""
                              // className="rounded-circle"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: this.state && this.state.email,
                          password: this.state && this.state.password,
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required(
                            "Please Enter Your Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                        })}
                        onSubmit={this.handleLogin}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    this.setState({
                                      showPassword: !showPassword,
                                    })
                                  }
                                >
                                  {showPassword ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>

                            <div className="mt-4 text-center"></div>

                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, initializeSocket } = state.Login;
  return { error, initializeSocket };
};

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    apiError,
    socialLogin,
    addNotification,
    setTwilioVoiceToken,
    toggleInitializeNewSocket,
    updateNotification,
  })(Login)
);
