import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { PAGE_SIZE } from "../../constants/constants";

class Pagination extends Component {
  render() {
    const { totalSize, handlePageClick, currentPage, currentSize } = this.props;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <ReactPaginate
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-3"
          previousLinkClassName="page-link"
          nextClassName="page-item ms-3"
          nextLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          breakLabel="..."
          nextLabel="Next >"
          previousLabel="< Prev"
          forcePage={currentPage}
          onPageChange={selectedItem => {
            const adjustedPageNumber = selectedItem.selected + 1;
            handlePageClick(adjustedPageNumber);
          }}
          pageRangeDisplayed={3}
          pageCount={Math.ceil(totalSize / currentSize)}
          renderOnZeroPageCount={null}
        />
      </div>
    );
  }
}

export default Pagination;
