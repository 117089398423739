import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";

import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import emptyContacts from "../../assets/images/emptyContact.png";
import { isEmpty, size, map } from "lodash";
import { axiosGet } from "helpers/api_helpers";
import { setLoader } from "store/actions";
import moment from "moment";
import { FormControl } from "react-bootstrap";

class CallLogs extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      callLogs: [],
      callLog: "",
      deleteModal: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      selectedNumberType: "local",
      searchQuery: "",
      contactListColumns: [
        {
          text: "Time",
          dataField: "createdAt",
          sort: true,
          formatter: cellContent => {
            return (
              <span> {moment(cellContent).format("YYYY-MM-DD HH:mm:ss")}</span>
            );
          },
        },

        {
          text: "Name",
          dataField: "name",
          sort: true,
        },
        {
          text: "To",
          dataField: "to",
          sort: true,
        },
        {
          text: "From",
          dataField: "from",
          sort: true,
        },
        {
          text: "Type",
          dataField: "type",
          sort: true,
        },
        {
          text: "Duration",
          dataField: "duration",
          sort: true,
        },
        {
          text: "Status",
          dataField: "status",
          sort: true,
          formatter: (cellContent, log) => (
            <span
              className={`badge rounded-pill ${
                log?.status === "completed" ? "bg-success" : "bg-danger"
              } fs-6`}
            >
              {log?.status}
            </span>
          ),
        },
        {
          text: "Cost",
          dataField: "cost",
          sort: true,
          formatter: cellContent => <span>{cellContent}</span>,
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => <span>-</span>,
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = async () => {
    // const { users, onGetUsers } = this.props;
    const { callLogs } = this.state;
    // if (users && !users.length) {
    //   onGetUsers();
    // }
    await this.handleGetCallLogs(this.state.page);
    // this.setState({ users, totalSize: callLogs.length });
  };

  toggle() {
    const { onSetShowBuyNumberModal } = this.props;
    onSetShowBuyNumberModal();
  }

  handleUserClicks = () => {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetCallLogs(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetCallLogs(selectedPage);
    });
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  handleGetCallLogs = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `calldailer?voiceRecordingUrl=false&page=${page}&limit=${
          this.state.size
        }${searchQuery ? `&q=${searchQuery}` : ""}&sortBy=createdAt:desc`
      );
      if (response?.status) {
        this.setState({
          callLogs: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error while getting call logs :", error);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetCallLogs(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "call-logs";

    const { callLogs, deleteModal } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Logs" breadcrumbItem="Call Logs" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                    </Col>
                    {callLogs.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={callLogs}
                            columns={this.state.contactListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />

                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

CallLogs.propTypes = {
  className: PropTypes.any,
};

const mapStateToProps = ({ contacts }) => ({});

const mapDispatchToProps = dispatch => ({
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CallLogs));
