import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { timezoneOptions } from "constants/constants";
import Select from "react-select";
import { axiosGet, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoader } from "../../../../store/actions";

class BulkWhatsappMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: "",
      selectedTimeZone: "",
      selectedWhatsappNumber: "",
      whatsappTemplates: [],
      contactGroups: [],
      senderNumbers: [],
    };
  }

  componentDidMount = () => {
    // const { onSetLoader } = this.props;
    // onSetLoader(false);
    this.handleGetAllContactGroups();
    this.handleGetAllWhatsappNumbers();
    // this.handleGetAllWhatsappTemplates();
  };

  handleMulti = (selectedTemplate, setFieldValue) => {
    this.setState({ selectedTemplate });
    setFieldValue("smsTemplate", selectedTemplate);
  };

  handleTimeZone = (selectedTimeZone, setFieldValue) => {
    this.setState({ selectedTimeZone });
    setFieldValue("timezone", selectedTimeZone?.value);
  };

  handleWhatsappNumberSelect = (value, setFieldValue) => {
    const { onSetLoader } = this.props;
    const [id, phoneNumberId] = value.split("-");

    setFieldValue("senderId", value);
    onSetLoader(true);
    this.setState({ selectedWhatsappNumber: phoneNumberId }, () => {
      this.handleGetAllWhatsappTemplates(phoneNumberId);
    });
    onSetLoader(false);
  };

  handleGetAllWhatsappNumbers = async () => {
    try {
      const response = await axiosGet("whatsapp-number");
      if (response?.status) {
        this.setState({ senderNumbers: response?.data?.results });
      }
    } catch (error) {
      console.error("error at handleGetAllSenderGroups :", error);
    }
  };

  handleGetAllContactGroups = async () => {
    try {
      const response = await axiosGet("contact-groups/all");
      if (response?.status) {
        this.setState({ contactGroups: response?.data });
      }
    } catch (error) {
      console.error("error at handleGetAllContactGroups :", error);
    }
  };

  handleGetAllWhatsappTemplates = async phoneNumberId => {
    try {
      const response = await axiosGet(
        `whatsapp-template/all?phoneNumberId=${phoneNumberId}`
      );
      if (response?.status) {
        this.setState({ whatsappTemplates: response?.data });
      }
    } catch (error) {
      console.error("error at handleGetAllSmsTemplates :", error);
    }
  };

  handleWhatsappCampaign = async (campaignData, formik) => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const response = await axiosPost(
        `campaign/bulk-whatsapp-message`,
        campaignData
      );
      if (response?.status) {
        toast.success(
          response?.message || "Whatsapp Campaign created successfully!"
        );
        this.setState({ selectedTimeZone: "" });
        formik.resetForm();
        onSetLoader(false);
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at handleWhatsappCampaoign", error);
    }
  };

  handleOnSubmit = (values, formik) => {
    try {
      const [id, phoneNumberId] = values?.senderId.split("-");
      const campaignData = {
        name: values?.campaignTitle,
        senderNumberId: id,
        contactGroupId: values?.group,
        templateId: values?.whatsappTemplate?.id,
        whatsappData: {
          dynamicDate: values?.dynamicDate,
        },
        dateTime: values?.dateTime,
        timezone: values?.timezone,
      };
      this.handleWhatsappCampaign(campaignData, formik);
    } catch (error) {
      console.error("error at handleOnSubmit buk whatsapp", error);
    }
  };

  render() {
    //meta title

    const initialValues = {
      campaignTitle: "",
      senderId: "",
      group: "",
      whatsappTemplate: "",
      dynamicDate: "",
      dateTime: "",
      timezone: "",
    };

    const validationSchema = Yup.object().shape({
      campaignTitle: Yup.string().required(
        "Please mention the title for the campaign"
      ),

      senderId: Yup.string().required("Please select sender id"),
      group: Yup.string().required("Please select a group"),
      // whatsappTemplate: Yup.string().required("Please select a template"),
      // whatsappTemplate: Yup.object().required("Please select a template"),
      whatsappTemplate: Yup.object().shape({
        // Other validation rules for whatsappTemplate if needed
        _id: Yup.string(),
        templateName: Yup.string(),
        templateContent: Yup.string(),
      }),

      // dynamicDate: Yup.string().required("Please provide dynamic date"),
      dateTime: Yup.string().required("Please provide schedule date and time"),
      dynamicDate: Yup.string().when(
        "whatsappTemplate.templateContent",
        (templateContent, schema) => {
          return templateContent && templateContent.includes("{{DATE}}")
            ? schema.required("Please provide dynamic date")
            : schema;
        }
      ),
      timezone: Yup.string().required("Please select a timezone"),
    });

    const {
      selectedTimeZone,
      senderNumbers,
      whatsappTemplates,
      contactGroups,
    } = this.state;
    document.title = "message";

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={this.handleOnSubmit}
                    >
                      {({ errors, status, touched, values, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">
                                  Campaign Title
                                </Label>
                                <Field
                                  name="campaignTitle"
                                  type="text"
                                  placeholder="Enter the campaign title..."
                                  className={
                                    "form-control" +
                                    (errors.campaignTitle &&
                                    touched.campaignTitle
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="campaignTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Sender ID</Label>
                                <Field
                                  as="select"
                                  name="senderId"
                                  className={
                                    "form-control-select form-select-border-bottom" +
                                    (errors.senderId && touched.senderId
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={event => {
                                    this.handleWhatsappNumberSelect(
                                      event.target.value,
                                      setFieldValue
                                    );
                                  }}
                                  value={values.senderId}
                                >
                                  <option value="">Select a sender</option>
                                  {senderNumbers?.map((number, index) => (
                                    <option
                                      value={`${number.id}-${number.numberId}`}
                                      key={`${number.phoneNumber}-${index}`}
                                    >
                                      {number.name}({number.phoneNumber})
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="senderId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Contact Group
                                </Label>
                                <Field
                                  as="select"
                                  name="group"
                                  className={
                                    "form-control-select form-select-border-bottom" +
                                    (errors.group && touched.group
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option value="">Select contact group</option>
                                  {contactGroups?.map((group, index) => (
                                    <option
                                      value={group.id}
                                      key={`${group.id}-${index}`}
                                    >
                                      {group.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="group"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Whatsapp Template
                                </Label>
                                <Field
                                  as="select"
                                  name="whatsappTemplate"
                                  className={
                                    "form-control-select form-select-border-bottom" +
                                    (errors.whatsappTemplate &&
                                    touched.whatsappTemplate
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={e => {
                                    setFieldValue(
                                      "whatsappTemplate",
                                      JSON.parse(e.target.value)
                                    );
                                  }}
                                  value={JSON.stringify(
                                    values.whatsappTemplate
                                  )}
                                >
                                  <option value="">Select a template</option>
                                  {whatsappTemplates.length
                                    ? whatsappTemplates?.map(
                                        (template, index) => (
                                          <option
                                            value={JSON.stringify(template)}
                                            key={`${template.id}-${index}`}
                                          >
                                            {template.templateName}
                                          </option>
                                        )
                                      )
                                    : null}
                                </Field>
                                <ErrorMessage
                                  name="whatsappTemplate"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Dynamic Date
                                </Label>
                                <Field
                                  type="date"
                                  id="yourDatetimeField"
                                  name="dynamicDate"
                                  className={
                                    "form-control-select" +
                                    (errors.dynamicDate && touched.dynamicDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                ></Field>
                                <ErrorMessage
                                  name="dynamicDate"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Date & Time
                                </Label>
                                <Field
                                  type="datetime-local"
                                  id="yourDatetimeField"
                                  name="dateTime"
                                  className={
                                    "form-control-select" +
                                    (errors.dateTime && touched.dateTime
                                      ? " is-invalid"
                                      : "")
                                  }
                                ></Field>
                                <ErrorMessage
                                  name="dateTime"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Timezone</Label>

                                <Select
                                  value={selectedTimeZone}
                                  onChange={selectedValue => {
                                    this.handleTimeZone(
                                      selectedValue,
                                      setFieldValue
                                    );
                                  }}
                                  options={timezoneOptions}
                                  classNamePrefix="select2-selection"
                                  className={
                                    "form-control-select form-select-border-bottom select-border-bottom-none p-0" +
                                    (errors.timezone && touched.timezone
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="timezone"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Send Now
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// export default BulkWhatsappMessage;

BulkWhatsappMessage.propTypes = {
  onSetLoader: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetLoader: bool => dispatch(setLoader(bool)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(BulkWhatsappMessage));
