
import { SHOW_ASSIGN_NUMBER_MODAL, SHOW_SENDER_GROUP_MODAL } from "./actionTypes"

const INIT_STATE = {
  showSenderFormModal: false,
  showAssignNumberModal: false
}

const senderGroup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_SENDER_GROUP_MODAL:
      return {
        ...state,
        showSenderFormModal: !state.showSenderFormModal,
      }

    case SHOW_ASSIGN_NUMBER_MODAL:
      return {
        ...state,
        showAssignNumberModal: !state.showAssignNumberModal,
      }

    default:
      return state
  }
}

export default senderGroup
