import React, { Component } from "react";
import {
  getDataFromLocalStorage,
  parseJwt,
} from "../../helpers/common_helpers";
import { withRouter } from "react-router-dom";
import { axiosGet } from "helpers/api_helpers";

export const UserContext = React.createContext({});

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      userData: "",
      isLoading: true,
    };
  }

  getUser = async id => {
    try {
      const users = await axiosGet(`users/${id}`);
      if (users?.status) {
        this.setState({ userData: users?.data });
      }
      this.setState({ isLoading: false });
      return users?.data;
    } catch (error) {
      console.error("error while getting user :", error);
    }
  };

  getUserinfo = async () => {
    try {
      const token = getDataFromLocalStorage("accessToken");

      if (token) {
        const userinfo = parseJwt(token);
        this.setState({ user: userinfo });
        const data = await this.getUser(userinfo?.sub);
        return data;
        // this.setState({ userData: info?.data?.user });
      } else {
        this.setState({ isLoading: false });
        return;
      }
    } catch (err) {
      if (err === "Please authenticate") {
        localStorage.clear();
        this.props.history.push("/login");
      }
    }
  };

  componentDidMount() {
    this.getUserinfo();
  }

  render() {
    const { user, userData } = this.state;
    return (
      <UserContext.Provider
        value={{
          user,
          getUserinfo: this.getUserinfo,
          setUser: newUser => this.setState({ user: newUser }),
          userData,
          setUserdata: newUserData => this.setState({ userData: newUserData }),
        }}
      >
        {this.state.isLoading ? <></> : this.props.children}
      </UserContext.Provider>
    );
  }
}

export default withRouter(UserProvider);
