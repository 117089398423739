import { whatsappVariables } from "constants/constants";
import React, { Component } from "react";

class VariableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { setSelectedVariable } = this.props;
    return (
      <React.Fragment>
        <div className="mb-1">
          <select
            id="groupSelection"
            className="form-select width-10rem"
            onClick={event => setSelectedVariable(event.target.value)}
          >
            <option value="">Select a Variable</option>
            {Object.values(whatsappVariables).map(
              (whatsappVariablesName, index) => (
                <option
                  value={whatsappVariablesName}
                  key={whatsappVariablesName}
                >
                  {whatsappVariablesName}
                </option>
              )
            )}
          </select>
        </div>
      </React.Fragment>
    );
  }
}

export default VariableSelect;
