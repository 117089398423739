import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

import logo from "../assets/images/logo.svg";

import { closeToast } from "../store/toast/actions";
import { connect } from "react-redux";

class ToastMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.closeToast();
    }, this.props.duration);
  }

  render() {
    const { show, type, message, title } = this.props;

    return (
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={show}>
          <ToastHeader>
            <img src={logo} alt="" className="me-2" height="18" />
            {title}
          </ToastHeader>
          <ToastBody
            className={type === "success" ? "text-success" : "text-danger"}
          >
            {message}
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

ToastMessage.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  duration: PropTypes.number,
};

ToastMessage.defaultProps = {
  show: false,
  type: "success",
  title: "Toast",
  message: "",
  duration: 2000,
};

const mapStateToProps = state => {
  const { show, type, message, duration, title } = state.ToastMsg;
  return { show, type, message, duration, title };
};

export default withRouter(
  connect(mapStateToProps, { closeToast })(ToastMessage)
);
