export const SOMETHING_WENT_WRONG = "Something went wrong";
export const deviceToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzUyYTAyOTMwNDFlYTkzN2RmMzQ5MjljNmIzMTQwYTY0LTE3MTA5OTU1OTYiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJicm93c2VydXNlciIsInZvaWNlIjp7ImluY29taW5nIjp7ImFsbG93Ijp0cnVlfSwib3V0Z29pbmciOnsiYXBwbGljYXRpb25fc2lkIjoiQVA1YjMwOWRkYzQ0ODAzNmUwYmFkN2Y4MjQyYTA5MDk0ZiJ9fX0sImlhdCI6MTcxMDk5NTU5NiwiZXhwIjoxNzEwOTk5MTk2LCJpc3MiOiJTSzUyYTAyOTMwNDFlYTkzN2RmMzQ5MjljNmIzMTQwYTY0Iiwic3ViIjoiQUMzNTc4NmJkZWQwN2FmYjQyZjlhYmMxMGQ0NzlkMWFhOSJ9.dm2Avwvh_-OOb29oZue4eHwy_NzMdhoSAhhvViF0nk4";

export const PAGE = 1;
export const SIZE_PER_PAGE = 10;
export const TOTAL_SIZE = 0;

export const LIMIT = 1000;
export const CONVERSATIONS_LIMIT = 40;
export const PAGE_SIZE = 20;
export const COPILIOT = "copilot";
export const limitRange = [10, 25, 30, 50];

export const roles = {
  admin: "admin",
  user: "user",
};

export const inboxTabTypes = {
  SMS: "1",
  WHATSAPP: "2",
};

export const callTypes = {
  INBOUND: "inbound",
  OUTBOUND: "outbound",
};

export const defaultSelect = { value: "select", label: "Select" };

export const randomString = (
  length,
  dashLength = 0,
  chars = "0123456789abcdefghijklmnopqrstuvwxyz"
) => {
  let result = "";
  for (let i = 0; i < length; i += 1) {
    if (dashLength && i !== 0 && i % dashLength === 0) {
      result += "-";
    } else {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
  }
  return result;
};

export const capitalizeFirstLetter = str => {
  // Check if the string is empty or null
  if (!str) {
    return str;
  }

  // Capitalize the first letter and convert the rest of the string to lowercase
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const numberType = {
  "toll-free": "Toll-Free",
  local: "Local",
};

export const bulkMsgType = {
  bulk_message: "Bulk Message",
  bulk_image: "Bulk Image",
  quick_response: "Quick Response",
};

export const fileType = {
  image: "image",
  video: "video",
  document: "document",
};

export const whatsappTemplateParameterTypes = {
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  DOCUMENT: "DOCUMENT",
  VIDEO: "VIDEO",
  LOCATION: "LOCATION",
};

export const AcceptedFileTypes = {
  audio: "audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg",
  document:
    "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  image: "image/jpeg, image/png",
  video: "video/mp4, video/3gp",
};

export const optType = {
  start_with: "start with",
  in_contain_sms: "in contain sms",
  end_of_string: "end of string",
};

export const fileSizeAllowed = {
  AUDIO: 16,
  VIDEO: 16,
  DOCUMENT: 100,
  IMAGE: 5,
};

export const bytesToMegabytes = bytes => {
  const MB = 1024 * 1024; // 1 MB in bytes
  return Math.ceil(bytes / MB);
};

export const channelType = {
  sms: "sms",
  whatsapp: "whatsapp",
};

export const templateType = {
  sms: "sms",
  whatsapp: "whatsapp",
};

export const conversationType = {
  SMS: "sms",
  WHATSAPP: "whatsapp",
};

export const messageTypes = {
  SEND: "send",
  RECEIVED: "received",
};

export const inboxFilter = {
  isAnsweredByCustomer: "Unanswered",
  isOpenedByUser: "Unopened",
};

export const templateComponentType = {
  HEADER: "HEADER",
  BODY: "BODY",
  FOOTER: "FOOTER",
  BUTTONS: "BUTTONS",
};

export const whatsappTemplateButtonTypes = {
  QUICK_REPLY: "Quick Reply",
  COPY_CODE: "Copy Code",
  URL: "URL",
  PHONE_NUMBER: "Phone Number",
  OTP: "OTP",
  MPM: "MPM",
  CATALOG: "Catalog",
  FLOW: "Flow",
  VOICE_CALL: "Voice Call",
};

export const reports = [
  {
    title: "Sent SMS",
    iconClass: "mdi mdi-email",
    key: "totalSentMessages",
    description: "0",
  },
  {
    title: "Delivered SMS",
    iconClass: "mdi mdi-email-check",
    key: "totalDeliveredMessages",
    description: "0",
  },
  {
    title: "Failed SMS",
    iconClass: "mdi mdi-email-remove",
    key: "totalFailedMessages",
    description: "0",
  },
  {
    title: "Queued SMS",
    iconClass: "mdi mdi-email-plus",
    key: "totalQueuedMessages",
    description: "0",
  },
  {
    title: "Not Respond",
    iconClass: "mdi mdi-email-mark-as-unread",
    key: "totalNotRespondMessages",
    description: "0",
  },
  // {
  //   title: "Undelivered",
  //   iconClass: "mdi mdi-email-off",
  //   key: "totalUndeliveredMessages",
  //   description: "0",
  // },
  {
    title: "Success Respond",
    iconClass: "mdi mdi-email",
    key: "totalSuccessRespondMessages",
    description: "0",
  },
];

export const smsCharacterCount = 150;
export const whatsappCharacterCount = 1024;

export const tag = {
  name: "NAME",
  address: "ADDRESS",
  zip: "ZIP",
  state: "STATE",
  city: "CITY",
  date: "DATE",
};

export const whatsappVariables = {
  name: "NAME",
  address: "ADDRESS",
  zip: "ZIP",
  state: "STATE",
  city: "CITY",
  date: "DATE",
};

export const generateMonthList = () => {
  const currentDate = new Date();
  const twelveMonthsAgo = new Date();
  twelveMonthsAgo.setFullYear(currentDate.getFullYear() - 2); // two year back from current date

  const monthsList = [];

  // Iterate from twelve months ago to current date
  let currentDateIter = new Date(twelveMonthsAgo);
  while (currentDateIter <= currentDate) {
    const formattedMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    }).format(currentDateIter);

    monthsList.push(formattedMonth);

    // Move to the next month
    currentDateIter.setMonth(currentDateIter.getMonth() + 1);
  }

  const monthOptions = monthsList?.map(month => ({
    label: month,
    value: month.split(" ").join(",").toLowerCase(),
  }));
  return monthOptions;
};

export const templateLanguage = {
  ENGLISH: "en",
  ENGLISH_UK: "en_UK",
  ENGLISH_US: "en_US",
  SPANISH: "es",
  SPANISH_SPA: "es_ES",
  SPANISH_ARG: "es_AR",
  SPANISH_MEX: "es_MX",
};

export const timezoneOptions = [
  { label: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
  { label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
  { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { label: "(GMT-09:00) Alaska", value: "US/Alaska" },
  {
    label: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  { label: "(GMT-08:00) Tijuana, Baja California", value: "America/Tijuana" },
  { label: "(GMT-07:00) Arizona", value: "US/Arizona" },
  {
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    value: "America/Chihuahua",
  },
  { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
  { label: "(GMT-06:00) Central America", value: "America/Managua" },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: "US/Central" },
  {
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    value: "America/Mexico_City",
  },
  { label: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
  {
    label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
    value: "America/Bogota",
  },
  { label: "(GMT-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
  { label: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
  { label: "(GMT-04:00) Atlantic Time (Canada)", value: "Canada/Atlantic" },
  { label: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
  { label: "(GMT-04:00) Manaus", value: "America/Manaus" },
  { label: "(GMT-04:00) Santiago", value: "America/Santiago" },
  { label: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
  { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
  {
    label: "(GMT-03:00) Buenos Aires, Georgetown",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "(GMT-03:00) Greenland", value: "America/Godthab" },
  { label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
  { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
  {
    label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
    value: "Africa/Casablanca",
  },
  {
    label:
      "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    value: "Etc/Greenwich",
  },
  {
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "Europe/Amsterdam",
  },
  {
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    value: "Europe/Belgrade",
  },
  {
    label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    value: "Europe/Brussels",
  },
  {
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "Europe/Sarajevo",
  },
  { label: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
  { label: "(GMT+02:00) Amman", value: "Asia/Amman" },
  { label: "(GMT+02:00) Athens, Bucharest, Istanbul", value: "Europe/Athens" },
  { label: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
  { label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
  { label: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
  {
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    value: "Europe/Helsinki",
  },
  { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
  { label: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
  { label: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
  { label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
  {
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    value: "Europe/Moscow",
  },
  { label: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
  { label: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
  { label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
  { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
  { label: "(GMT+04:00) Baku", value: "Asia/Baku" },
  { label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
  { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
  { label: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: "Asia/Karachi" },
  {
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "Asia/Calcutta",
  },
  { label: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
  { label: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
  { label: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
  { label: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
  { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
  { label: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  {
    label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    value: "Asia/Hong_Kong",
  },
  { label: "(GMT+08:00) Kuala Lumpur, Singapore", value: "Asia/Kuala_Lumpur" },
  { label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
  { label: "(GMT+08:00) Perth", value: "Australia/Perth" },
  { label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
  { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
  { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
  { label: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
  { label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
  { label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
  { label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
  {
    label: "(GMT+10:00) Canberra, Melbourne, Sydney",
    value: "Australia/Canberra",
  },
  { label: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
  { label: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
  { label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
  {
    label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    value: "Asia/Magadan",
  },
  { label: "(GMT+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
  { label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "Pacific/Fiji" },
  { label: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
];

export const countryCodeOptions = [
  { label: "USA (+1)", value: "1" },
  { label: "Norway (+47)", value: "47" },
  { label: "UK (+44)", value: "44" },
  { label: "Algeria (+213)", value: "213" },
  { label: "Andorra (+376)", value: "376" },
  { label: "Angola (+244)", value: "244" },
  { label: "Anguilla (+1264)", value: "1264" },
  { label: "Antigua & Barbuda (+1268)", value: "1268" },
  { label: "Argentina (+54)", value: "54" },
  { label: "Armenia (+374)", value: "374" },
  { label: "Aruba (+297)", value: "297" },
  { label: "Australia (+61)", value: "61" },
  { label: "Austria (+43)", value: "43" },
  { label: "Azerbaijan (+994)", value: "994" },
  { label: "Bahamas (+1242)", value: "1242" },
  { label: "Bahrain (+973)", value: "973" },
  { label: "Bangladesh (+880)", value: "880" },
  { label: "Barbados (+1246)", value: "1246" },
  { label: "Belarus (+375)", value: "375" },
  { label: "Belgium (+32)", value: "32" },
  { label: "Belize (+501)", value: "501" },
  { label: "Benin (+229)", value: "229" },
  { label: "Bermuda (+1441)", value: "1441" },
  { label: "Bhutan (+975)", value: "975" },
  { label: "Bolivia (+591)", value: "591" },
  { label: "Bosnia Herzegovina (+387)", value: "387" },
  { label: "Botswana (+267)", value: "267" },
  { label: "Brazil (+55)", value: "55" },
  { label: "Brunei (+673)", value: "673" },
  { label: "Bulgaria (+359)", value: "359" },
  { label: "Burkina Faso (+226)", value: "226" },
  { label: "Burundi (+257)", value: "257" },
  { label: "Cambodia (+855)", value: "855" },
  { label: "Cameroon (+237)", value: "237" },
  { label: "Canada (+1)", value: "1" },
  { label: "Cape Verde Islands (+238)", value: "238" },
  { label: "Cayman Islands (+1345)", value: "1345" },
  { label: "Central African Republic (+236)", value: "236" },
  { label: "Chile (+56)", value: "56" },
  { label: "China (+86)", value: "86" },
  { label: "Colombia (+57)", value: "57" },
  { label: "Comoros (+269)", value: "269" },
  { label: "Congo (+242)", value: "242" },
  { label: "Cook Islands (+682)", value: "682" },
  { label: "Costa Rica (+506)", value: "506" },
  { label: "Croatia (+385)", value: "385" },
  { label: "Cuba (+53)", value: "53" },
  { label: "Cyprus North (+90392)", value: "90392" },
  { label: "Cyprus South (+357)", value: "357" },
  { label: "Czech Republic (+42)", value: "42" },
  { label: "Denmark (+45)", value: "45" },
  { label: "Djibouti (+253)", value: "253" },
  { label: "Dominica (+1809)", value: "1809" },
  { label: "Dominican Republic (+1809)", value: "1809" },
  { label: "Ecuador (+593)", value: "593" },
  { label: "Egypt (+20)", value: "20" },
  { label: "El Salvador (+503)", value: "503" },
  { label: "Equatorial Guinea (+240)", value: "240" },
  { label: "Eritrea (+291)", value: "291" },
  { label: "Estonia (+372)", value: "372" },
  { label: "Ethiopia (+251)", value: "251" },
  { label: "Falkland Islands (+500)", value: "500" },
  { label: "Faroe Islands (+298)", value: "298" },
  { label: "Fiji (+679)", value: "679" },
  { label: "Finland (+358)", value: "358" },
  { label: "France (+33)", value: "33" },
  { label: "French Guiana (+594)", value: "594" },
  { label: "French Polynesia (+689)", value: "689" },
  { label: "Gabon (+241)", value: "241" },
  { label: "Gambia (+220)", value: "220" },
  { label: "Georgia (+7880)", value: "7880" },
  { label: "Germany (+49)", value: "49" },
  { label: "Ghana (+233)", value: "233" },
  { label: "Gibraltar (+350)", value: "350" },
  { label: "Greece (+30)", value: "30" },
  { label: "Greenland (+299)", value: "299" },
  { label: "Grenada (+1473)", value: "1473" },
  { label: "Guadeloupe (+590)", value: "590" },
  { label: "Guam (+671)", value: "671" },
  { label: "Guatemala (+502)", value: "502" },
  { label: "Guinea (+224)", value: "224" },
  { label: "Guinea - Bissau (+245)", value: "245" },
  { label: "Guyana (+592)", value: "592" },
  { label: "Haiti (+509)", value: "509" },
  { label: "Honduras (+504)", value: "504" },
  { label: "Hong Kong (+852)", value: "852" },
  { label: "Hungary (+36)", value: "36" },
  { label: "Iceland (+354)", value: "354" },
  { label: "India (+91)", value: "91" },
  { label: "Indonesia (+62)", value: "62" },
  { label: "Iran (+98)", value: "98" },
  { label: "Iraq (+964)", value: "964" },
  { label: "Ireland (+353)", value: "353" },
  { label: "Israel (+972)", value: "972" },
  { label: "Italy (+39)", value: "39" },
  { label: "Jamaica (+1876)", value: "1876" },
  { label: "Japan (+81)", value: "81" },
  { label: "Jordan (+962)", value: "962" },
  { label: "Kazakhstan (+7)", value: "7" },
  { label: "Kenya (+254)", value: "254" },
  { label: "Kiribati (+686)", value: "686" },
  { label: "Korea North (+850)", value: "850" },
  { label: "Korea South (+82)", value: "82" },
  { label: "Kuwait (+965)", value: "965" },
  { label: "Kyrgyzstan (+996)", value: "996" },
  { label: "Laos (+856)", value: "856" },
  { label: "Latvia (+371)", value: "371" },
  { label: "Lebanon (+961)", value: "961" },
  { label: "Lesotho (+266)", value: "266" },
  { label: "Liberia (+231)", value: "231" },
  { label: "Libya (+218)", value: "218" },
  { label: "Liechtenstein (+417)", value: "417" },
  { label: "Lithuania (+370)", value: "370" },
  { label: "Luxembourg (+352)", value: "352" },
  { label: "Macao (+853)", value: "853" },
  { label: "Macedonia (+389)", value: "389" },
  { label: "Madagascar (+261)", value: "261" },
  { label: "Malawi (+265)", value: "265" },
  { label: "Malaysia (+60)", value: "60" },
  { label: "Maldives (+960)", value: "960" },
  { label: "Mali (+223)", value: "223" },
  { label: "Malta (+356)", value: "356" },
  { label: "Marshall Islands (+692)", value: "692" },
  { label: "Martinique (+596)", value: "596" },
  { label: "Mauritania (+222)", value: "222" },
  { label: "Mayotte (+269)", value: "269" },
  { label: "Mexico (+52)", value: "52" },
  { label: "Micronesia (+691)", value: "691" },
  { label: "Moldova (+373)", value: "373" },
  { label: "Monaco (+377)", value: "377" },
  { label: "Mongolia (+976)", value: "976" },
  { label: "Montserrat (+1664)", value: "1664" },
  { label: "Morocco (+212)", value: "212" },
  { label: "Mozambique (+258)", value: "258" },
  { label: "Myanmar (+95)", value: "95" },
  { label: "Namibia (+264)", value: "264" },
  { label: "Nauru (+674)", value: "674" },
  { label: "Nepal (+977)", value: "977" },
  { label: "Netherlands (+31)", value: "31" },
  { label: "New Caledonia (+687)", value: "687" },
  { label: "New Zealand (+64)", value: "64" },
  { label: "Nicaragua (+505)", value: "505" },
  { label: "Niger (+227)", value: "227" },
  { label: "Nigeria (+234)", value: "234" },
  { label: "Niue (+683)", value: "683" },
  { label: "Norfolk Islands (+672)", value: "672" },
  { label: "Northern Marianas (+670)", value: "670" },
  { label: "Norway (+47)", value: "47" },
  { label: "Oman (+968)", value: "968" },
  { label: "Palau (+680)", value: "680" },
  { label: "Panama (+507)", value: "507" },
  { label: "Papua New Guinea (+675)", value: "675" },
  { label: "Paraguay (+595)", value: "595" },
  { label: "Peru (+51)", value: "51" },
  { label: "Philippines (+63)", value: "63" },
  { label: "Poland (+48)", value: "48" },
  { label: "Portugal (+351)", value: "351" },
  { label: "Puerto Rico (+1787)", value: "1787" },
  { label: "Qatar (+974)", value: "974" },
  { label: "Reunion (+262)", value: "262" },
  { label: "Romania (+40)", value: "40" },
  { label: "Russia (+7)", value: "7" },
  { label: "Rwanda (+250)", value: "250" },
  { label: "San Marino (+378)", value: "378" },
  { label: "Sao Tome & Principe (+239)", value: "239" },
  { label: "Saudi Arabia (+966)", value: "966" },
  { label: "Senegal (+221)", value: "221" },
  { label: "Serbia (+381)", value: "381" },
  { label: "Seychelles (+248)", value: "248" },
  { label: "Sierra Leone (+232)", value: "232" },
  { label: "Singapore (+65)", value: "65" },
  { label: "Slovak Republic (+421)", value: "421" },
  { label: "Slovenia (+386)", value: "386" },
  { label: "Solomon Islands (+677)", value: "677" },
  { label: "Somalia (+252)", value: "252" },
  { label: "South Africa (+27)", value: "27" },
  { label: "Spain (+34)", value: "34" },
  { label: "Sri Lanka (+94)", value: "94" },
  { label: "St. Helena (+290)", value: "290" },
  { label: "St. Kitts (+1869)", value: "1869" },
  { label: "St. Lucia (+1758)", value: "1758" },
  { label: "Sudan (+249)", value: "249" },
  { label: "Suriname (+597)", value: "597" },
  { label: "Swaziland (+268)", value: "268" },
  { label: "Sweden (+46)", value: "46" },
  { label: "Switzerland (+41)", value: "41" },
  { label: "Syria (+963)", value: "963" },
  { label: "Taiwan (+886)", value: "886" },
  { label: "Tajikstan (+7)", value: "7" },
  { label: "Thailand (+66)", value: "66" },
  { label: "Togo (+228)", value: "228" },
  { label: "Tonga (+676)", value: "676" },
  { label: "Trinidad & Tobago (+1868)", value: "1868" },
  { label: "Tunisia (+216)", value: "216" },
  { label: "Turkey (+90)", value: "90" },
  { label: "Turkmenistan (+7)", value: "7" },
  { label: "Turkmenistan (+993)", value: "993" },
  { label: "Turks & Caicos Islands (+1649)", value: "1649" },
  { label: "Tuvalu (+688)", value: "688" },
  { label: "Uganda (+256)", value: "256" },
  { label: "Ukraine (+380)", value: "380" },
  { label: "United Arab Emirates (+971)", value: "971" },
  { label: "Uruguay (+598)", value: "598" },
  { label: "Uzbekistan (+7)", value: "7" },
  { label: "Vanuatu (+678)", value: "678" },
  { label: "Vatican City (+379)", value: "379" },
  { label: "Venezuela (+58)", value: "58" },
  { label: "Vietnam (+84)", value: "84" },
  { label: "Virgin Islands - British (+1284)", value: "1284" },
  { label: "Virgin Islands - US (+1340)", value: "1340" },
  { label: "Wallis & Futuna (+681)", value: "681" },
  { label: "Yemen (North)(+969)", value: "969" },
  { label: "Yemen (South)(+967)", value: "967" },
  { label: "Zambia (+260)", value: "260" },
  { label: "Zimbabwe (+263)", value: "263" },
];
