import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setShowGroupFormModal } from "../../../../store/contacts/actions";
import { LIMIT, roles } from "constants/constants";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { UserContext } from "../../../../components/UserProvider/UserProvider";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";

class GroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNumberType: "local",
      users: [],
      showModalLoader: false,
    };
  }

  static contextType = UserContext;

  componentDidMount = () => {
    const { userData } = this.context;
    if (userData?.role === roles.admin) {
      this.getAllUsers();
    }
  };

  toggle = () => {
    const { onSetShowGroupFormModal } = this.props;
    onSetShowGroupFormModal();
  };

  handleNumberTyoe = event => {
    this.setState({ selectedNumberType: event.target.value });
  };

  getAllUsers = async () => {
    try {
      const users = await axiosGet(`users?page=1&limit=${LIMIT}`);
      if (users?.status) {
        this.setState({ users: users?.data?.results });
      }
    } catch (error) {
      console.error("error while getting all users :", error);
    }
  };

  createNewGroup = async groupName => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllContactGroups } = this.props;
      const response = await axiosPost("contact-groups", { name: groupName });
      if (response?.status) {
        toast.success(
          response?.message || "Contact Group Created Successfully!",
          {
            position: "top-right",
          }
        );
        getAllContactGroups();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while creating new contact group :", error);
    }
  };

  editGroup = async editedData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllContactGroups, group } = this.props;
      const response = await axiosPatch(
        `contact-groups/${group?.id}`,
        editedData
      );
      if (response?.status) {
        toast.success(response?.message || "Group edited successfully!", {
          position: "top-right",
        });
        getAllContactGroups();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong", {
          position: "top-right",
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while updating contact group :", error);
    }
  };

  render() {
    const { isEdit, showGroupFormModal, group } = this.props;
    const { users, showModalLoader } = this.state;
    const { userData } = this.context;

    const initialValues = {
      groupName: group?.name || "",
      ...(isEdit && userData?.role === roles.admin
        ? {
            assignedUserId: `${group?.assignedUserId || ""}|${
              group?.assignedUserName || ""
            }`,
          }
        : {}),
    };

    const validationSchema = Yup.object().shape({
      groupName: Yup.string().required("Please enter group name"),
      ...(isEdit &&
        userData?.role === roles.admin && {
          assignedUserId: Yup.string().required("Please enter the user name"),
        }),
    });

    const onSubmit = values => {
      if (isEdit) {
        const isGroupNameChanged = group?.name !== values?.groupName;
        const isUserNameChanged =
          userData?.role === roles.admin &&
          group?.id !== values?.assignedUserId;

        if (!isGroupNameChanged && !isUserNameChanged) {
          // No changes, you might want to display a message or handle it accordingly
          this.toggle();
          return;
        }

        let data = {};

        if (isGroupNameChanged) {
          data.name = values?.groupName;
        }

        if (userData?.role === roles.admin && isUserNameChanged) {
          const [id, name] = values?.assignedUserId?.split("|");
          data.assignedUserId = id;
          data.assignedUserName = name;
        }

        this.editGroup(data);
      } else {
        this.createNewGroup(values?.groupName);
      }
    };
    return (
      <Modal isOpen={showGroupFormModal} className={this.props.className}>
        {showModalLoader ? <ModalLoader /> : ""}
        <ModalHeader toggle={this.toggle} tag="h4">
          {!!isEdit ? "Edit Group" : "Add Group"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Group Name</Label>
                      <Field
                        name="groupName"
                        type="text"
                        className={
                          "form-control" +
                          (errors.groupName && touched.groupName
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="groupName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {isEdit && userData?.role === roles.admin && (
                      <div className="mb-3">
                        <Label className="form-label">User Name</Label>
                        <Field
                          as="select"
                          name="assignedUserId"
                          className={
                            "form-control-select" +
                            (errors.assignedUserId && touched.assignedUserId
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">Select</option>
                          {users?.map((user, index) => (
                            <option
                              value={`${user?.id}|${user?.name}`}
                              key={`${index}-${user?.name}`}
                            >
                              {user?.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="assignedUserId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

GroupForm.propTypes = {
  className: PropTypes.any,
  onSetShowGroupFormModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowGroupFormModal: () => dispatch(setShowGroupFormModal()),
});

const mapStateToProps = ({ contacts }) => ({
  showGroupFormModal: contacts.showGroupFormModal,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupForm));
