import React, { Component } from "react";
import { Spinner } from "reactstrap";

class PageLoader extends Component {
  render() {
    return (
      <div className="page-loader">
        <Spinner color="light">Loading...</Spinner>;
      </div>
    );
  }
}

export default PageLoader;
