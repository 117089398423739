import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { connect } from "react-redux";
import { conversationType, inboxTabTypes } from "constants/constants";
//Import images
import avatar3 from "../../../assets/images/users/default-avatar.png";

//i18n
import { withTranslation } from "react-i18next";
import { addNotification, setActiveTab, setSelectedNotification, updateNotification } from "../../../store/actions";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smstoggle: false,
      whatsapptoggle: false,
      sms: "",
      whatsapp: ""
    };
    this.toggleSms = this.toggleSms.bind(this);
    this.toggleWhatsapp = this.toggleWhatsapp.bind(this);
  }

  componentDidMount = () => {
    // this.handleGetUnreadMessages()
  }

  toggleSms() {
    this.setState(prevState => ({
      smstoggle: !prevState.smstoggle,
    }));
  }

  toggleWhatsapp() {
    this.setState(prevState => ({
      whatsapptoggle: !prevState.whatsapptoggle,
    }));
  }

  handleNotificationClick = (data) => {
    const { onSetSelectedNotification, onSetActiveTab } = this.props
    onSetActiveTab(data?.index)
    onSetSelectedNotification(data);
    const { notifications, onUpdateNotification } = this.props;
    const updatedNotificationArray = notifications.filter(notification => {
      if (notification.from === data.from && notification.to === data.to) {
        return false
      } else {
        return true
      }
    }
    );
    onUpdateNotification(updatedNotificationArray)
  }

  handleNotification = () => {
    const { notifications } = this.props;
    const sms = notifications?.filter(
      notification => notification.channel === conversationType.SMS
    );
    const whatsapp = notifications?.filter(
      notification => notification.channel === conversationType.WHATSAPP
    );
    this.setState({ sms, whatsapp })
  }


  render() {
    const { notifications } = this.props;

    const sms = notifications?.filter(
      notification => notification.channel === conversationType.SMS
    );
    const whatsapp = notifications?.filter(
      notification => notification.channel === conversationType.WHATSAPP
    );
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.smstoggle}
          toggle={this.toggleSms}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-message-alt-check bx-tada" />
            <span className="badge bg-danger rounded-pill">{sms.length}</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>

              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {sms && sms.length > 0 ? (
                sms.map((singleSms, index) => (
                  <Link
                    to="/inbox"
                    className="text-reset notification-item"
                    key={`${singleSms?.from}-${index + 1}`}
                  >
                    <div className="d-flex" onClick={() => this.handleNotificationClick({ to: singleSms.to, from: singleSms.from, index: inboxTabTypes.SMS })}>
                      <img
                        src={avatar3}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">{singleSms?.from}</h6>
                        <div className="font-size-12 text-muted">
                          <p
                            className="mb-0 font-sm"
                            style={{ fontSize: "11px" }}
                          >
                            To : {singleSms?.to}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <span className="badge rounded-pill bg-danger float-end p-1">
                          {singleSms?.totalCount}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div className="text-center">No notifications yet.</div>
              )}
            </SimpleBar>

          </DropdownMenu>
        </Dropdown>
        <Dropdown
          isOpen={this.state.whatsapptoggle}
          toggle={this.toggleWhatsapp}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bxl-whatsapp bx-tada" />
            <span className="badge bg-danger rounded-pill">
              {whatsapp.length}
            </span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>

              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {whatsapp && whatsapp.length > 0 ? (
                whatsapp.map((singleWhatsapp, index) => (
                  <Link
                    to="/inbox"
                    className="text-reset notification-item"
                    key={`${singleWhatsapp?.from}-${index + 2}`}
                  >
                    <div className="d-flex" onClick={() => this.handleNotificationClick({ to: singleWhatsapp.to, from: singleWhatsapp.from, index: inboxTabTypes.WHATSAPP })}>
                      <img
                        src={avatar3}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">{singleWhatsapp?.from}</h6>

                        <div className="font-size-12 text-muted">
                          {/* <p className="mb-0">{singleWhatsapp?.message}</p> */}
                          <p
                            className="mb-0 font-sm"
                            style={{ fontSize: "11px" }}
                          >
                            To : {singleWhatsapp?.to}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <span className="badge rounded-pill bg-danger float-end p-1">
                          {singleWhatsapp?.totalCount}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div className="text-center">No notifications yet.</div>
              )}
            </SimpleBar>

          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  onAddNotification: data => dispatch(addNotification(data)),
  onSetSelectedNotification: data => dispatch(setSelectedNotification(data)),
  onUpdateNotification: data => dispatch(updateNotification(data)),
  onSetActiveTab: index => dispatch(setActiveTab(index))
});

const mapStateToProps = state => {
  return {
    notifications: state.notification.notifications,
    selectedNotification: state.notification.selectedNotification,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(NotificationDropdown)
);
