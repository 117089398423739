import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {axiosPatch } from "helpers/api_helpers";
import { setSelectedContactId, setShowChangeGroupModal } from "../../../store/inbox/actions";
import { toast } from "react-toastify";

class ChangeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContactId:null,
    //   selectedNumberType: "local",
    };
  }

  toggle = () => {
    const { onSetShowChangeGroupModal } = this.props;
    onSetShowChangeGroupModal();
  };

  handleSelectedContactId = async (contactGroupId) => {
    const { selectedContactId,setSelectedContactId } = this.props;

    const contactId = selectedContactId;
   
    try {
      
      const response  = await axiosPatch("contact-groups/add-contact-in-group", { contactGroupId ,contactId});
      if (response?.status === true) {
        setSelectedContactId(null);
        toast.success(response?.message || "Contact added successfully!");
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
    } catch (error) {
      console.error("Error while unblocking contacts:", error);
    }
  };


  render() {
    const { showChangeGroupModal } = this.props;
    const { contactGroups } = this.props;

    return (
      <Modal isOpen={showChangeGroupModal} className={this.props.className}>
        <ModalHeader toggle={this.toggle} tag="h4">
          Move to another list
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              groupName: "",
            }}
            validationSchema={Yup.object().shape({
              groupName: Yup.string().required("Please enter group name"),
            })}
            onSubmit={contactGroupId => {
             this.handleSelectedContactId(contactGroupId?.groupName)
              this.toggle();
            }}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Group Name</Label>
                      <Field
                        as="select"
                        name="groupName"
                        className={
                          "form-control-select" +
                          (errors.groupName && touched.groupName
                            ? " is-invalid"
                            : "")
                        }
                      >
                      
                        <option value="">Select a group</option>
                        {contactGroups.map(group => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="groupName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

ChangeGroup.propTypes = {
  className: PropTypes.any,
  onSetShowChangeGroupModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowChangeGroupModal: () => dispatch(setShowChangeGroupModal()),
  setSelectedContactId: (value) => dispatch(setSelectedContactId(value)),
});

const mapStateToProps = state => {
  return {
    showChangeGroupModal: state.inbox.showChangeGroupModal,
    contactGroups: state.contactgroup.contactGroups,
    selectedContactId : state.inbox.selectedContactId,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangeGroup));
