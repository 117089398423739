import React from "react";
import { Redirect } from "react-router-dom";
import UserProfile from "../pages/Authentication/UserProfile";

//user settings
import Settings from "../components/VerticalLayout/Settings"

//Dashboard 
import Dashboard from "../pages/Dashboard/Dashboard";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login";
// import Login2 from "../pages/AuthenticationInner/Login2";
// import Register1 from "../pages/AuthenticationInner/Register";
// import Register2 from "../pages/AuthenticationInner/Register2";
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
// import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
// import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPwd2";
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
// import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
// import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
// import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
// import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
// import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
// import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";


import ResetPassword from "pages/Authentication/ResetPassword";
import ChangePassword from "pages/Authentication/ChangePassword";
// import CustomerTable from "pages/Customers/CustomerTable";
import CustomerTableNew from "pages/Customers/CustomerTableNew";
import PhoneNumber from "pages/PhoneNumber/PhoneNumber";
import User from "pages/Admin Management/User/User";
import Step1 from "pages/Contacts/ImportContacts/Step1";
import Step2 from "pages/Contacts/ImportContacts/Step2";
import ImportContacts from "pages/Contacts/ImportContacts/ImportContacts";
import ContactGroups from "pages/Contacts/Contact Groups/ContactGroups";
import ViewContacts from "pages/Contacts/Contact Groups/ViewContacts";
import SenderGroup from "pages/Sender Group/SenderGroup";
import Message from "pages/Campaign/Message/Message";
import WhatsappMessage from "pages/Campaign/Whatsapp Message/WhatsappMessage";
import Report from "pages/Campaign/Report/Report";
import ViewReport from "pages/Campaign/Report/ViewReport";
import CallLogs from "pages/Logs/CallLogs";
import Voicemail from "pages/Logs/Voicemail";
import QuickMessage from "pages/Quick Message/QuickMessage";
import Notes from "pages/Notes/Notes";
import Inbox from "pages/Inbox/Inbox";
import Opt from "pages/Admin Management/Opt/Opt";
import BlockedNumbers from "pages/Blocked Numbers/BlockedNumbers";
import Templates from "pages/Campaign/Message Template/Templates";
import Numbers from "pages/PhoneNumber/Numbers";
import InboxMessage from "pages/Inbox/InboxMessage";
import InboxWhatsappMessage from "pages/Inbox/InboxWhatsappMessage";
import Billing from "pages/Billing/Billing";

const authProtectedRoutes = [

  // Customers routes
  { path: "/customer-table", component: CustomerTableNew },
  { path: "/customers", component: CustomerTableNew },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/change-password", component: ChangePassword },

  //settings
  { path: "/settings", component: Settings },

  //Dashboard
  { path: "/", component: Dashboard },

  //Phone Numbers
  { path: "/phone-number", component: Numbers },

  //Admin Management
  //user
  { path: "/user-management", component: User },

  //opt
  { path: "/opt-management", component: Opt },

  //Billing
  { path: "/billing", component: Billing },

  //Contacts
  //import contacts
  { path: "/Step1", component: Step1 },
  { path: "/Step2", component: Step2 },
  { path: "/import-contacts", component: ImportContacts },
  { path: "/contact-groups", component: ContactGroups },
  { path: "/view-contacts/:groupId", component: ViewContacts },

  //Sender Groups
  { path: "/sender-group", component: SenderGroup },

  //Campaign
  { path: "/send-message", component: Message },
  { path: "/send-whatsapp-message", component: WhatsappMessage },
  { path: "/report", component: Report },
  { path: "/view-report/:id", component: ViewReport },
  { path: "/message-template", component: Templates },

  //Logs
  { path: "/call-logs", component: CallLogs },
  { path: "/voicemail", component: Voicemail },

  //Quick-sms
  { path: "/quick-message", component: QuickMessage },

  //Notes
  { path: "/notes", component: Notes },

  //Inbox
  { path: "/inbox", component: Inbox },
  { path: "/inbox/sms", component: InboxMessage },
  { path: "/inbox/whatsapp", component: InboxWhatsappMessage },
  //Blocked-Numbers
  { path: "/do-not-disturb", component: BlockedNumbers },


];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login/:userId", component: Login },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/reset-password", component: ResetPassword },

  { path: "/auth-email-verification/:token", component: EmailVerification },
  { path: "/auth-email-verification", component: EmailVerification },

];

export { authProtectedRoutes, publicRoutes };
