import axios from "axios";
import { getDataFromLocalStorage, setDataInLocalStorage } from "./common_helpers";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  config => {
    const token = getDataFromLocalStorage("accessToken") || getDataFromLocalStorage("accessToken")

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message === "Please authenticate" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getDataFromLocalStorage("refreshToken")

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/refresh-tokens`,
          {
            refreshToken: refreshToken,
          }
        );
        if (res.status === 200) {
          setDataInLocalStorage(
            "accessToken",
            res?.data?.tokens?.access?.token
          );
          setDataInLocalStorage("accessToken",
            res?.data?.tokens?.access?.token)

          // setDataInLocalStorage(
          //   "refreshToken",
          //   JSON.stringify(res?.data?.tokens?.refresh?.token)
          // );

          error.config.headers[
            "Authorization"
          ] = `Bearer ${res.data.tokens.access.token}`;

          return axiosInstance(error.config);
        } else {
          localStorage.clear();
          sessionStorage.clear()
          window.location.replace("/login");
        }
      } catch (refreshError) {
        localStorage.clear();
        sessionStorage.clear()
        window.location.replace("/login");
      }
    } else {
      return Promise.reject(error);
    }
  }
);
