import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from "pages/pagination/Pagination";
import Limit from "pages/pagination/Limit";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import { setShowOptModal } from "../../../store/user/actions";
import OptForm from "./Modal/OptForm";
import { optType } from "constants/constants";
import { axiosDel, axiosGet } from "helpers/api_helpers";
import emptyContacts from "../../../assets/images/emptyContact.png";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/actions";
import { FormControl } from "react-bootstrap";

class Opt extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      opts: [],
      opt: "",
      isEdit: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: "",
      contactListColumns: [
        {
          text: "User",
          dataField: "adminName",
          sort: true,
        },
        {
          text: "Opt Out",
          dataField: "optOut",
          sort: true,
          formatter: cellContent => <span> {optType[cellContent]}</span>,
        },
        {
          text: "Text",
          dataField: "content",
          sort: true,
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, opt) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-clipboard-edit font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  id="edit"
                  onClick={() => this.handleUserClick(opt)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                  onClick={() => this.onClickDelete(opt)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = () => {
    this.handleGetAllOpts(this.state.page);
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllOpts(selectedPage);
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetAllOpts(this.state.page);
    });
  };

  toggle = () => {
    const { onSetShowOptModal } = this.props;
    onSetShowOptModal();
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = opt => {
    this.setState({ opt, deleteModal: true });
  };

  handleUserClicks = () => {
    this.setState({ opt: "", isEdit: false });
    this.toggle();
  };

  handleUserClick = arg => {
    const optInfo = arg;

    this.setState({
      opt: {
        id: optInfo.id,
        text: optInfo.content,
        optOut: optInfo.optOut,
      },
      isEdit: true,
    });

    this.toggle();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleGetAllOpts = async page => {
    const { onSetLoader } = this.props;
    const { searchQuery } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `opt?page=${page}&limit=${this.state.size}${
          searchQuery ? `&q=${searchQuery}` : ""
        }&sortBy=createdAt:desc`
      );
      if (response?.status) {
        this.setState({
          opts: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error("error at handleGetAllOpts :", error);
      onSetLoader(false);
    }
  };

  handleOptDeletion = async () => {
    try {
      const { opt } = this.state;
      const response = await axiosDel(`opt/${opt?.id}`);
      if (response?.status) {
        toast.success(response?.message || "Opt Deleted successfully!");
        this.handleGetAllOpts(this.state.page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error("error at handleOptDeletion :", error);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetAllOpts(this.state.page);
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    //meta title
    document.title = "opt";

    const { opts } = this.state;

    const { deleteModal, isEdit, opt } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleOptDeletion}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <OptForm
          isEdit={isEdit}
          optInfo={opt}
          getAllOpts={() => {
            this.handleGetAllOpts(this.state.page);
          }}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Opt" breadcrumbItem="Opt List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      sm="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block w-25 mb-3">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchQuery}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      <div className="text-sm-end me-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Opt
                        </Button>
                      </div>
                    </Col>
                    {opts.length ? (
                      <>
                        <div>
                          <BootstrapTable
                            keyField="id"
                            data={opts}
                            columns={this.state.contactListColumns}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img src={emptyContacts} alt="empty-conatct"></img>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Opt.propTypes = {
  className: PropTypes.any,
  onSetShowOptModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onSetShowOptModal: () => dispatch(setShowOptModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ user }) => ({
  showOptFormModal: user.showOptFormModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Opt));
