import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import BulkWhatsappMessage from "./Form/BulkWhatsappMessage";
import SingleWhatsappMessage from "./Form/SingleWhatsappMessage";

class WhatsappMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      isMenu: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }));
  }

  render() {
    //meta title
    document.title = "message";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Campaign" breadcrumbItem="Send Whatsapp Message" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggleTab("1");
                            }}
                          >
                            Bulk
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.toggleTab("2");
                            }}
                          >
                            Single
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <div className="">
                      {this.state.activeTab === "1" ? (
                        <BulkWhatsappMessage />
                      ) : (
                        ""
                      )}
                      {this.state.activeTab === "2" ? (
                        <SingleWhatsappMessage />
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default WhatsappMessage;
