import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addNotification, setLatestConversation, updateNotification } from "store/notifications/actions";
import { setTwilioVoiceToken, setStartTimer, setElaspedTime } from "store/actions";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout";
import HorizontalLayout from "./components/HorizontalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// Import fackbackend Configuration file
import fakeBackend from "./helpers/AuthType/fakeBackend";
import ToastMessage from "components/ToastMessage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import socketService from "utils/socket";
// import NotificationDropdown from "components/CommonForBoth/TopbarDropdown/NotificationDropdown";
import { UserContext } from "./components/UserProvider/UserProvider";
import notification from "store/notifications/reducer";
import { axiosGet } from "helpers/api_helpers";
import { getDataFromLocalStorage } from "helpers/common_helpers";
import { success } from "toastr";

// Activating fake backend
fakeBackend();

// Activating fake firebase
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elapsedTime: 0,
      timer: null,
    };
    this.getLayout = this.getLayout.bind(this);
  }

  static contextType = UserContext;

  CustomToastContent = (data) => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column">
        {data.channel === "sms" ? (
          <div className="d-flex align-items-center notification-toast"><div className="pr-3"><i className="mdi mdi-message-text-outline font-size-24 message-icon me-1"></i></div> <div><span className="">{"SMS" || data.channel}</span></div> </div>
        ) : (
          <div className="d-flex align-items-center notification-toast"><div className="pr-3"> <i className="mdi mdi-whatsapp font-size-24 whatsapp-icon me-1"></i></div> <div><span className="">{"Whatsapp" || data.channel}</span></div> </div>
        )}
      </div>
      <div>
        <div className="from">From: {data.from}</div>
      </div>
    </div>
  );

  socketConnectionOnload = async () => {
    const { getUserinfo } = this.context;
    const latestData = await getUserinfo()

    if (latestData && latestData.id) {
      // Extract userId from userData
      const { id } = latestData;

      // Initialize socket service if not initialized elsewhere
      socketService?.close()
      socketService.init(id);
      socketService.emit('login', latestData)
      socketService.on('login', (data) => {
      })

      // Listen for notifications for the specific userId
      socketService.on("notification", data => {

        // toast.success(`New (${data?.channel}) message recieved from : ${data.from?.replace(/\+/g, "")} \n ${data?.message}`)
        if (data) {
          toast(this.CustomToastContent(data), { hideProgressBar: true })
        }
        const socketData = {
          to: data.to?.replace(/\+/g, ""),
          from: data.from?.replace(/\+/g, ""),
          message: data?.message,
          channel: data?.channel,
          userId: data?.userId,
        };
        const { notifications } = this.props;
        let notificationFound = false;

        // Iterate through existing notifications and create a new array with modifications
        const updatedNotifications = notifications.map(notification => {
          if (
            notification?.to === socketData?.to &&
            notification?.from === socketData?.from
          ) {
            // If the condition is met, increment totalCount
            notification.totalCount++;
            notificationFound = true;
          }
          return notification;
        });

        // If no matching notification is found, add a new one
        if (!notificationFound) {
          this.props.addNotification({ ...socketData, totalCount: 1 }); // Add new notification
        } else {
          // Update the state with the modified notifications array
          this.props.updateNotification(updatedNotifications);
        }
        this.props.setLatestConversation(data);
        // You can update your state or trigger any action here based on the received notification
      });

      socketService.on("campaign", data => {
        toast.success(data?.message);
        // this.props.history.push('/report');
        // this.handleAllContactGroups(this.state.page);
      });

      socketService.on("contact-blocked", data => {
        this.props.updateNotification([])
        this.handleGetUnreadMessages()
      });
    }
  };

  handleGetUnreadMessages = async () => {
    try {
      const { addNotification } = this.props;
      const response = await axiosGet("/conversation/unread-messages");
      if (response?.status) {
        response.data.forEach(notification => {
          addNotification(notification);
        });
      }
    } catch (error) {
      console.error("error at handleNotificationClick", error);
    }
  };

  handleGetTwilioVoiceToken = async () => {
    try {
      const { setTwilioVoiceToken } = this.props
      const response = await axiosGet('private-cred/voice-access-token')
      if (response.status) {
        setTwilioVoiceToken(response?.data)
      }
    } catch (error) {
      console.error("error at handleGetTwilioVoiceToken in app.js", error)
    }
  }

  componentDidMount() {
    this.socketConnectionOnload();
    if (getDataFromLocalStorage("accessToken")) {
      this.handleGetUnreadMessages()
      this.handleGetTwilioVoiceToken()
    }
    const { startTimer } = this.props;
    if (startTimer) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    // Assume socketService provides access to a socket instance
    socketService.removeAllListeners();
  }

  componentDidUpdate = prevProps => {
    if (prevProps.startTimer !== this.props.startTimer) {
      if (this.props.startTimer) {
        // If stopTimer prop changes to true, stop the timer
        this.startTimer();
      } else {
        // If stopTimer prop changes to false, start the timer
        this.resetTimer();
      }
    }
    if (prevProps.initializeSocket !== this.props.initializeSocket) {
      this.socketConnectionOnload()
    }
  };

  startTimer = () => {
    if (!this.props.stopTimer) {
      this.setState({
        timer: setInterval(this.tick, 1000),
      });
    }
  };

  stopTimer = () => {
    clearInterval(this.state.timer);
    this.setState({ timer: null });
  };

  resetTimer = () => {
    this.stopTimer();
    this.setState({ elapsedTime: 0 });
  };

  tick = () => {
    const { elapsedTime } = this.state;
    const { setElaspedTime } = this.props;

    const newElapsedTime = elapsedTime + 1;
    setElaspedTime(newElapsedTime)
    this.setState({ elapsedTime: newElapsedTime });
  };
  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();
    const { twilioVoiceToken } = this.props

    return (
      <React.Fragment>
        {this.props?.toast?.show ? <ToastMessage /> : <></>}
        <ToastContainer />

        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {twilioVoiceToken || !getDataFromLocalStorage("accessToken") ? authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            )) : ""}


          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    toast: state.ToastMsg,
    userData: state.userData,
    notifications: state.notification.notifications,
    twilioVoiceToken: state.dialPad.twilioVoiceToken,
    startTimer: state.dialPad.startTimer,
    initializeSocket: state.Login.initializeSocket
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, { addNotification, setLatestConversation, updateNotification, setTwilioVoiceToken, setStartTimer, setElaspedTime })(App));
